import React from 'react';
import {
  Flex,
  Icon,
  Link,
  Text,
  VAdultCareIcon,
  VAssessmentIcon,
  VBreakupIcon,
  VCalendarIcon,
  VHomeIcon,
  VOutOfPocketIcon,
  VProviderIcon,
  VUploadIcon,
  VWorkplaceIcon,
} from '@springcare/sh-component-library';
import classnames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import {
  MdOutlineCircle,
  MdFormatListBulletedAdd,
  MdOutlineFormatListBulleted,
  MdOutlineHandshake,
} from 'react-icons/md';
import { TbAccessPoint, TbPresentation, TbUsers, TbUserSearch, TbClipboardSmile, TbCompass } from 'react-icons/tb';

import { CDX_REPORTING_ADMIN } from 'constants/roles';
import styles from './styles.module.scss';
import routes from 'routes';
import LocalStorageUtils from 'utils/localStorage';
import { FLAGS, useFeatureFlag } from 'utils/launchdarkly';
import { getCompassUrl } from 'modules/shared/utils/redirectToCompass';

interface IProps {
  showLinkText: boolean;
}

interface IMenuItem {
  alias: string;
  icon: React.ElementType;
  text: string;
  hide?: boolean;
  href?: string;
}

export const SideMenuItems: React.FC<IProps> = ({ showLinkText }) => {
  const router = useRouter();

  const isProduction = process.env.APP_ENV === 'production';
  const shouldDisplayCompass = LocalStorageUtils.getUserScopes()?.some((role) => role.includes('compass'));
  const userScopes = LocalStorageUtils.getUserScopes();
  const shouldDisplayCustomerDataIntegrations = userScopes?.some((role) => role.includes(CDX_REPORTING_ADMIN));
  const hasSalesRole = userScopes?.some((role) => role.includes('sales'));

  const enableTrials = useFeatureFlag(FLAGS.TRIALS);
  const enabledConnectedBenefits = useFeatureFlag(FLAGS.SHOW_CONNECTED_BENEFITS);
  const hasDeactivationAccess = useFeatureFlag(FLAGS.DEACTIVATIONS);

  let menuItems: IMenuItem[] = [
    { alias: 'Home', icon: VHomeIcon, text: 'Home' },
    { alias: 'AppointmentList', icon: VCalendarIcon, text: 'Appointments' },
    { alias: 'AssessmentList', icon: VAssessmentIcon, text: 'Assessments' },
    { alias: 'Billing--Appointments', icon: VOutOfPocketIcon, text: 'Billing Home' },
    { alias: 'ProviderList', icon: VProviderIcon, text: 'Caregivers' },
    { alias: 'CensusIngestionList', icon: VUploadIcon, text: 'Census Uploads' },
    { alias: 'ChannelPartnerList', icon: MdOutlineHandshake, text: 'Channel Partners' },
    { alias: 'Compass', icon: TbCompass, text: 'Compass', hide: !shouldDisplayCompass, href: getCompassUrl() },
    {
      alias: 'CreateData',
      icon: MdFormatListBulletedAdd,
      text: 'Create Test Data',
      hide: isProduction,
    },
    {
      alias: 'CustomerDataIntegrations',
      icon: TbPresentation,
      text: 'Customer Data Integrations',
      hide: !shouldDisplayCustomerDataIntegrations,
    },
    { alias: 'CustomerList', icon: VWorkplaceIcon, text: 'Customers' },
    { alias: 'DeactivationsList', icon: VBreakupIcon, text: 'Deactivations', hide: !hasDeactivationAccess },
    { alias: 'CoveredLives', icon: TbUserSearch, text: 'Find Covered Lives' },
    { alias: 'MemberList', icon: VAdultCareIcon, text: 'Members' },
    { alias: 'SSOClients', icon: TbAccessPoint, text: 'SSO Portal' },
    { alias: 'AppTaskList', icon: MdOutlineFormatListBulleted, text: 'Task Status' },
    { alias: 'TrialList', icon: TbPresentation, text: 'Trials', hide: !enableTrials },
    { alias: 'UserList', icon: TbUsers, text: 'Users' },
    {
      alias: 'DemoAccountList',
      icon: TbClipboardSmile,
      text: 'Demo Accounts',
      hide: isProduction,
    },
    {
      alias: 'ConnectedBenefitList',
      icon: MdOutlineCircle,
      text: 'Connected Benefits',
      hide: !enabledConnectedBenefits,
    },
  ];

  // the sales role should only be allowed to see the demo portion of admin
  if (hasSalesRole) {
    menuItems = [
      {
        alias: 'DemoAccountList',
        icon: TbClipboardSmile,
        text: 'Demo Accounts',
        hide: isProduction,
      },
    ];
  }

  return (
    <ul className={styles.sideMenuItems}>
      {menuItems
        .filter((item) => !item?.hide)
        .map(({ alias, icon, text, href }, index) => {
          const route = routes[alias]?.to || '';
          const basePath = routes[alias]?.as || '';
          const isActive = text === 'Compass' ? false : router?.asPath?.includes(basePath);
          const homeIsActive = router.route === route;

          return (
            <li key={text} className={classnames({ [styles.activeRoute]: index === 0 ? homeIsActive : isActive })}>
              <NextLink href={href || basePath} prefetch={false} passHref>
                <Link style={{ textDecoration: 'none' }}>
                  <Flex color="primary-base" _hover={{ cursor: 'pointer' }} alignItems="center" py={2} px={4} gap={4}>
                    <Icon as={icon} height={5} width={5} />
                    {showLinkText && <Text textStyle="body-medium-regular">{text}</Text>}
                  </Flex>
                </Link>
              </NextLink>
            </li>
          );
        })}
    </ul>
  );
};
