// AUTOMATICALLY GENERATED. DO NOT EDIT

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Customer's Connected Benefit Cohorts hash */
  ConnectedBenefitCohort: any;
  DataURL: any;
  Date: any;
  DateTime: any;
  /** An attributeTag describing a Human Situation, used to define Personal Filters for a searching user */
  FindHelpAttributeTagType: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JSON: any;
  TrimWhitespace: any;
};

/** Autogenerated return type of AcknowledgeLateCancelMissedPolicy. */
export type AcknowledgeLateCancelMissedPolicyPayload = {
  __typename?: 'AcknowledgeLateCancelMissedPolicyPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** Indicates the activity type of an exercise */
export enum Activity {
  Learning = 'LEARNING',
  LearningATechnique = 'LEARNING_A_TECHNIQUE',
  Meditation = 'MEDITATION',
  Mindfulness = 'MINDFULNESS',
}

/** Acuity api accounts */
export type AcuityAccount = {
  __typename?: 'AcuityAccount';
  account_name: Scalars['String'];
  api_id: Scalars['Int'];
  id: Scalars['ID'];
};

/** Acuity api accounts */
export type AcuityAccountList = {
  __typename?: 'AcuityAccountList';
  data: Array<AcuityAccount>;
};

/** An admin */
export type Admin = {
  __typename?: 'Admin';
  creator?: Maybe<User>;
  creator_id?: Maybe<Scalars['String']>;
  hipaa_and_security_trained?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
};

/** consent fields from AgedOutRecord */
export type AgedOutRecord = {
  __typename?: 'AgedOutRecord';
  /** @deprecated Use hipaa_agreed_at instead */
  aged_out_hipaa_agreed_on?: Maybe<Scalars['Date']>;
  /** @deprecated Use terms_of_service_agreed_at instead */
  aged_out_tos_agreed_on?: Maybe<Scalars['Date']>;
  created_at?: Maybe<Scalars['DateTime']>;
  electronic_comm_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  health_info_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  hipaa_agreed_at?: Maybe<Scalars['DateTime']>;
  informed_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  privacy_policy_agreed_at?: Maybe<Scalars['DateTime']>;
  terms_of_service_agreed_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Apero Customer */
export type AperoCustomer = {
  __typename?: 'AperoCustomer';
  apero_customer_category_id: Scalars['ID'];
  apero_customer_category_name: Scalars['String'];
  apero_customer_name: Scalars['String'];
  apero_record_id: Scalars['ID'];
  customer_id: Scalars['ID'];
  id: Scalars['ID'];
};

/** Apero Customer Mapping for a Customer */
export type AperoCustomerList = {
  __typename?: 'AperoCustomerList';
  data: Array<Maybe<AperoCustomer>>;
};

/** Return apero sync status output from memberAperoSync, careProviderAperoSync, appointmentAperoSync mutations */
export type AperoSyncOutput = {
  __typename?: 'AperoSyncOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
};

/** An application task */
export type AppTask = {
  __typename?: 'AppTask';
  cohort?: Maybe<Cohort>;
  completed: Scalars['Int'];
  created_at: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  ended_at?: Maybe<Scalars['DateTime']>;
  failed: Scalars['Int'];
  id: Scalars['ID'];
  metadata_as_json?: Maybe<Scalars['String']>;
  metadata_as_string?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parent_id: Scalars['ID'];
  parent_type: AppTaskParentEnum;
  queued: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  status: AppTaskStatusEnum;
  updated_at: Scalars['DateTime'];
};

/** Indicates the parent type of the application task */
export enum AppTaskParentEnum {
  /** The task has been queued. */
  Cohort = 'COHORT',
  /** The task has been queued. */
  Customer = 'CUSTOMER',
}

/** Indicates the status of an application task */
export enum AppTaskStatusEnum {
  /** One or more of the tasks queued has failed. */
  Errored = 'ERRORED',
  /** All of the tasks queued jobs have finished processing in the queue successfully. */
  Finished = 'FINISHED',
  /** The task has finished queueing and will be processed by the queue. */
  Queued = 'QUEUED',
  /** The task is currently adding its jobs into the queue. */
  Queueing = 'QUEUEING',
  /** The task is currently being processed across the queue. */
  Started = 'STARTED',
}

export type AppliedFiltersOutput = {
  __typename?: 'AppliedFiltersOutput';
  conditions?: Maybe<Array<Scalars['String']>>;
  days_of_week?: Maybe<Array<Scalars['String']>>;
  description_tags?: Maybe<Array<CareProviderTagDescriptionTagEnumType>>;
  ethnicities?: Maybe<Array<Scalars['String']>>;
  genders?: Maybe<Array<Scalars['String']>>;
  languages?: Maybe<Array<Scalars['String']>>;
  licenses?: Maybe<LicensesAppliedFiltersOutput>;
  mediums?: Maybe<Array<Scalars['String']>>;
  specialties?: Maybe<Array<Scalars['String']>>;
  time_of_day?: Maybe<Array<Scalars['String']>>;
  treatment_modalities?: Maybe<Array<Scalars['String']>>;
};

/** An appointment */
export type Appointment = {
  __typename?: 'Appointment';
  acuity_type: AppointmentTypeShow;
  apero_invoice_created_at?: Maybe<Scalars['DateTime']>;
  apero_invoice_record_id?: Maybe<Scalars['String']>;
  attended?: Maybe<Scalars['String']>;
  attended_enum?: Maybe<AppointmentAttendedEnumType>;
  attended_source?: Maybe<AppointmentAttendedSourceEnumType>;
  billing_status?: Maybe<AppointmentBillingStatusEnumType>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  cancellation_reason?: Maybe<Scalars['String']>;
  cancellation_text?: Maybe<Scalars['String']>;
  cancelled_at?: Maybe<Scalars['DateTime']>;
  cancelled_for_member: Scalars['Boolean'];
  change_logs?: Maybe<Array<Maybe<AppointmentChangeLog>>>;
  client_type?: Maybe<Scalars['String']>;
  cohort_name?: Maybe<Scalars['String']>;
  confirmed_at?: Maybe<Scalars['DateTime']>;
  covered: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  customer_site?: Maybe<CustomerSite>;
  duration?: Maybe<Scalars['Int']>;
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_covered_for_member?: Maybe<Scalars['Boolean']>;
  is_eligible_for_recording: Scalars['Boolean'];
  is_in_house_video: Scalars['Boolean'];
  kind: AppointmentKindEnum;
  location?: Maybe<Scalars['String']>;
  medium: AppointmentMediumEnum;
  member: Member;
  member_id?: Maybe<Scalars['ID']>;
  member_session_url?: Maybe<Scalars['String']>;
  next_billing_status?: Maybe<AppointmentBillingStatusEnumType>;
  padding_after?: Maybe<Scalars['Int']>;
  paid?: Maybe<Scalars['String']>;
  patient_practice?: Maybe<PatientPractice>;
  patient_practice_location?: Maybe<PatientPracticeLocation>;
  previous_appointment_with_provider?: Maybe<Appointment>;
  previous_billing_status?: Maybe<AppointmentBillingStatusEnumType>;
  provider?: Maybe<CareProvider>;
  provider_id?: Maybe<Scalars['ID']>;
  provider_session_url?: Maybe<Scalars['String']>;
  reason_for_scheduling?: Maybe<Scalars['String']>;
  sentiment_rating?: Maybe<Scalars['Int']>;
  session_type: Scalars['String'];
  start_at: Scalars['DateTime'];
  status?: Maybe<Scalars['String']>;
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
};

/** An appointment */
export type AppointmentLocationArgs = {
  is_caregiver_context?: InputMaybe<Scalars['Boolean']>;
};

/** Appointment allocation attached to a given Contract Term */
export type AppointmentAllocation = {
  __typename?: 'AppointmentAllocation';
  amount?: Maybe<Scalars['Int']>;
  category_allocations: Array<AppointmentCategoryAllocation>;
  end_date: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  payment_type_name: Scalars['String'];
  start_date: Scalars['ISO8601Date'];
};

/** Whether an appointment was kept or missed */
export enum AppointmentAttendedEnumType {
  /** An appointment that was canceled by caregiver */
  CaregiverCancel = 'CAREGIVER_CANCEL',
  /** An appointment missed by caregiver */
  CaregiverDidNotAttend = 'CAREGIVER_DID_NOT_ATTEND',
  /** An appointment canceled late by caregiver */
  CaregiverLateCancel = 'CAREGIVER_LATE_CANCEL',
  /** An appointment that was kept */
  Kept = 'KEPT',
  /** An appointment that was canceled by member */
  MemberCancel = 'MEMBER_CANCEL',
  /** An appointment missed by member */
  MemberDidNotAttend = 'MEMBER_DID_NOT_ATTEND',
  /** An appointment that was canceled late by member */
  MemberLateCancel = 'MEMBER_LATE_CANCEL',
  /** [Deprecated] An appointment missed by the member */
  MemberMissed = 'MEMBER_MISSED',
  /** [Deprecated] An appointment missed by the provider */
  ProviderMissed = 'PROVIDER_MISSED',
  /** An appointment that was canceled by Spring Health */
  SpringHealthCancel = 'SPRING_HEALTH_CANCEL',
  /** An appointment canceled late by Spring Health */
  SpringHealthLateCancel = 'SPRING_HEALTH_LATE_CANCEL',
}

/** Where the attended information came from */
export enum AppointmentAttendedSourceEnumType {
  Admin = 'ADMIN',
  CompassNotes = 'COMPASS_NOTES',
  CompassScheduling = 'COMPASS_SCHEDULING',
  MemberExperience = 'MEMBER_EXPERIENCE',
  Zoom = 'ZOOM',
}

/** Indicates the status of billing processing for this appointment */
export enum AppointmentBillingStatusEnumType {
  ActionNeeded = 'ACTION_NEEDED',
  BillToCustomer = 'BILL_TO_CUSTOMER',
  Completed = 'COMPLETED',
  NeedsBilling = 'NEEDS_BILLING',
  NeedsCoding = 'NEEDS_CODING',
  NeedsCostCommunication = 'NEEDS_COST_COMMUNICATION',
  NeedsEligibilityVerification = 'NEEDS_ELIGIBILITY_VERIFICATION',
  NeedsInvoicing = 'NEEDS_INVOICING',
  NoActionNeeded = 'NO_ACTION_NEEDED',
}

/** An appointment category of an appointment allocation */
export type AppointmentCategory = {
  __typename?: 'AppointmentCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Appointment Category for an AppointmentAllocation */
export type AppointmentCategoryAllocation = {
  __typename?: 'AppointmentCategoryAllocation';
  category_name: Scalars['String'];
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
};

/** appointment change log */
export type AppointmentChangeLog = {
  __typename?: 'AppointmentChangeLog';
  action?: Maybe<Scalars['String']>;
  changes?: Maybe<Array<Maybe<AppointmentChangeLogChange>>>;
  timestamp: Scalars['DateTime'];
  user_first_name?: Maybe<Scalars['String']>;
  user_last_name?: Maybe<Scalars['String']>;
  user_roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** an individual field change in an appointment change log */
export type AppointmentChangeLogChange = {
  __typename?: 'AppointmentChangeLogChange';
  field_name: Scalars['String'];
  new_value?: Maybe<Scalars['String']>;
  old_value?: Maybe<Scalars['String']>;
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AppointmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Appointment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total_count: Scalars['Int'];
};

export type AppointmentDateRangeInput = {
  end_span?: InputMaybe<Scalars['DateTime']>;
  start_span?: InputMaybe<Scalars['DateTime']>;
};

/** An edge in a connection. */
export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Appointment>;
};

/** Appointment invoices for a given member */
export type AppointmentInvoices = {
  __typename?: 'AppointmentInvoices';
  appointment_invoices: Array<AppointmentInvoicesData>;
  next_page?: Maybe<Scalars['String']>;
  previous_page?: Maybe<Scalars['String']>;
};

/** Invoice data for each appointment */
export type AppointmentInvoicesData = {
  __typename?: 'AppointmentInvoicesData';
  amount_charged: Scalars['String'];
  amount_paid: Scalars['String'];
  appointment_date: Scalars['ISO8601DateTime'];
  appointment_id: Scalars['String'];
  appointment_kind: AppointmentKindEnum;
  attended_status?: Maybe<Scalars['String']>;
  balance: Scalars['String'];
  care_provider: Scalars['String'];
  care_provider_avatar: Scalars['String'];
  invoice_date: Scalars['ISO8601DateTime'];
  invoice_id: Scalars['String'];
  invoice_status: Scalars['String'];
};

/** Indicates the kind of an appointment */
export enum AppointmentKindEnum {
  /** A coaching appointment. */
  Coaching = 'COACHING',
  /** An appointment with a therapist, for couples. */
  CouplesTherapy = 'COUPLES_THERAPY',
  /** An appointment with a care consultant focused on the employee and finding solutions. */
  EmployeeConsultation = 'EMPLOYEE_CONSULTATION',
  /** A follow up appointment with a Spring Health care navigator. */
  FollowUpCareNavigation = 'FOLLOW_UP_CARE_NAVIGATION',
  /** A follow-up coaching appointment. */
  FollowUpCoaching = 'FOLLOW_UP_COACHING',
  /** An initial appointment with a physician for medication management. */
  FollowUpMedicationManagement = 'FOLLOW_UP_MEDICATION_MANAGEMENT',
  /** A follow up appointment with a peer recovery specialist. */
  FollowUpPeerRecovery = 'FOLLOW_UP_PEER_RECOVERY',
  /** A follow up appointment with a Spring Health specialty care navigator. */
  FollowUpSpecialtyCareNavigation = 'FOLLOW_UP_SPECIALTY_CARE_NAVIGATION',
  /** An appointment with a WMPHC focused on the individual and finding solutions. */
  IndividualSolutionsFocusedConsultation = 'INDIVIDUAL_SOLUTIONS_FOCUSED_CONSULTATION',
  /** An initial appointment with a Spring Health care navigator. */
  InitialCareNavigation = 'INITIAL_CARE_NAVIGATION',
  /** An initial coaching appointment. */
  InitialCoaching = 'INITIAL_COACHING',
  /** An initial appointment with a physician for medication management. */
  InitialMedicationManagement = 'INITIAL_MEDICATION_MANAGEMENT',
  /** An initial appointment with a peer recovery specialist. */
  InitialPeerRecovery = 'INITIAL_PEER_RECOVERY',
  /** An initial appointment with a Spring Health specialty care navigator. */
  InitialSpecialtyCareNavigation = 'INITIAL_SPECIALTY_CARE_NAVIGATION',
  /** An appointment with a WMPHC to help folks in the manager role. */
  ManagerConsultationAndSupport = 'MANAGER_CONSULTATION_AND_SUPPORT',
  /** An appointment with a therapist, for a minor. */
  MinorTherapy = 'MINOR_THERAPY',
  /** An appointment with a therapist. */
  Therapy = 'THERAPY',
  /** An appointment with a WMPHC focused on training and awareness. */
  TrainingAndAwarenessEventConsultation = 'TRAINING_AND_AWARENESS_EVENT_CONSULTATION',
  /** An appointment with a CCA to discuss wellness and support opportunities. */
  WellnessAndSocialSupportSession = 'WELLNESS_AND_SOCIAL_SUPPORT_SESSION',
}

/** Returned data from a AppointmentKinds query */
export type AppointmentKindOutput = {
  __typename?: 'AppointmentKindOutput';
  appointment_kind: Scalars['String'];
  session_type: Scalars['String'];
};

/** Indicates the medium of an appointment */
export enum AppointmentMediumEnum {
  /** An appointment which occurs in person. */
  InPerson = 'IN_PERSON',
  /** An appointment which occurs over the phone. */
  Phone = 'PHONE',
  /** An appointment which occurs over video chat. */
  Video = 'VIDEO',
}

/** Autogenerated return type of AppointmentSentimentRating. */
export type AppointmentSentimentRatingPayload = {
  __typename?: 'AppointmentSentimentRatingPayload';
  success: Scalars['Boolean'];
};

/** provider start time setting on care provider constraints/settings */
export enum AppointmentStartTimeSetting {
  /** Appointment start times chosen by scheduling algorithm */
  AlgorithmV1 = 'ALGORITHM_V1',
  /** Appointments start at the top of the hour */
  Every_00 = 'EVERY_00',
  /** Appointments start every 15 minutes */
  Every_15 = 'EVERY_15',
  /** Appointments start every 20 minutes */
  Every_20 = 'EVERY_20',
  /** Appointments start every 30 minutes */
  Every_30 = 'EVERY_30',
}

/** An appointment type */
export type AppointmentTypeShow = {
  __typename?: 'AppointmentTypeShow';
  buffer_time: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['ID'];
  mediums: Array<AppointmentMediumEnum>;
  name: Scalars['String'];
};

/** A list of appointment types */
export type AppointmentTypes = {
  __typename?: 'AppointmentTypes';
  data: Array<Maybe<AppointmentTypeShow>>;
};

export type AppointmentUsersInput = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  member_id?: InputMaybe<Scalars['ID']>;
};

/** An assessment */
export type Assessment = {
  __typename?: 'Assessment';
  current_questionnaire?: Maybe<Questionnaire>;
  /** Corresponding modal should be displayed when present */
  display_modal?: Maybe<Scalars['String']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  kind: AssessmentKindEnumType;
  member: Member;
  metadata: Scalars['String'];
  num_prompts_answered?: Maybe<Scalars['Int']>;
  num_prompts_total: Scalars['Int'];
  questionnaires?: Maybe<Array<Maybe<Questionnaire>>>;
  questionnaires_order: Array<Maybe<Scalars['String']>>;
  risk?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  triaged: Scalars['Boolean'];
  version_number?: Maybe<Scalars['Int']>;
};

/** An assessment */
export type AssessmentQuestionnairesArgs = {
  version?: InputMaybe<Scalars['String']>;
};

/** The kinds of assessments that can exist */
export enum AssessmentKindEnumType {
  ConditionsCheckIn = 'CONDITIONS_CHECK_IN',
  ConditionsScreening = 'CONDITIONS_SCREENING',
  GoalCheckIn = 'GOAL_CHECK_IN',
  InitialAssessment = 'INITIAL_ASSESSMENT',
  Migrated = 'MIGRATED',
  ProgressUpdate = 'PROGRESS_UPDATE',
  Sprass = 'SPRASS',
  TeenCheckIn = 'TEEN_CHECK_IN',
  TeenInitialAssessment = 'TEEN_INITIAL_ASSESSMENT',
}

/** The reasons for requesting care navigator assistance */
export enum AssistanceReasonEnumType {
  /** Member requested to delete their account */
  DeleteUserAccount = 'DELETE_USER_ACCOUNT',
  /** Member could not find providers for their state */
  NoProvidersFound = 'NO_PROVIDERS_FOUND',
}

/** Returned data from a FetchAppointmentSlots query */
export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  admin_available?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  available?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  blocked?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  duration?: Maybe<Scalars['Int']>;
  slot_increment?: Maybe<Scalars['Int']>;
};

export type AvailableCnAppointmentTimeOutputType = {
  __typename?: 'AvailableCnAppointmentTimeOutputType';
  care_provider: CareProvider;
  start_time: Scalars['DateTime'];
};

/** start times for cns */
export type AvailableCnAppointmentTimes = {
  __typename?: 'AvailableCnAppointmentTimes';
  cn_appointments: Array<Maybe<AvailableCnAppointmentTimeOutputType>>;
  day_of_the_month: Scalars['Int'];
};

/** Outstanding balance for a given member */
export type Balance = {
  __typename?: 'Balance';
  apero_payment_url: Scalars['String'];
  balance?: Maybe<Scalars['String']>;
  latest_statement_url?: Maybe<Scalars['String']>;
  lookup_code: Scalars['String'];
};

export type Benefits = {
  __typename?: 'Benefits';
  care_navigation_benefit?: Maybe<CareNavigationBenefit>;
  coaching_benefit?: Maybe<CoachingBenefit>;
  dependents_benefit?: Maybe<DependentsBenefit>;
  medication_management_benefit?: Maybe<MedicationManagementBenefit>;
  spring_benefit: SpringBenefit;
  sud_benefit?: Maybe<SudBenefit>;
  therapy_benefit?: Maybe<TherapyBenefit>;
};

/** A booking for an appointment */
export type Booking = {
  __typename?: 'Booking';
  appointment: Appointment;
  avatar: Scalars['String'];
  calendar: Calendar;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type BookingFieldsForAppointmentInput = {
  role: Scalars['String'];
  user_id: Scalars['ID'];
};

/** A care provider */
export type BrowseCareProviderResult = {
  __typename?: 'BrowseCareProviderResult';
  acuity_calendar_id?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_cdn_url?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  care_provider_tags?: Maybe<Array<CareProviderTag>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  cohorts?: Maybe<Array<Scalars['ID']>>;
  coordinates?: Maybe<Coordinate>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  dedicated?: Maybe<Scalars['Boolean']>;
  dedicated_customer?: Maybe<Customer>;
  distance_kilometers?: Maybe<Scalars['Float']>;
  /** @deprecated use `distance_kilometers` */
  distance_meters?: Maybe<Scalars['Float']>;
  distance_miles?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  ethnicities?: Maybe<Array<Maybe<Scalars['String']>>>;
  first_name: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  in_person_supported?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_name?: Maybe<Scalars['String']>;
  licensed_countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  members: Array<Maybe<PaginatedMembers>>;
  name?: Maybe<Scalars['String']>;
  network_status: NetworkStatusEnumType;
  next_available_time_slot?: Maybe<Scalars['DateTime']>;
  on_site?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer used */
  on_site_instructions?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduling_url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  supported_appointment_kinds: Array<Maybe<RequestableAppointmentKindEnum>>;
  supported_appointment_mediums: Array<Maybe<AppointmentMediumEnum>>;
  /** @deprecated Use `care_provider_tags` instead. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_zone?: Maybe<Scalars['String']>;
  top_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  user: User;
  user_id?: Maybe<Scalars['String']>;
  virtual_supported: Scalars['Boolean'];
  zip_code?: Maybe<Scalars['String']>;
  zoom_url?: Maybe<Scalars['String']>;
};

/**
 * A list of members for a care provider with the session type for each of the care provider's appointment kinds.
 *                We define "for a care provider" by those with a past appointment and/or on the care team of said provider.
 */
export type ByCareProviderOutputType = {
  __typename?: 'ByCareProviderOutputType';
  data: Array<Maybe<WithNextAppointmentsSessionOutputType>>;
};

/** A calendar for a user */
export type Calendar = {
  __typename?: 'Calendar';
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  user: User;
};

/** A calendar for a user */
export type CalendarAppointmentsArgs = {
  all_past?: InputMaybe<Scalars['Boolean']>;
  all_upcoming?: InputMaybe<Scalars['Boolean']>;
  end_at?: InputMaybe<Scalars['DateTime']>;
  on_date?: InputMaybe<Scalars['DateTime']>;
  only_past?: InputMaybe<Scalars['Boolean']>;
  only_upcoming?: InputMaybe<Scalars['Boolean']>;
  start_at?: InputMaybe<Scalars['DateTime']>;
};

/** Iterable campaign fields */
export type CampaignInput = {
  campaign_id?: InputMaybe<Scalars['Int']>;
  template_id?: InputMaybe<Scalars['Int']>;
};

export type CancelAppointmentInput = {
  /** The attendedness of the appointment */
  attended_enum?: InputMaybe<AppointmentAttendedEnumType>;
  /** where the attended information is coming from */
  attended_source?: InputMaybe<AppointmentAttendedSourceEnumType>;
  campaign?: InputMaybe<CampaignInput>;
  /** A note to attach to the the cancellation email */
  cancel_note?: InputMaybe<Scalars['String']>;
  /** The reason this appointment was cancelled */
  cancellation_reason?: InputMaybe<Scalars['String']>;
  /** The user id of the person who cancelled the appointment */
  cancelled_by?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** Skips sending notifications when true */
  no_email?: InputMaybe<Scalars['Boolean']>;
  /** The plan to reschedule for Zendesk tickets */
  plan_to_reschedule?: InputMaybe<Scalars['String']>;
  /** Rescheduling details for Zendesk tickets */
  reschedule_details?: InputMaybe<Scalars['String']>;
};

/** Return from a CancelAppointment mutation */
export type CancelAppointmentOutput = {
  __typename?: 'CancelAppointmentOutput';
  appointment: Appointment;
  success: Scalars['Boolean'];
};

export type CancelAssessmentInput = {
  id: Scalars['ID'];
};

/** Return from a CancelAssessment mutation */
export type CancelAssessmentOutput = {
  __typename?: 'CancelAssessmentOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

export type CareItem = {
  __typename?: 'CareItem';
  care_item_name: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

export type CareItems = {
  __typename?: 'CareItems';
  available_care_items: Array<CareItem>;
  metadata?: Maybe<Scalars['JSON']>;
  recommended_care_items: Array<CareItem>;
};

/** Care navigation benefit information for a member */
export type CareNavigationBenefit = {
  __typename?: 'CareNavigationBenefit';
  delete_me?: Maybe<Scalars['Boolean']>;
};

export type CareNavigatorCustomerAssignment = {
  __typename?: 'CareNavigatorCustomerAssignment';
  assignment_type: Scalars['String'];
  customer_id: Scalars['String'];
};

export type CareNavigatorRequestAssistanceOutput = {
  __typename?: 'CareNavigatorRequestAssistanceOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** Care Plan Progress */
export type CarePlanProgress = {
  __typename?: 'CarePlanProgress';
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  progress_steps: Array<Maybe<CarePlanProgressStep>>;
};

/** Care Plan Progress Step */
export type CarePlanProgressStep = {
  __typename?: 'CarePlanProgressStep';
  alt_copy?: Maybe<Scalars['String']>;
  call_to_action?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  completed_at?: Maybe<Scalars['DateTime']>;
  cta_path: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  external_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internal_name: Scalars['String'];
  skipped: Scalars['Boolean'];
  skipped_at?: Maybe<Scalars['DateTime']>;
  timeline_name: Scalars['String'];
};

export type CarePreference = {
  __typename?: 'CarePreference';
  created_at: Scalars['DateTime'];
  days_of_week?: Maybe<Array<Scalars['String']>>;
  end_at?: Maybe<Scalars['DateTime']>;
  ethnicities?: Maybe<Array<Scalars['String']>>;
  genders?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  mediums?: Maybe<Array<Scalars['String']>>;
  preference_type?: Maybe<Array<Scalars['String']>>;
  specialties?: Maybe<Array<Scalars['String']>>;
  start_at?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CarePreferenceStatusEnumType>;
  therapy_styles?: Maybe<Scalars['JSON']>;
  time_of_day?: Maybe<Array<Scalars['String']>>;
  updated_at: Scalars['DateTime'];
};

/** Indicates the current status of the Member Preferences questionnaire */
export enum CarePreferenceStatusEnumType {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  Started = 'STARTED',
}

/** A care provider */
export type CareProvider = {
  __typename?: 'CareProvider';
  accepted_client_types: Array<Scalars['String']>;
  acuity_account?: Maybe<AcuityAccount>;
  acuity_calendar_id?: Maybe<Scalars['String']>;
  acuity_calendar_sync_enabled?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  assigned_supervisors?: Maybe<Array<CareProvider>>;
  associate_status?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_cdn_url?: Maybe<Scalars['String']>;
  away_message?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  care_navigator_customer_assignment?: Maybe<CareNavigatorCustomerAssignment>;
  care_provider_tags?: Maybe<Array<CareProviderTag>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  cn_caseload?: Maybe<Scalars['Int']>;
  cohorts?: Maybe<Array<Scalars['ID']>>;
  coordinates?: Maybe<Coordinate>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  created_with_in_house_availability: Scalars['Boolean'];
  customer_role_name?: Maybe<Scalars['String']>;
  customer_sites: Array<CustomerSite>;
  dedicated?: Maybe<Scalars['Boolean']>;
  dedicated_customer?: Maybe<Customer>;
  distance_kilometers?: Maybe<Scalars['Float']>;
  /** @deprecated use `distance_kilometers` */
  distance_meters?: Maybe<Scalars['Float']>;
  distance_miles?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  /** @deprecated Use `care_provider_tags` instead. */
  ethnicities?: Maybe<Array<Maybe<Scalars['String']>>>;
  external_calendar_sync_enabled?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  /** @deprecated Use `care_provider_tags` instead. */
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  in_house_availability_source_of_truth_at?: Maybe<Scalars['DateTime']>;
  in_person_supported?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  is_private_practice: Scalars['Boolean'];
  /** @deprecated Use `care_provider_tags` instead. */
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_name?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  licensed_countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  members: Array<Maybe<PaginatedMembers>>;
  name?: Maybe<Scalars['String']>;
  network_status: NetworkStatusEnumType;
  network_status_changes?: Maybe<Array<Version>>;
  on_site?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer used */
  on_site_instructions?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  personalized_provider_match_description?: Maybe<PersonalizedProviderBioMatchDescription>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduling_constraints?: Maybe<SchedulingConstraintType>;
  scheduling_setting?: Maybe<SchedulingSettingType>;
  scheduling_url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  supervisor_status?: Maybe<Scalars['String']>;
  supported_appointment_kinds: Array<Maybe<RequestableAppointmentKindEnum>>;
  supported_appointment_mediums: Array<Maybe<AppointmentMediumEnum>>;
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `care_provider_tags` instead. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_zone?: Maybe<Scalars['String']>;
  top_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  user: User;
  user_id?: Maybe<Scalars['String']>;
  virtual_supported: Scalars['Boolean'];
  zip_code?: Maybe<Scalars['String']>;
  zoom_url?: Maybe<Scalars['String']>;
};

/** one-off override events for a providers availability */
export type CareProviderAvailabilityEvent = {
  __typename?: 'CareProviderAvailabilityEvent';
  created_by_user_id?: Maybe<Scalars['String']>;
  end_at: Scalars['DateTime'];
  for_in_person: Scalars['Boolean'];
  for_phone: Scalars['Boolean'];
  for_video: Scalars['Boolean'];
  id: Scalars['ID'];
  medium: Scalars['String'];
  send_away_messages: Scalars['Boolean'];
  start_at: Scalars['DateTime'];
};

export type CareProviderAvailabilityEventCreateFieldsInputType = {
  care_provider_id: Scalars['ID'];
  end_at: Scalars['DateTime'];
  for_in_person?: InputMaybe<Scalars['Boolean']>;
  for_phone?: InputMaybe<Scalars['Boolean']>;
  for_video?: InputMaybe<Scalars['Boolean']>;
  medium: Scalars['String'];
  send_away_messages?: InputMaybe<Scalars['Boolean']>;
  start_at: Scalars['DateTime'];
};

/** care provider availability events */
export type CareProviderAvailabilityEvents = {
  __typename?: 'CareProviderAvailabilityEvents';
  data: Array<Maybe<CareProviderAvailabilityEvent>>;
};

/** a providers availability slots */
export type CareProviderAvailabilitySlot = {
  __typename?: 'CareProviderAvailabilitySlot';
  end_at: Scalars['DateTime'];
  for_in_person: Scalars['Boolean'];
  for_phone: Scalars['Boolean'];
  for_video: Scalars['Boolean'];
  id?: Maybe<Scalars['ID']>;
  medium: Scalars['String'];
  start_at: Scalars['DateTime'];
};

/** care provider availability slots */
export type CareProviderAvailabilitySlots = {
  __typename?: 'CareProviderAvailabilitySlots';
  data: Array<Maybe<CareProviderAvailabilitySlot>>;
};

export type CareProviderFiltersInputType = {
  conditions?: InputMaybe<Array<Scalars['String']>>;
  days_of_week?: InputMaybe<Array<Scalars['String']>>;
  dedicated_only?: InputMaybe<Scalars['Boolean']>;
  description_tags?: InputMaybe<Array<CareProviderTagDescriptionTagEnumType>>;
  ethnicities?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  licenses?: InputMaybe<CareProviderLicenses>;
  mediums?: InputMaybe<Array<AppointmentMediumEnum>>;
  provider_distance?: InputMaybe<ProviderDistance>;
  specialties?: InputMaybe<Array<Scalars['String']>>;
  time_of_day?: InputMaybe<Array<Scalars['String']>>;
  treatment_modalities?: InputMaybe<Array<Scalars['String']>>;
};

export type CareProviderLicenses = {
  countries?: InputMaybe<Array<Scalars['String']>>;
  states?: InputMaybe<Array<Scalars['String']>>;
};

/** regular working hours for a provider */
export type CareProviderRecurringAvailabilityBlock = {
  __typename?: 'CareProviderRecurringAvailabilityBlock';
  customer_site?: Maybe<CustomerSite>;
  end_at_minute: Scalars['Int'];
  for_in_person: Scalars['Boolean'];
  for_phone: Scalars['Boolean'];
  for_video: Scalars['Boolean'];
  id: Scalars['ID'];
  medium: Scalars['String'];
  start_at_minute: Scalars['Int'];
};

/** care provider recurring availability blocks */
export type CareProviderRecurringAvailabilityBlocks = {
  __typename?: 'CareProviderRecurringAvailabilityBlocks';
  data: Array<Maybe<CareProviderRecurringAvailabilityBlock>>;
};

/** User facing roles that a provider serves */
export enum CareProviderRoleEnum {
  /** A compass community care advocate */
  CareAdvocate = 'CARE_ADVOCATE',
  /** A compass care consultant */
  CareConsultant = 'CARE_CONSULTANT',
  /** An internal care navigator */
  CareNavigator = 'CARE_NAVIGATOR',
  /** A licensed coach */
  Coach = 'COACH',
  /** An MD who can prescribe */
  MedicationManager = 'MEDICATION_MANAGER',
  /** A licensed therapist */
  Therapist = 'THERAPIST',
}

/** Member facing roles that a care provider serves */
export enum CareProviderRoleForMembers {
  /** An MD who can prescribe */
  CareNavigator = 'care_navigator',
  /** A licensed coach */
  Coach = 'coach',
  /** An MD who can prescribe */
  MedicationManager = 'medication_manager',
  /** A peer recovery specialist */
  PeerRecoverySpecialist = 'peer_recovery_specialist',
  /** A specialty care navigator */
  SpecialtyCareNavigator = 'specialty_care_navigator',
  /** A licensed therapist */
  Therapist = 'therapist',
}

/** Care provider tag */
export type CareProviderTag = {
  __typename?: 'CareProviderTag';
  created_at: Scalars['DateTime'];
  english_name: Scalars['String'];
  id: Scalars['ID'];
  kind: CareProviderTagKindEnumType;
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

/** Care provider tag */
export type CareProviderTagNameArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

/** Provider/Description tag enum for care providers */
export enum CareProviderTagDescriptionTagEnumType {
  AssociateProvider = 'ASSOCIATE_PROVIDER',
  FullTimeProvider = 'FULL_TIME_PROVIDER',
  LessThan_1000HoursOfSupervision = 'LESS_THAN_1000_HOURS_OF_SUPERVISION',
  MedicareAdvantageMaOptOut = 'MEDICARE_ADVANTAGE_MA_OPT_OUT',
  MoreThan_1000HoursOfSupervision = 'MORE_THAN_1000_HOURS_OF_SUPERVISION',
  ServesMicrosoft = 'SERVES_MICROSOFT',
}

/** The kinds of care provider tags that can exist */
export enum CareProviderTagKindEnumType {
  Condition = 'CONDITION',
  CriticalIncidentResponse = 'CRITICAL_INCIDENT_RESPONSE',
  DescriptionTag = 'DESCRIPTION_TAG',
  Ethnicity = 'ETHNICITY',
  Gender = 'GENDER',
  Language = 'LANGUAGE',
  Specialty = 'SPECIALTY',
  Team = 'TEAM',
  TreatmentModality = 'TREATMENT_MODALITY',
}

/** A care team for a member */
export type CareTeam = {
  __typename?: 'CareTeam';
  care_navigator: CareProvider;
  coach?: Maybe<CareProvider>;
  id: Scalars['ID'];
  medication_manager?: Maybe<CareProvider>;
  member: Member;
  peer_recovery_specialist?: Maybe<CareProvider>;
  therapist?: Maybe<CareProvider>;
};

export type CategoryInput = {
  category: ExerciseCategoryEnum;
  position?: InputMaybe<Scalars['Int']>;
};

/** A census error */
export type CensusError = {
  __typename?: 'CensusError';
  cohort: Cohort;
  erred_at: Scalars['DateTime'];
  error_msg?: Maybe<Scalars['String']>;
  error_row?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  validation_id: Scalars['ID'];
};

/** A census ingestion */
export type CensusIngestion = {
  __typename?: 'CensusIngestion';
  cancelled_at?: Maybe<Scalars['DateTime']>;
  cohort: Cohort;
  created_at: Scalars['DateTime'];
  csv_error?: Maybe<Scalars['String']>;
  csv_errored_at?: Maybe<Scalars['DateTime']>;
  customer: Customer;
  ended_at?: Maybe<Scalars['DateTime']>;
  errored: Scalars['Int'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  number_of_rows?: Maybe<Scalars['Int']>;
  progress_percentage: Scalars['Float'];
  rows_cleaned_at?: Maybe<Scalars['DateTime']>;
  rows_scheduled: Scalars['Int'];
  rows_scheduled_at?: Maybe<Scalars['DateTime']>;
  succeeded: Scalars['Int'];
  uploaded_by?: Maybe<User>;
  uploaded_by_user_id?: Maybe<Scalars['ID']>;
};

/** A census ingestion row */
export type CensusIngestionRow = {
  __typename?: 'CensusIngestionRow';
  covered_life_id?: Maybe<Scalars['ID']>;
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  parsing_errors?: Maybe<Scalars['JSON']>;
  record_changes?: Maybe<Scalars['JSON']>;
  row_data: Scalars['JSON'];
  row_number: Scalars['Int'];
  updated_at: Scalars['DateTime'];
  validation_errors?: Maybe<Scalars['JSON']>;
};

/** An Iterable channel type */
export type Channel = {
  __typename?: 'Channel';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
};

/** The channel partner who has many customers */
export type ChannelPartner = {
  __typename?: 'ChannelPartner';
  active_at: Scalars['DateTime'];
  channel_partner_sub_type: ChannelPartnerSubTypeEnumType;
  channel_partner_type: ChannelPartnerTypeEnumType;
  contract_end_at: Scalars['DateTime'];
  customers?: Maybe<Array<Maybe<Customer>>>;
  id: Scalars['ID'];
  landing_page_url: Scalars['String'];
  launched: Scalars['Boolean'];
  name: Scalars['String'];
  sso_client_slug?: Maybe<Scalars['String']>;
};

/** Indicates sub-type of Channel Partner */
export enum ChannelPartnerSubTypeEnumType {
  Referral = 'REFERRAL',
  Reseller = 'RESELLER',
}

/** Indicates type of Channel Partner */
export enum ChannelPartnerTypeEnumType {
  Partner = 'PARTNER',
  Payer = 'PAYER',
}

/** Medical chart for a member */
export type Chart = {
  __typename?: 'Chart';
  care_navigator_call_recommended?: Maybe<Scalars['Boolean']>;
  medication_manager_recommended?: Maybe<Scalars['Boolean']>;
};

export type ClonePlanYearsOutputType = {
  __typename?: 'ClonePlanYearsOutputType';
  contract_terms: Array<ContractTerm>;
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Coaching benefit information for a member */
export type CoachingBenefit = {
  __typename?: 'CoachingBenefit';
  allow_direct_scheduling?: Maybe<Scalars['Boolean']>;
  care_provider?: Maybe<CareProvider>;
  coaching_tier?: Maybe<Scalars['String']>;
  continued_coaching?: Maybe<Scalars['Boolean']>;
  enabled: Scalars['Boolean'];
  qualifies_for_coaching_via_CN?: Maybe<Scalars['Boolean']>;
  recommended?: Maybe<Scalars['Boolean']>;
  reschedule?: Maybe<Scalars['Boolean']>;
  sessions_remaining?: Maybe<Scalars['Int']>;
  sessions_used?: Maybe<Scalars['Int']>;
  show_coaching_paid_experience?: Maybe<Scalars['Boolean']>;
  unlimited_coaching_cn?: Maybe<Scalars['Boolean']>;
  unlimited_coaching_direct_scheduling?: Maybe<Scalars['Boolean']>;
};

/** A segment of a customer, that has certain capabilities */
export type Cohort = {
  __typename?: 'Cohort';
  administration_notes?: Maybe<Scalars['String']>;
  all_basic_visits_covered: Scalars['Int'];
  all_specialist_visits_covered: Scalars['Int'];
  basic_visits_covered: Scalars['Int'];
  benefits_summary?: Maybe<Scalars['String']>;
  can_access_provider?: Maybe<Scalars['Boolean']>;
  census_outreach_enabled: Scalars['Boolean'];
  child_dependents_allowed: Scalars['Boolean'];
  coaching_visits_covered?: Maybe<Scalars['Int']>;
  cohort_eap_link?: Maybe<Scalars['String']>;
  cohort_eap_text?: Maybe<Scalars['String']>;
  cohort_email_customizations?: Maybe<Array<Maybe<CohortEmailCustomization>>>;
  cohort_platform_services?: Maybe<Array<Maybe<CohortPlatformService>>>;
  contract_term?: Maybe<ContractTerm>;
  contract_terms: Array<ContractTerm>;
  country?: Maybe<Scalars['String']>;
  crisis_support_number?: Maybe<Scalars['String']>;
  customer: Customer;
  deactivation_grace_period?: Maybe<DeactivationGracePeriod>;
  dedicated_therapy_available: Scalars['Boolean'];
  default: Scalars['Boolean'];
  default_care_navigator?: Maybe<CareProvider>;
  default_care_navigator_id?: Maybe<Scalars['ID']>;
  default_language?: Maybe<Scalars['String']>;
  dependent_cohort?: Maybe<Cohort>;
  dependents_allowed: Scalars['Boolean'];
  eap_access_code_text?: Maybe<Scalars['String']>;
  eap_direct_dial_number?: Maybe<Scalars['String']>;
  eap_email?: Maybe<Scalars['String']>;
  eap_freephone_number?: Maybe<Scalars['String']>;
  eap_intro_text?: Maybe<Scalars['String']>;
  eap_sms_number?: Maybe<Scalars['String']>;
  general_support_number?: Maybe<Scalars['String']>;
  /** @deprecated Use scheduling_access to get the tier of a member */
  global_tier?: Maybe<Scalars['String']>;
  has_census_ingestions: Scalars['Boolean'];
  has_client_specific_network?: Maybe<Scalars['Boolean']>;
  has_dedicated_providers: Scalars['Boolean'];
  id: Scalars['ID'];
  in_network: Scalars['Boolean'];
  in_person_med_management_supported: Scalars['Boolean'];
  in_person_supported: Scalars['Boolean'];
  international: Scalars['Boolean'];
  /** @deprecated The frontend work this field was added for was modified to not need it pre-release. Purely vestigial and unused field. */
  is_v0_customer?: Maybe<Scalars['Boolean']>;
  /** @deprecated The frontend work this field was added for was modified to not need it pre-release. Purely vestigial and unused field. */
  is_v1_customer?: Maybe<Scalars['Boolean']>;
  /** @deprecated The frontend work this field was added for was modified to not need it pre-release. Purely vestigial and unused field. */
  is_v2_customer?: Maybe<Scalars['Boolean']>;
  /** The last of a cohort's contract terms, when sorted by the contract term's start date */
  last_starting_contract_term?: Maybe<ContractTerm>;
  member_termination_enabled: Scalars['Boolean'];
  members?: Maybe<Array<Maybe<Member>>>;
  members_count: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  specialist_visits_covered: Scalars['Int'];
  sponsored_visits_covered: Scalars['Int'];
  time_zone?: Maybe<Scalars['String']>;
  unlimited_coaching_cn_visits: Scalars['Boolean'];
  unlimited_coaching_direct_visits: Scalars['Boolean'];
};

/** The connection type for Cohort. */
export type CohortConnection = {
  __typename?: 'CohortConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CohortEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Cohort>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  total_count: Scalars['Int'];
};

/** A cohort deactivation run */
export type CohortDeactivation = {
  __typename?: 'CohortDeactivation';
  census_ingestion: CensusIngestion;
  cohort: Cohort;
  covered_lives_count: Scalars['Int'];
  deactivated_count: Scalars['Int'];
  failed_count: Scalars['Int'];
  id: Scalars['ID'];
  members_count: Scalars['Int'];
  metadata: Scalars['JSON'];
  processed_at?: Maybe<Scalars['ISO8601DateTime']>;
  status: Scalars['String'];
};

/** An edge in a connection. */
export type CohortEdge = {
  __typename?: 'CohortEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Cohort>;
};

/** Customization for cohort emails */
export type CohortEmailCustomization = {
  __typename?: 'CohortEmailCustomization';
  body?: Maybe<Scalars['String']>;
  cohort?: Maybe<Cohort>;
  id: Scalars['ID'];
  intro?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

/** A request to move certain members and covered lives from one cohort to another. */
export type CohortMoveRequest = {
  __typename?: 'CohortMoveRequest';
  covered_lives_count: Scalars['Int'];
  dependents_count?: Maybe<Scalars['Int']>;
  dependents_status: Scalars['String'];
  destination_cohort: Cohort;
  filters: Scalars['String'];
  id: Scalars['ID'];
  include_dependents: Scalars['Boolean'];
  move_all: Scalars['Boolean'];
  source_cohort: Cohort;
  user: User;
};

/** A platform service attached to a specific cohort */
export type CohortPlatformService = {
  __typename?: 'CohortPlatformService';
  cohort?: Maybe<Cohort>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  max_rate?: Maybe<Scalars['Float']>;
  min_rate?: Maybe<Scalars['Float']>;
  modifiers?: Maybe<Scalars['JSON']>;
  platform_service?: Maybe<PlatformService>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type CohortSignupDetails = {
  __typename?: 'CohortSignupDetails';
  child_dependents_allowed: Scalars['Boolean'];
  dependents_allowed: Scalars['Boolean'];
};

/** Status of the client in their journey to become a member */
export enum CompassClientSearchStatusEnumType {
  /** Covered lives (active and not claimed) and members (active and deactivated) */
  AllClients = 'ALL_CLIENTS',
  /** Covered Life (active) */
  CoveredLife = 'COVERED_LIFE',
  /** Not active member */
  DeactivatedMember = 'DEACTIVATED_MEMBER',
  /** Active member */
  EnrolledMember = 'ENROLLED_MEMBER',
  /** Covered Life (active and not claimed) */
  NotClaimedCoveredLife = 'NOT_CLAIMED_COVERED_LIFE',
}

/** A member that can have a null user */
export type CompassMember = {
  __typename?: 'CompassMember';
  access_limit_date?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  administration_notes?: Maybe<Scalars['String']>;
  aged_out_consent_fields?: Maybe<AgedOutRecord>;
  apero_record_id?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  assessments?: Maybe<Array<Maybe<Assessment>>>;
  attended_appointment_within_last_90_days?: Maybe<Scalars['Boolean']>;
  attended_appointment_within_last_180_days?: Maybe<Scalars['Boolean']>;
  beneficiaries?: Maybe<Array<Maybe<PrimaryGrantor>>>;
  care_advocate_supported?: Maybe<Scalars['Boolean']>;
  care_consultant_supported?: Maybe<Scalars['Boolean']>;
  care_items: CareItems;
  care_navigation_benefit?: Maybe<CareNavigationBenefit>;
  care_preference?: Maybe<CarePreference>;
  care_preference_questionnaire?: Maybe<Array<PreferenceQuestion>>;
  care_team?: Maybe<CareTeam>;
  ccof_policy_agreed_at?: Maybe<Scalars['DateTime']>;
  chart?: Maybe<Chart>;
  check_in_frequency_days?: Maybe<Scalars['Int']>;
  choose_user_cta_tag?: Maybe<Scalars['String']>;
  coaching_benefit?: Maybe<CoachingBenefit>;
  /** @deprecated Use coaching_benefit instead */
  coaching_tier?: Maybe<Scalars['String']>;
  cohort?: Maybe<Cohort>;
  community_care_supported?: Maybe<Scalars['Boolean']>;
  coppa_notice?: Maybe<Scalars['Boolean']>;
  country_code?: Maybe<Scalars['String']>;
  covered_life?: Maybe<CoveredLife>;
  created_at: Scalars['DateTime'];
  customer_site?: Maybe<CustomerSite>;
  customer_site_id?: Maybe<Scalars['ID']>;
  date_of_birth?: Maybe<Scalars['Date']>;
  deactivation_date?: Maybe<Scalars['DateTime']>;
  dependents_benefit?: Maybe<DependentsBenefit>;
  eap_email?: Maybe<Scalars['String']>;
  eap_link?: Maybe<Scalars['String']>;
  eap_phone?: Maybe<Scalars['String']>;
  electronic_comm_consent?: Maybe<Scalars['Boolean']>;
  eligible_for_insurance_validation?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  experience_state?: Maybe<ExperienceState>;
  first_name?: Maybe<Scalars['String']>;
  first_past_unrated_appointment?: Maybe<Appointment>;
  first_upcoming_appointments?: Maybe<FirstUpcomingAppointmentOutputType>;
  first_upcoming_appointments_by_provider: Array<Appointment>;
  full_address_available?: Maybe<Scalars['Boolean']>;
  get_specialty_program?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Maybe<MemberGoal>>>;
  grantor?: Maybe<PrimaryGrantor>;
  has_access_to_cost_estimation?: Maybe<Scalars['Boolean']>;
  has_received_care?: Maybe<Scalars['Boolean']>;
  health_info_consent?: Maybe<Scalars['Boolean']>;
  hipaa_privacy_policy?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  individual_deactivation: Scalars['Boolean'];
  informed_consent?: Maybe<Scalars['Boolean']>;
  initial_sud_risk?: Maybe<Scalars['String']>;
  inside_inactivation_window: Scalars['Boolean'];
  insurance_payers?: Maybe<Array<Maybe<InsurancePayer>>>;
  insurance_policy?: Maybe<MemberInsurancePolicy>;
  is_a_teenager?: Maybe<Scalars['Boolean']>;
  is_eligible_for_peer_recovery_support?: Maybe<Scalars['Boolean']>;
  is_global_t2?: Maybe<Scalars['Boolean']>;
  is_past_cancellation_threshold: Scalars['Boolean'];
  is_peer_recovery_specialist_supported?: Maybe<Scalars['Boolean']>;
  is_private_practice: Scalars['Boolean'];
  is_provisional: Scalars['Boolean'];
  is_work_life_supported?: Maybe<Scalars['Boolean']>;
  kareo_patient_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_notified_at?: Maybe<Scalars['DateTime']>;
  latest_sud_risk?: Maybe<Scalars['String']>;
  list_goal_types?: Maybe<Array<Scalars['String']>>;
  list_issues?: Maybe<Array<Scalars['String']>>;
  managed_dependents?: Maybe<Array<Maybe<Member>>>;
  manager?: Maybe<Member>;
  medication_management_benefit?: Maybe<MedicationManagementBenefit>;
  minor: Scalars['Boolean'];
  missed_late_cancel_policy_ack_required: Scalars['Boolean'];
  most_recent_assessment?: Maybe<Assessment>;
  most_recently_created_appointment_by_provider: Array<Appointment>;
  name?: Maybe<Scalars['String']>;
  no_show_late_cancel_acknowledged_at?: Maybe<Scalars['DateTime']>;
  offboarding_preferences?: Maybe<OffboardingPreferences>;
  opt_in_personal?: Maybe<Scalars['Boolean']>;
  optimism?: Maybe<Scalars['Int']>;
  patient_practice_location?: Maybe<PatientPracticeLocation>;
  payment_preference?: Maybe<MemberPaymentPreferenceEnumType>;
  phone?: Maybe<Scalars['String']>;
  /** represents available member preferences for therapist recommendations */
  possible_filters?: Maybe<RecommendedTherapistsPossibleFiltersOutputType>;
  postal_address?: Maybe<PostalAddress>;
  preferred_language?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  previsit?: Maybe<MemberPrevisit>;
  primary_covered_life?: Maybe<CoveredLife>;
  pronouns?: Maybe<Scalars['String']>;
  proposed_grantor_email?: Maybe<Scalars['String']>;
  push_opt_in?: Maybe<Scalars['Boolean']>;
  recently_transitioned_account?: Maybe<Scalars['String']>;
  recommended_therapists?: Maybe<Array<Maybe<RecommendedTherapistsResult>>>;
  risk?: Maybe<Scalars['String']>;
  scheduling_access: Scalars['Boolean'];
  sms_opt_in?: Maybe<Scalars['Boolean']>;
  spring_benefit?: Maybe<SpringBenefit>;
  sud_program?: Maybe<SudProgram>;
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
  terminate_at?: Maybe<Scalars['DateTime']>;
  terminate_reason?: Maybe<Scalars['String']>;
  therapy_benefit?: Maybe<TherapyBenefit>;
  /** @deprecated Use cohort scheduling access instead */
  tier_group: Scalars['Int'];
  time_zone?: Maybe<Scalars['String']>;
  todos?: Maybe<Array<Maybe<Todo>>>;
  total_completed_exercises?: Maybe<Scalars['Int']>;
  tracked_goals?: Maybe<Array<Maybe<MemberGoal>>>;
  unlimited_basic_visits: Scalars['Boolean'];
  unlimited_coaching_visits: Scalars['Boolean'];
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['String']>;
  valid_privacy_policy_agreement?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  visits_covered_basic: Scalars['Int'];
  visits_covered_coaching?: Maybe<Scalars['Int']>;
  visits_covered_specialist: Scalars['Int'];
  visits_covered_total?: Maybe<Scalars['Int']>;
  visits_eligibility_limit_date?: Maybe<Scalars['DateTime']>;
  visits_remaining_basic: Scalars['Int'];
  visits_remaining_coaching?: Maybe<Scalars['Int']>;
  visits_remaining_specialist: Scalars['Int'];
  visits_remaining_total?: Maybe<Scalars['Int']>;
  visits_used_basic: Scalars['Int'];
  visits_used_coaching: Scalars['Int'];
  visits_used_specialist: Scalars['Int'];
  visits_used_total: Scalars['Int'];
  within_aging_out?: Maybe<Scalars['String']>;
  workplace_consultation_supported?: Maybe<Scalars['Boolean']>;
  workplace_manager?: Maybe<Scalars['Boolean']>;
};

/** A member that can have a null user */
export type CompassMemberAssessmentsArgs = {
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A member that can have a null user */
export type CompassMemberRecommended_TherapistsArgs = {
  current_path?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<RecommendedTherapistsFiltersInputType>;
  version?: InputMaybe<Scalars['String']>;
};

/** A member */
export type CompassMemberSearchResult = {
  __typename?: 'CompassMemberSearchResult';
  active: Scalars['Boolean'];
  cohort?: Maybe<Cohort>;
  id: Scalars['ID'];
  user?: Maybe<User>;
};

/** A covered life or member object */
export type CompassSearchClient = CoveredLifeSearchResult | MemberSearchResult;

export type CompleteStepOutput = {
  __typename?: 'CompleteStepOutput';
  next_step?: Maybe<Step>;
  success: Scalars['Boolean'];
};

export type ConfirmAppointmentInput = {
  id: Scalars['ID'];
  memberId: Scalars['ID'];
};

/** Return from a ConfirmAppointment mutation */
export type ConfirmAppointmentOutput = {
  __typename?: 'ConfirmAppointmentOutput';
  appointment: LoginlessAppointment;
  success: Scalars['Boolean'];
};

/** Connected Benefit */
export type ConnectedBenefit = {
  __typename?: 'ConnectedBenefit';
  benefits_type: ConnectedBenefitEnumType;
  created_at: Scalars['DateTime'];
  default_description: Scalars['String'];
  id: Scalars['String'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
};

export enum ConnectedBenefitEnumType {
  Childcare = 'CHILDCARE',
  Fertility = 'FERTILITY',
  Financial = 'FINANCIAL',
  Medical = 'MEDICAL',
  MentalHealth = 'MENTAL_HEALTH',
}

/** A contract term of a Customer, granting benefits to its Cohorts */
export type ContractTerm = {
  __typename?: 'ContractTerm';
  allocations: Array<AppointmentAllocation>;
  /** @deprecated use denormalized_allocations_data instead */
  basic_visits_covered: Scalars['Int'];
  benefits_summary: Scalars['String'];
  census_dependents_only?: Maybe<Scalars['Boolean']>;
  child_dependents_allowed?: Maybe<Scalars['Boolean']>;
  coaching_support?: Maybe<Scalars['Boolean']>;
  cohort?: Maybe<Cohort>;
  /** @deprecated Contract Terms have only one cohort, use `ContractTerm.cohort` */
  cohorts?: Maybe<Array<Maybe<Cohort>>>;
  community_care_solution?: Maybe<Scalars['Boolean']>;
  continued_coaching?: Maybe<Scalars['Boolean']>;
  customer: Customer;
  customer_id: Scalars['ID'];
  dedicated: Scalars['Boolean'];
  denormalized_allocations_data: DenormalizedAllocationsData;
  dependents_allowed: Scalars['Boolean'];
  /** @deprecated use end_date instead */
  end_at: Scalars['DateTime'];
  end_date: Scalars['ISO8601Date'];
  has_plan_rate: Scalars['Boolean'];
  id: Scalars['ID'];
  in_person_med_management_supported?: Maybe<Scalars['Boolean']>;
  in_person_supported?: Maybe<Scalars['Boolean']>;
  last_plan_rate_uploaded_at?: Maybe<Scalars['DateTime']>;
  member_cost_of_coaching?: Maybe<Scalars['Float']>;
  moments_supported?: Maybe<Scalars['Boolean']>;
  no_show_term?: Maybe<NoShowTerm>;
  plan_name?: Maybe<Scalars['String']>;
  plan_rates?: Maybe<Array<Maybe<PlanRate>>>;
  scheduling_access: SchedulingAccess;
  sdoh_screener?: Maybe<Scalars['Boolean']>;
  /** @deprecated use denormalized_allocations_data instead */
  specialist_visits_covered: Scalars['Int'];
  /** @deprecated use denormalized_allocations_data instead */
  sponsored_visits_covered?: Maybe<Scalars['Int']>;
  /** @deprecated use denormalized_allocations_data instead */
  sponsored_visits_expires?: Maybe<Scalars['DateTime']>;
  /** @deprecated use denormalized_allocations_data instead */
  sponsored_visits_start_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated use start_date instead */
  start_at: Scalars['DateTime'];
  start_date: Scalars['ISO8601Date'];
  status: ContractTermStatusEnumType;
  sud_supported: Scalars['Boolean'];
  tobacco_cessation_purchased?: Maybe<Scalars['Boolean']>;
  tobacco_cessation_url?: Maybe<Scalars['String']>;
  /** @deprecated use denormalized_allocations_data instead */
  unlimited_visits_expires?: Maybe<Scalars['DateTime']>;
  /** @deprecated use denormalized_allocations_data instead */
  unlimited_visits_start_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated use denormalized_allocations_data instead */
  unlimited_visits_supported?: Maybe<Scalars['Boolean']>;
  virtual_session_cost_term?: Maybe<VirtualSessionCostTerm>;
  /** @deprecated use denormalized_allocations_data instead */
  visit_type?: Maybe<ContractTermVisitTypeEnumType>;
  wpo_coaching_supported?: Maybe<Scalars['Boolean']>;
};

/** Input for cloning a contract terms */
export type ContractTermCloneInputType = {
  contract_term_ids: Array<Scalars['ID']>;
};

/** The status of a Contract Term */
export enum ContractTermStatusEnumType {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  PreLaunch = 'PRE_LAUNCH',
}

/** Indicates type of visit covered for sponsored and unlimited */
export enum ContractTermVisitTypeEnumType {
  BasicAndSpecialist = 'BASIC_AND_SPECIALIST',
  BasicOnly = 'BASIC_ONLY',
}

/** A latitude/longitude pair */
export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Cost Estimate for an Encounter */
export type CostEstimate = {
  __typename?: 'CostEstimate';
  estimate?: Maybe<CostEstimateEstimate>;
  insurance?: Maybe<CostEstimateInsurance>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Estimate information from Encounter Cost Estimate */
export type CostEstimateEstimate = {
  __typename?: 'CostEstimateEstimate';
  billed_amount?: Maybe<Money>;
  coinsurance_estimate_percentage?: Maybe<Scalars['Float']>;
  copay_amount?: Maybe<Money>;
  covered_by_insurance_amount?: Maybe<Money>;
  individual_deductible_amount?: Maybe<Money>;
  individual_oop_amount?: Maybe<Money>;
  patient_responsibility_amount?: Maybe<Money>;
  patient_responsibility_amount_range?: Maybe<CostEstimatePatientResponsibilityAmountRange>;
  remaining_individual_deductible_amount?: Maybe<Money>;
  remaining_individual_oop_amount?: Maybe<Money>;
  spent_individual_deductible_amount?: Maybe<Money>;
  spent_individual_oop_amount?: Maybe<Money>;
};

/** Insurance information from Encounter Cost Estimate */
export type CostEstimateInsurance = {
  __typename?: 'CostEstimateInsurance';
  coverage_active?: Maybe<Scalars['Boolean']>;
  coverage_end?: Maybe<Scalars['Date']>;
  coverage_start?: Maybe<Scalars['Date']>;
  insurance_group_id?: Maybe<Scalars['ID']>;
  insurance_member_id?: Maybe<Scalars['ID']>;
  payer_id?: Maybe<Scalars['ID']>;
  payer_name?: Maybe<Scalars['String']>;
  plan_description?: Maybe<Scalars['String']>;
};

/** List of Cost Estimate Log Entries */
export type CostEstimateLogEntries = {
  __typename?: 'CostEstimateLogEntries';
  data: Array<Maybe<CostEstimateLogEntry>>;
};

/** Insurance information from Encounter Cost Estimate */
export type CostEstimateLogEntry = {
  __typename?: 'CostEstimateLogEntry';
  cpt_code?: Maybe<Scalars['String']>;
  cpt_code_modifier?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  date_of_service?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  member_id: Scalars['ID'];
  place_of_service?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vendor?: Maybe<Scalars['String']>;
};

export type CostEstimatePatientResponsibilityAmountRange = {
  __typename?: 'CostEstimatePatientResponsibilityAmountRange';
  max: Money;
  min: Money;
};

/** Country Info */
export type CountryInfo = {
  __typename?: 'CountryInfo';
  dial_code: Scalars['String'];
  flag: Scalars['String'];
  iso2: Scalars['String'];
  name: Scalars['String'];
};

/** A covered appointment */
export type CoveredAppointment = {
  __typename?: 'CoveredAppointment';
  appointment_date: Scalars['ISO8601DateTime'];
  appointment_id?: Maybe<Scalars['String']>;
  appointment_kind: AppointmentKindEnum;
  attended_status?: Maybe<Scalars['String']>;
  care_provider: Scalars['String'];
  care_provider_avatar: Scalars['String'];
};

/** A person who is eligible to use Spring */
export type CoveredLife = {
  __typename?: 'CoveredLife';
  census_outreach_enabled: Scalars['Boolean'];
  claimed: Scalars['Boolean'];
  cohort: Cohort;
  corporate_email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  date_of_birth?: Maybe<Scalars['Date']>;
  dependent: Scalars['Boolean'];
  direct_employer?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  grantor?: Maybe<PrimaryGrantor>;
  home_state?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invite_link?: Maybe<Scalars['String']>;
  invite_sent_at?: Maybe<Scalars['DateTime']>;
  invite_token?: Maybe<Scalars['String']>;
  last_name: Scalars['String'];
  member?: Maybe<Member>;
  office_city?: Maybe<Scalars['String']>;
  office_state?: Maybe<Scalars['String']>;
  other_1?: Maybe<Scalars['String']>;
  other_2?: Maybe<Scalars['String']>;
  other_3?: Maybe<Scalars['String']>;
  other_4?: Maybe<Scalars['String']>;
  other_5?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  professional_employer_organization?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

export type CoveredLifeAttributes = {
  census_outreach_enabled?: InputMaybe<Scalars['Boolean']>;
  cohort_id?: InputMaybe<Scalars['ID']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  dependent?: InputMaybe<Scalars['Boolean']>;
  invited?: InputMaybe<Scalars['Boolean']>;
};

/** A covered life deactivation run */
export type CoveredLifeDeactivation = {
  __typename?: 'CoveredLifeDeactivation';
  covered_life: CoveredLife;
  covered_life_id: Scalars['ID'];
  deactivated_at?: Maybe<Scalars['ISO8601DateTime']>;
  errored_at?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  last_seen_at?: Maybe<Scalars['ISO8601DateTime']>;
  member?: Maybe<Member>;
  member_id?: Maybe<Scalars['ID']>;
  status: Scalars['String'];
  validation_errors?: Maybe<Scalars['JSON']>;
};

/** A paginated list of covered life deactivations */
export type CoveredLifeDeactivations = {
  __typename?: 'CoveredLifeDeactivations';
  data: Array<CoveredLifeDeactivation>;
  paging: PagingData;
};

export type CoveredLifeInput = {
  campaign?: InputMaybe<CampaignInput>;
  city?: InputMaybe<Scalars['String']>;
  corporate_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['Date']>;
  dependent: Scalars['Boolean'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone_number?: InputMaybe<Scalars['String']>;
  primary_covered_life_id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of CoveredLife. */
export type CoveredLifePayload = {
  __typename?: 'CoveredLifePayload';
  covered_life?: Maybe<CoveredLife>;
};

/** Autogenerated return type of CoveredLifePrimary. */
export type CoveredLifePrimaryPayload = {
  __typename?: 'CoveredLifePrimaryPayload';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A covered life */
export type CoveredLifeSearchResult = {
  __typename?: 'CoveredLifeSearchResult';
  age?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invite_link?: Maybe<Scalars['String']>;
  invite_sent_at?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CoveredLifeWithoutCensusAndCohortInputType = {
  corporate_email: Scalars['String'];
  country?: Scalars['String'];
  customer_id: Scalars['ID'];
  date_of_birth?: InputMaybe<Scalars['Date']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  should_receive_invite?: InputMaybe<Scalars['Boolean']>;
};

export type CoveredLifeWithoutCensusInputType = {
  cohort_id: Scalars['ID'];
  corporate_email: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['Date']>;
  dependent?: InputMaybe<Scalars['Boolean']>;
  employee_id?: InputMaybe<Scalars['String']>;
  first_name: Scalars['String'];
  insurance_card_id?: InputMaybe<Scalars['String']>;
  last_name: Scalars['String'];
  should_receive_invite?: InputMaybe<Scalars['Boolean']>;
};

/** Input for creating an admin */
export type CreateAdminInput = {
  email: Scalars['TrimWhitespace'];
  first_name: Scalars['TrimWhitespace'];
  hipaa_and_security_trained: Scalars['Boolean'];
  last_name: Scalars['TrimWhitespace'];
  role: Scalars['String'];
};

export type CreateAdminOutput = {
  __typename?: 'CreateAdminOutput';
  success?: Maybe<Scalars['Boolean']>;
  user: User;
};

export type CreateAperoCustomerFieldsInput = {
  apero_customer_category_name?: InputMaybe<Scalars['String']>;
  apero_customer_name: Scalars['String'];
  apero_record_id: Scalars['ID'];
  customer_id: Scalars['ID'];
};

/** Return from a CreateAperoCustomer mutation */
export type CreateAperoCustomerOutput = {
  __typename?: 'CreateAperoCustomerOutput';
  apero_customer: AperoCustomer;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateAperoInvoiceFieldsInput = {
  appointment_id: Scalars['ID'];
};

/** Return from a CreateAperoInvoice mutation */
export type CreateAperoInvoiceOutput = {
  __typename?: 'CreateAperoInvoiceOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateAppointmentFieldsInput = {
  bookings: Array<BookingFieldsForAppointmentInput>;
  campaign?: InputMaybe<CampaignInput>;
  kind: RequestableAppointmentKindEnum;
  medium?: InputMaybe<AppointmentMediumEnum>;
  reason_for_scheduling?: InputMaybe<Scalars['String']>;
  specialty?: InputMaybe<Scalars['String']>;
  start_at: Scalars['DateTime'];
  sud_signal_flag?: InputMaybe<Scalars['String']>;
  time_zone?: InputMaybe<Scalars['String']>;
};

export type CreateAppointmentInput = {
  put?: InputMaybe<CreateAppointmentFieldsInput>;
};

/** Return from a CreateAppointment mutation */
export type CreateAppointmentOutput = {
  __typename?: 'CreateAppointmentOutput';
  appointment: Appointment;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCareNavigatorCustomerAssignmentFieldsInput = {
  assignment_type: Scalars['String'];
  customer_id: Scalars['String'];
};

export type CreateCareProviderAvailabilityEventOutput = {
  __typename?: 'CreateCareProviderAvailabilityEventOutput';
  care_provider_availability_event: CareProviderAvailabilityEvent;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCareProviderFieldsInput = {
  acuity_account?: InputMaybe<Scalars['String']>;
  acuity_calendar_id?: InputMaybe<Scalars['String']>;
  avatar: Scalars['DataURL'];
  bio: Scalars['String'];
  care_navigator_customer_assignment?: InputMaybe<CreateCareNavigatorCustomerAssignmentFieldsInput>;
  care_provider_tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  cohorts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first_name: Scalars['String'];
  /** @deprecated Use care_provider_tags instead */
  gender?: InputMaybe<Scalars['String']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last_name: Scalars['String'];
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  on_site?: InputMaybe<Scalars['Boolean']>;
  on_site_instructions?: InputMaybe<Scalars['String']>;
  roles: Array<InputMaybe<Scalars['String']>>;
  scheduling_url?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time_zone?: InputMaybe<Scalars['String']>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type CreateCareProviderOutput = {
  __typename?: 'CreateCareProviderOutput';
  care_provider?: Maybe<CareProvider>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for creating a channel partner */
export type CreateChannelPartnerFieldsInput = {
  active_at?: InputMaybe<Scalars['DateTime']>;
  channel_partner_sub_type?: InputMaybe<ChannelPartnerSubTypeEnumType>;
  channel_partner_type?: InputMaybe<ChannelPartnerTypeEnumType>;
  contract_end_at?: InputMaybe<Scalars['DateTime']>;
  customer_ids?: InputMaybe<Array<Scalars['ID']>>;
  landing_page_url?: InputMaybe<Scalars['String']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sso_client_slug?: InputMaybe<Scalars['String']>;
};

/** Input for creating a channel partner */
export type CreateChannelPartnerInput = {
  post?: InputMaybe<CreateChannelPartnerFieldsInput>;
};

export type CreateChannelPartnerOutput = {
  __typename?: 'CreateChannelPartnerOutput';
  channel_partner: ChannelPartner;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCohortEmailCustomizationInput = {
  body?: InputMaybe<Scalars['String']>;
  cohort_id: Scalars['ID'];
  intro?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type CreateCohortEmailCustomizationOutput = {
  __typename?: 'CreateCohortEmailCustomizationOutput';
  cohort_email_customization: CohortEmailCustomization;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCohortFieldsInput = {
  administration_notes?: InputMaybe<Scalars['String']>;
  can_access_provider?: InputMaybe<Scalars['Boolean']>;
  census_outreach_enabled?: InputMaybe<Scalars['Boolean']>;
  cohort_eap_link?: InputMaybe<Scalars['String']>;
  cohort_eap_text?: InputMaybe<Scalars['String']>;
  contract_term_id?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  crisis_support_number?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['String'];
  deactivation_grace_period?: InputMaybe<DeactivationGracePeriodInput>;
  default?: InputMaybe<Scalars['Boolean']>;
  default_care_navigator_id?: InputMaybe<Scalars['ID']>;
  default_language?: InputMaybe<Scalars['String']>;
  dependent_cohort_id?: InputMaybe<Scalars['ID']>;
  eap_access_code_text?: InputMaybe<Scalars['String']>;
  eap_direct_dial_number?: InputMaybe<Scalars['String']>;
  eap_email?: InputMaybe<Scalars['String']>;
  eap_freephone_number?: InputMaybe<Scalars['String']>;
  eap_intro_text?: InputMaybe<Scalars['String']>;
  eap_sms_number?: InputMaybe<Scalars['String']>;
  general_support_number?: InputMaybe<Scalars['String']>;
  /** @deprecated Use scheduling_access to get the tier of a member */
  global_tier?: InputMaybe<Scalars['String']>;
  has_client_specific_network?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  international?: InputMaybe<Scalars['Boolean']>;
  member_termination_enabled?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  time_zone?: InputMaybe<Scalars['String']>;
};

/** Input for creating a cohort move request */
export type CreateCohortMoveRequestInput = {
  corporate_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  covered_life_ids?: InputMaybe<Array<Scalars['ID']>>;
  destination_cohort_id: Scalars['ID'];
  first_name?: InputMaybe<Scalars['String']>;
  include_dependents: Scalars['Boolean'];
  last_name?: InputMaybe<Scalars['String']>;
  move_all: Scalars['Boolean'];
  office_city?: InputMaybe<Scalars['String']>;
  other_1?: InputMaybe<Scalars['String']>;
  other_2?: InputMaybe<Scalars['String']>;
  other_3?: InputMaybe<Scalars['String']>;
  other_4?: InputMaybe<Scalars['String']>;
  other_5?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  source_cohort_id: Scalars['ID'];
  user_id?: InputMaybe<Scalars['ID']>;
};

export type CreateCohortMoveRequestOutput = {
  __typename?: 'CreateCohortMoveRequestOutput';
  cohort_move_request: CohortMoveRequest;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCohortOutput = {
  __typename?: 'CreateCohortOutput';
  cohort: Cohort;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCohortPlaformServiceFieldsInput = {
  cohort_id: Scalars['ID'];
  max_rate?: InputMaybe<Scalars['Float']>;
  min_rate: Scalars['Float'];
  modifiers?: InputMaybe<Scalars['JSON']>;
  platform_service_id: Scalars['ID'];
};

export type CreateCohortPlatformServiceInput = {
  post?: InputMaybe<CreateCohortPlaformServiceFieldsInput>;
};

export type CreateCohortPlatformServiceOutput = {
  __typename?: 'CreateCohortPlatformServiceOutput';
  cohort_platform_service: CohortPlatformService;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCompassUserFieldsInput = {
  acuity_account?: InputMaybe<Scalars['String']>;
  acuity_calendar_id?: InputMaybe<Scalars['String']>;
  acuity_calendar_sync_enabled?: InputMaybe<Scalars['Boolean']>;
  admin_roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  associate_status?: InputMaybe<Scalars['String']>;
  avatar: Scalars['DataURL'];
  bio?: InputMaybe<Scalars['String']>;
  care_navigator_customer_assignment?: InputMaybe<CreateCareNavigatorCustomerAssignmentFieldsInput>;
  care_provider_tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  cohorts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country?: InputMaybe<Scalars['String']>;
  customer_sites?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first_name: Scalars['String'];
  /** @deprecated Use care_provider_tags instead */
  gender?: InputMaybe<Scalars['String']>;
  hipaa_and_security_trained?: InputMaybe<Scalars['Boolean']>;
  in_house_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last_name: Scalars['String'];
  license_number?: InputMaybe<Scalars['String']>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  on_site?: InputMaybe<Scalars['Boolean']>;
  on_site_instructions?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scheduling_constraints?: InputMaybe<SchedulingConstraintsInputType>;
  scheduling_setting?: InputMaybe<SchedulingSettingInputType>;
  scheduling_url?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time_zone?: InputMaybe<Scalars['String']>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type CreateCompassUserOutput = {
  __typename?: 'CreateCompassUserOutput';
  ehr_sync_success?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type CreateConnectedBenefitInput = {
  benefits_type: ConnectedBenefitEnumType;
  default_description: Scalars['String'];
  logo_url: Scalars['DataURL'];
  name: Scalars['String'];
  vendor_logo_original_file_name: Scalars['String'];
};

export type CreateConnectedBenefitOutput = {
  __typename?: 'CreateConnectedBenefitOutput';
  connected_benefit?: Maybe<ConnectedBenefit>;
  success: Scalars['Boolean'];
};

export type CreateContractTermFieldsInput = {
  basic_visits_covered: Scalars['Int'];
  benefits_summary: Scalars['String'];
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  coaching_support?: InputMaybe<Scalars['Boolean']>;
  cohort_id?: InputMaybe<Scalars['ID']>;
  community_care_solution?: InputMaybe<Scalars['Boolean']>;
  customer_id: Scalars['ID'];
  dependents_allowed: Scalars['Boolean'];
  end_at: Scalars['DateTime'];
  in_person_supported: Scalars['Boolean'];
  moments_supported?: InputMaybe<Scalars['Boolean']>;
  sdoh_screener?: InputMaybe<Scalars['Boolean']>;
  specialist_visits_covered: Scalars['Int'];
  sponsored_visits_covered?: InputMaybe<Scalars['Int']>;
  sponsored_visits_expires?: InputMaybe<Scalars['DateTime']>;
  sponsored_visits_start_at?: InputMaybe<Scalars['DateTime']>;
  start_at: Scalars['DateTime'];
  unlimited_visits_expires?: InputMaybe<Scalars['DateTime']>;
  unlimited_visits_start_at?: InputMaybe<Scalars['DateTime']>;
  unlimited_visits_supported?: InputMaybe<Scalars['Boolean']>;
  visit_type?: InputMaybe<ContractTermVisitTypeEnumType>;
};

export type CreateContractTermOutput = {
  __typename?: 'CreateContractTermOutput';
  contract_term: ContractTerm;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCoveredLifeOutput = {
  __typename?: 'CreateCoveredLifeOutput';
  cohort_id?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  covered_life_id?: Maybe<Scalars['ID']>;
  customer_id?: Maybe<Scalars['ID']>;
  invite_token?: Maybe<Scalars['String']>;
  invite_url?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerConnectedBenefitInput = {
  cohorts?: InputMaybe<Array<Scalars['String']>>;
  connected_benefit_id: Scalars['String'];
  connected_benefits_url: Scalars['String'];
  customer_id: Scalars['String'];
  dependent_enabled?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
};

export type CreateCustomerConnectedBenefitOutput = {
  __typename?: 'CreateCustomerConnectedBenefitOutput';
  customer_connected_benefit?: Maybe<CustomerConnectedBenefit>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for creating a Customer Deactivation */
export type CreateCustomerDeactivationInputType = {
  cohort_ids: Array<Scalars['ID']>;
  customer_id: Scalars['ID'];
  skip_failsafes?: InputMaybe<Scalars['Boolean']>;
};

/** Input fields for creating a customer */
export type CreateCustomerFieldsInput = {
  active_at?: InputMaybe<Scalars['DateTime']>;
  care_navigation_contract_type?: InputMaybe<Scalars['String']>;
  census_dob_format?: InputMaybe<Scalars['String']>;
  census_enabled?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Customers can have multiple channel partners, use channel_partner_ids instead. */
  channel_partner_id?: InputMaybe<Scalars['ID']>;
  channel_partner_ids?: InputMaybe<Array<Scalars['ID']>>;
  dedicated?: InputMaybe<Scalars['Boolean']>;
  dependent_eligibility_tier?: InputMaybe<DependentEligibilityTierEnum>;
  domain?: InputMaybe<Scalars['String']>;
  employment_id_kind?: InputMaybe<Scalars['String']>;
  enable_connected_benefits?: InputMaybe<Scalars['Boolean']>;
  enable_member_no_show_policy?: InputMaybe<Scalars['Boolean']>;
  in_network?: InputMaybe<Scalars['Boolean']>;
  inactive_at?: InputMaybe<Scalars['DateTime']>;
  include_in_comparative_analytics?: InputMaybe<Scalars['Boolean']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  marketing_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number_of_dependents?: InputMaybe<Scalars['Int']>;
  number_of_employees?: InputMaybe<Scalars['Int']>;
  personal_id_kind?: InputMaybe<Scalars['String']>;
  sso_client_slug?: InputMaybe<Scalars['String']>;
  sso_client_slugs?: InputMaybe<Array<Scalars['String']>>;
  trial?: InputMaybe<Scalars['Boolean']>;
};

/** Input for creating a customer */
export type CreateCustomerInput = {
  post?: InputMaybe<CreateCustomerFieldsInput>;
};

export type CreateCustomerOutput = {
  __typename?: 'CreateCustomerOutput';
  customer: Customer;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for creating a demo user covered life */
export type CreateDemoUserCoveredLifeFieldsInput = {
  covered_life: CoveredLifeWithoutCensusInputType;
  external_site_id?: InputMaybe<Scalars['String']>;
  purpose?: InputMaybe<Scalars['String']>;
};

/** Input for creating a demo user covered life */
export type CreateDemoUserCoveredLifeInput = {
  post: CreateDemoUserCoveredLifeFieldsInput;
};

export type CreateDocumentPresignedStatusInputType = {
  id: Scalars['ID'];
  object_key: Scalars['String'];
};

export type CreateDocumentPresignedUrlInputType = {
  file_name: Scalars['String'];
  id: Scalars['ID'];
  md5: Scalars['String'];
};

/** Details around uploading insurance card images for a member */
export type CreateDocumentUploadPresignedStatusOutput = {
  __typename?: 'CreateDocumentUploadPresignedStatusOutput';
  tagging_url?: Maybe<Scalars['String']>;
};

/** Details around uploading insurance card images for a member */
export type CreateDocumentUploadPresignedUrlOutput = {
  __typename?: 'CreateDocumentUploadPresignedUrlOutput';
  object_key?: Maybe<Scalars['String']>;
  tagging_header?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CreateEhrOutput = {
  __typename?: 'CreateEHROutput';
  member: Member;
  success?: Maybe<Scalars['Boolean']>;
};

/** Return from a CreateEvaluation mutation */
export type CreateEvaluationOutput = {
  __typename?: 'CreateEvaluationOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateExerciseInput = {
  activity_type: Activity;
  categories?: InputMaybe<Array<CategoryInput>>;
  duration: Scalars['String'];
  emotions?: InputMaybe<Array<EmotionInput>>;
  images?: InputMaybe<Array<ImageInput>>;
  steps?: InputMaybe<Array<StepInput>>;
  title: Scalars['String'];
};

export type CreateExerciseOutput = {
  __typename?: 'CreateExerciseOutput';
  exercise?: Maybe<Exercise>;
  success: Scalars['Boolean'];
};

/** Input for createExternalCalendarSync mutation */
export type CreateExternalCalendarSyncInput = {
  additional_calendars?: InputMaybe<Array<GoogleCalendarInput>>;
  care_provider_id: Scalars['ID'];
  primary_calendar: GoogleCalendarInput;
};

/** Return from a createExternalCalendarSync mutation */
export type CreateExternalCalendarSyncOutput = {
  __typename?: 'CreateExternalCalendarSyncOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for createGoogleToken mutation */
export type CreateGoogleTokenInput = {
  care_provider_id: Scalars['ID'];
  code: Scalars['String'];
};

/** Return from a createGoogleToken mutation */
export type CreateGoogleTokenOutput = {
  __typename?: 'CreateGoogleTokenOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateMemberPreferenceOutputType = {
  __typename?: 'CreateMemberPreferenceOutputType';
  member_preferences?: Maybe<Array<MemberPreference>>;
  success: Scalars['Boolean'];
};

/** Input for member providing feedback on a personalized provider bio */
export type CreatePersonalizedProviderBioFeedbackInput = {
  bio: Scalars['String'];
  care_provider_id: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
  feedback_enum: Scalars['String'];
  feedback_sentiment: Scalars['String'];
  feedback_text: Scalars['String'];
  personalized_provider_bio_id: Scalars['ID'];
};

/** Return from a PersonalizedProviderBioFeedback mutation */
export type CreatePersonalizedProviderBioFeedbackOutput = {
  __typename?: 'CreatePersonalizedProviderBioFeedbackOutput';
  success: Scalars['Boolean'];
};

export type CreatePlanYearFieldsInput = {
  benefits_summary?: InputMaybe<Scalars['String']>;
  bundled_basic: Scalars['Int'];
  bundled_coaching: Scalars['Int'];
  bundled_specialist: Scalars['Int'];
  bundled_unlimited_coaching: Scalars['Boolean'];
  census_dependents_only?: InputMaybe<Scalars['Boolean']>;
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  coaching_support?: InputMaybe<Scalars['Boolean']>;
  cohort_id?: InputMaybe<Scalars['ID']>;
  community_care_solution?: InputMaybe<Scalars['Boolean']>;
  continued_coaching?: InputMaybe<Scalars['Boolean']>;
  customer_display_name?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['ID'];
  dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Use end_date instead */
  end_at?: InputMaybe<Scalars['DateTime']>;
  end_date: Scalars['ISO8601Date'];
  in_person_med_management_supported?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  member_cost_of_coaching?: InputMaybe<Scalars['Float']>;
  moments_supported?: InputMaybe<Scalars['Boolean']>;
  no_show_term_attributes?: InputMaybe<NoShowTermFieldsInput>;
  plan_name?: InputMaybe<Scalars['String']>;
  scheduling_access: Scalars['String'];
  sdoh_screener?: InputMaybe<Scalars['Boolean']>;
  sponsored_basic: Scalars['Int'];
  sponsored_coaching: Scalars['Int'];
  /** @deprecated Use sponsored_end_date instead */
  sponsored_end_at?: InputMaybe<Scalars['DateTime']>;
  sponsored_end_date?: InputMaybe<Scalars['ISO8601Date']>;
  sponsored_specialist: Scalars['Int'];
  /** @deprecated Use sponsored_start_date instead */
  sponsored_start_at?: InputMaybe<Scalars['DateTime']>;
  sponsored_start_date?: InputMaybe<Scalars['ISO8601Date']>;
  sponsored_unlimited_basic: Scalars['Boolean'];
  sponsored_unlimited_coaching: Scalars['Boolean'];
  /** @deprecated Use start_date instead */
  start_at?: InputMaybe<Scalars['DateTime']>;
  start_date: Scalars['ISO8601Date'];
  sud_supported?: InputMaybe<Scalars['Boolean']>;
  tobacco_cessation_purchased?: InputMaybe<Scalars['Boolean']>;
  tobacco_cessation_url?: InputMaybe<Scalars['String']>;
  virtual_session_cost_term_attributes?: InputMaybe<VirtualSessionCostTermCreateInputType>;
  work_life_supported?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePlatformServiceFieldsInput = {
  appointment_kind: Scalars['String'];
  description: Scalars['String'];
  label: Scalars['String'];
};

export type CreatePlatformServiceInput = {
  post?: InputMaybe<CreatePlatformServiceFieldsInput>;
};

export type CreatePlatformServiceOutput = {
  __typename?: 'CreatePlatformServiceOutput';
  platform_service: PlatformService;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatePotentialCoveredLifeOutput = {
  __typename?: 'CreatePotentialCoveredLifeOutput';
  potential_covered_life_id?: Maybe<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for creating a Referral */
export type CreateReferralInput = {
  consent?: InputMaybe<Scalars['Boolean']>;
  contact_preferences?: InputMaybe<Scalars['String']>;
  external_program_id: Scalars['String'];
  external_program_name?: InputMaybe<Scalars['String']>;
  is_external_referral?: InputMaybe<Scalars['Boolean']>;
  /** Receiver. The person who will receive information about the program */
  member_id: Scalars['ID'];
  /** (Not supported yet) used if the person creating the referral is not the receiver */
  sender_member_id?: InputMaybe<Scalars['ID']>;
  service_tags?: InputMaybe<Array<Scalars['String']>>;
};

/** Output from referral creation */
export type CreateReferralOutput = {
  __typename?: 'CreateReferralOutput';
  referral: Referral;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for creating a reporting integration */
export type CreateReportingIntegrationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  aws_handle?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  delimiter?: InputMaybe<Scalars['String']>;
  delivery_method?: InputMaybe<Scalars['String']>;
  delta_only?: InputMaybe<Scalars['Boolean']>;
  file_content_type?: Scalars['String'];
  filename: Scalars['String'];
  frequency?: InputMaybe<Scalars['String']>;
  header_casing?: InputMaybe<Scalars['String']>;
  include_record_count?: InputMaybe<Scalars['Boolean']>;
  integration_type?: InputMaybe<Scalars['String']>;
  last_run_at?: InputMaybe<Scalars['DateTime']>;
  monthly_reporting_date?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  partner_customization?: InputMaybe<Scalars['String']>;
  partner_customization_options?: InputMaybe<Scalars['JSON']>;
  pgp_encrypted_file?: InputMaybe<Scalars['Boolean']>;
  prepend_count_label?: InputMaybe<Scalars['String']>;
  report_type?: InputMaybe<Scalars['String']>;
  reporting_integration_partner_id: Scalars['ID'];
  row_terminator?: InputMaybe<Scalars['String']>;
  scheduled_to_run_at: Scalars['DateTime'];
  snowflake_query_statement: Scalars['String'];
};

export type CreateReportingIntegrationOutput = {
  __typename?: 'CreateReportingIntegrationOutput';
  errors?: Maybe<Array<Scalars['String']>>;
  reporting_integration?: Maybe<ReportingIntegration>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for creating a reporting integration partner */
export type CreateReportingIntegrationPartnerInput = {
  name: Scalars['String'];
};

export type CreateReportingIntegrationPartnerOutput = {
  __typename?: 'CreateReportingIntegrationPartnerOutput';
  errors?: Maybe<Array<Scalars['String']>>;
  reporting_integration_partner?: Maybe<ReportingIntegrationPartner>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSettingFieldsInput = {
  name: Scalars['String'];
  value: Scalars['JSON'];
};

export type CreateSettingInput = {
  post?: InputMaybe<CreateSettingFieldsInput>;
};

/** Return from a CreateSetting mutation */
export type CreateSettingOutput = {
  __typename?: 'CreateSettingOutput';
  setting: Setting;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateStepInput = {
  audio_url?: InputMaybe<Scalars['String']>;
  caption_url?: InputMaybe<Scalars['String']>;
  content_format: Scalars['String'];
  exercise_id: Scalars['ID'];
  position: Scalars['Int'];
  rich_text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  video_url?: InputMaybe<Scalars['String']>;
};

/** Input for creating a trial */
export type CreateTrialInput = {
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  coaching_supported?: InputMaybe<Scalars['Boolean']>;
  cohort_name?: InputMaybe<Scalars['String']>;
  company_name: Scalars['String'];
  create_passwords?: InputMaybe<Scalars['Boolean']>;
  dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  end_date: Scalars['Date'];
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  moments_supported?: InputMaybe<Scalars['Boolean']>;
  start_date: Scalars['Date'];
  trial_users: Array<TrialUserInput>;
};

export type CreateTrialOutput = {
  __typename?: 'CreateTrialOutput';
  success?: Maybe<Scalars['Boolean']>;
  trial?: Maybe<Trial>;
};

/** The client who is paying for care for members */
export type Customer = {
  __typename?: 'Customer';
  active: Scalars['Boolean'];
  active_at?: Maybe<Scalars['DateTime']>;
  cancellation_policy_gated_booking_enabled: Scalars['Boolean'];
  care_navigation_contract_type: Scalars['String'];
  census_dob_format: Scalars['String'];
  census_enabled: Scalars['Boolean'];
  census_outreach_enabled: Scalars['Boolean'];
  /** @deprecated Customers can have multiple channel partners. Use channel_partners instead. */
  channel_partner?: Maybe<ChannelPartner>;
  channel_partners?: Maybe<Array<ChannelPartner>>;
  cohorts: Array<Maybe<Cohort>>;
  contract_terms: Array<Maybe<ContractTerm>>;
  customer_sites: Array<CustomerSite>;
  dedicated: Scalars['Boolean'];
  default_cohort?: Maybe<Cohort>;
  dependent_eligibility_tier: DependentEligibilityTierEnum;
  direct_employers: Array<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  domains: Array<Maybe<Scalars['String']>>;
  eap_email?: Maybe<Scalars['String']>;
  eap_link?: Maybe<Scalars['String']>;
  eap_phone?: Maybe<Scalars['String']>;
  employment_id_kind: Scalars['String'];
  enable_connected_benefits?: Maybe<Scalars['Boolean']>;
  enable_member_no_show_policy?: Maybe<Scalars['Boolean']>;
  has_monthly_awareness_campaign_enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** @deprecated in_network has been removed from customer. Please use cohort.in_network instead */
  in_network: Scalars['Boolean'];
  inactive_at?: Maybe<Scalars['DateTime']>;
  include_in_comparative_analytics: Scalars['Boolean'];
  international_dependents?: Maybe<Scalars['Int']>;
  international_employees?: Maybe<Scalars['Int']>;
  is_health_plan: Scalars['Boolean'];
  launched: Scalars['Boolean'];
  marketing_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number_of_dependents?: Maybe<Scalars['Int']>;
  number_of_employees: Scalars['Int'];
  personal_id_kind: Scalars['String'];
  professional_employer_organizations: Array<Scalars['String']>;
  slug: Scalars['String'];
  sso_client_slug?: Maybe<Scalars['String']>;
  sso_client_slugs?: Maybe<Array<Scalars['String']>>;
  sso_slugs?: Maybe<Array<Scalars['String']>>;
  trial: Scalars['Boolean'];
  us_dependents?: Maybe<Scalars['Int']>;
  us_employees?: Maybe<Scalars['Int']>;
  wpo_dependents?: Maybe<Scalars['Int']>;
  wpo_employees?: Maybe<Scalars['Int']>;
};

/** Customer Apero Record */
export type CustomerAperoRecord = {
  __typename?: 'CustomerAperoRecord';
  category: Scalars['ID'];
  company_name: Scalars['String'];
  display_name: Scalars['String'];
  id: Scalars['ID'];
  linked_ehr_id: Scalars['ID'];
};

/** Customer's Connected Benefit */
export type CustomerConnectedBenefit = {
  __typename?: 'CustomerConnectedBenefit';
  benefits_type: Scalars['String'];
  cohorts: Array<Scalars['ConnectedBenefitCohort']>;
  connected_benefit_id: Scalars['String'];
  connected_benefit_name: Scalars['String'];
  connected_benefits_url: Scalars['String'];
  customer_connected_benefit_id: Scalars['String'];
  customer_id: Scalars['String'];
  dependent_enabled: Scalars['Boolean'];
  description: Scalars['String'];
};

export type CustomerConnectedBenefitOutputType = {
  __typename?: 'CustomerConnectedBenefitOutputType';
  data: Array<Maybe<CustomerConnectedBenefit>>;
};

/** A customer deactivation run */
export type CustomerDeactivation = {
  __typename?: 'CustomerDeactivation';
  appointments_to_be_cancelled_count?: Maybe<Scalars['Int']>;
  blocked_by_failsafes: Scalars['Boolean'];
  cohort_deactivations: Array<CohortDeactivation>;
  covered_lives_count: Scalars['Int'];
  created_at: Scalars['ISO8601DateTime'];
  created_by?: Maybe<User>;
  created_by_user_id?: Maybe<Scalars['ID']>;
  customer: Customer;
  customer_id: Scalars['ID'];
  deactivated_count: Scalars['Int'];
  dependent_covered_lives_count: Scalars['Int'];
  dependent_members_count: Scalars['Int'];
  excluded_cohort_names: Array<Scalars['String']>;
  failed_count: Scalars['Int'];
  failsafes_triggered: Scalars['JSON'];
  id: Scalars['ID'];
  members_count: Scalars['Int'];
  preview_ready: Scalars['Boolean'];
  processed_at?: Maybe<Scalars['ISO8601DateTime']>;
  processed_by?: Maybe<User>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type CustomerDeactivationOutput = {
  __typename?: 'CustomerDeactivationOutput';
  customer_deactivation?: Maybe<CustomerDeactivation>;
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A paginated list of customer deactivations */
export type CustomerDeactivations = {
  __typename?: 'CustomerDeactivations';
  data: Array<CustomerDeactivation>;
  paging: PagingData;
};

/** Metrics for a specific customer */
export type CustomerMetrics = {
  __typename?: 'CustomerMetrics';
  all_members_by_risk_percentages?: Maybe<Array<Maybe<FloatDataPoint>>>;
  care_appointments_series?: Maybe<IntegerTimeSeries>;
  care_appointments_total?: Maybe<Scalars['Int']>;
  covered_lives_total?: Maybe<Scalars['Int']>;
  customer_ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  days_missed_average?: Maybe<Scalars['Float']>;
  days_unproductive_average?: Maybe<Scalars['Float']>;
  members_by_risk?: Maybe<IntegerDataSet>;
  members_series?: Maybe<IntegerTimeSeries>;
  members_total?: Maybe<Scalars['Int']>;
  members_with_positive_conditions_breakdown?: Maybe<IntegerDataSet>;
  members_with_positive_conditions_total?: Maybe<Scalars['Int']>;
  questionnaires_series?: Maybe<IntegerTimeSeries>;
  questionnaires_total?: Maybe<Scalars['Int']>;
};

/** Custom role name defined by customer */
export type CustomerRoleName = {
  __typename?: 'CustomerRoleName';
  custom_name: Scalars['String'];
  customer_id: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

/** customer site locations for employer services */
export type CustomerSite = {
  __typename?: 'CustomerSite';
  address?: Maybe<Scalars['String']>;
  appointment_directions?: Maybe<Scalars['String']>;
  care_providers: Array<CareProvider>;
  city?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  customer: Customer;
  external_site_id: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

/** The grace period for deactivaiton */
export type DeactivationGracePeriod = {
  __typename?: 'DeactivationGracePeriod';
  days?: Maybe<Scalars['Int']>;
  months?: Maybe<Scalars['Int']>;
  weeks?: Maybe<Scalars['Int']>;
};

/** The grace period for deactivaiton */
export type DeactivationGracePeriodInput = {
  days?: InputMaybe<Scalars['Int']>;
  months?: InputMaybe<Scalars['Int']>;
  weeks?: InputMaybe<Scalars['Int']>;
};

export type DeleteCohortPlatformServiceInput = {
  id: Scalars['ID'];
};

export type DeleteCohortPlatformServiceOutput = {
  __typename?: 'DeleteCohortPlatformServiceOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteConnectedBenefitOutput = {
  __typename?: 'DeleteConnectedBenefitOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteContractTermOutput = {
  __typename?: 'DeleteContractTermOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** Return from a deleteExternalCalendarSync mutation */
export type DeleteExternalCalendarSyncOutput = {
  __typename?: 'DeleteExternalCalendarSyncOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** A covered life created by a demo user */
export type DemoUserCoveredLife = {
  __typename?: 'DemoUserCoveredLife';
  covered_life?: Maybe<CoveredLife>;
  id: Scalars['ID'];
  purpose?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type DemoUserCoveredLifeOutput = {
  __typename?: 'DemoUserCoveredLifeOutput';
  demo_user_covered_life?: Maybe<DemoUserCoveredLife>;
  errors?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** A contract term's allocation data denormalized as plain form input values */
export type DenormalizedAllocationsData = {
  __typename?: 'DenormalizedAllocationsData';
  bundled_basic: Scalars['Int'];
  bundled_coaching: Scalars['Int'];
  bundled_specialist: Scalars['Int'];
  bundled_unlimited_coaching: Scalars['Boolean'];
  sponsored_basic: Scalars['Int'];
  sponsored_coaching: Scalars['Int'];
  /** @deprecated use sponsored_end_date instead */
  sponsored_end_at?: Maybe<Scalars['DateTime']>;
  sponsored_end_date?: Maybe<Scalars['ISO8601Date']>;
  sponsored_specialist: Scalars['Int'];
  /** @deprecated use sponsored_start_date instead */
  sponsored_start_at?: Maybe<Scalars['DateTime']>;
  sponsored_start_date?: Maybe<Scalars['ISO8601Date']>;
  sponsored_unlimited_basic: Scalars['Boolean'];
  sponsored_unlimited_coaching: Scalars['Boolean'];
};

/** Indicates the current tier of the Customer dependent eligibility */
export enum DependentEligibilityTierEnum {
  AllHousehold = 'ALL_HOUSEHOLD',
  BenefitEligibleDependents = 'BENEFIT_ELIGIBLE_DEPENDENTS',
  BenefitEnrolledDependents = 'BENEFIT_ENROLLED_DEPENDENTS',
  None = 'NONE',
  Unknown = 'UNKNOWN',
}

/** Dependents benefit information for a member */
export type DependentsBenefit = {
  __typename?: 'DependentsBenefit';
  delete_me?: Maybe<Scalars['Boolean']>;
};

export type DestroyCareProviderAvailabilityEventFieldsInput = {
  care_provider_id: Scalars['ID'];
  id: Scalars['ID'];
};

export type DestroyCareProviderAvailabilityEventInput = {
  delete?: InputMaybe<DestroyCareProviderAvailabilityEventFieldsInput>;
};

export type DestroyCareProviderAvailabilityEventOutput = {
  __typename?: 'DestroyCareProviderAvailabilityEventOutput';
  care_provider_availability_event?: Maybe<CareProviderAvailabilityEvent>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DestroySettingFieldsInput = {
  id: Scalars['ID'];
};

export type DestroySettingInput = {
  delete?: InputMaybe<DestroySettingFieldsInput>;
};

export type DestroySettingOutput = {
  __typename?: 'DestroySettingOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type DestroyTrialFieldsInput = {
  id: Scalars['ID'];
};

export type DestroyTrialInput = {
  delete?: InputMaybe<DestroyTrialFieldsInput>;
};

export type DestroyTrialOutput = {
  __typename?: 'DestroyTrialOutput';
  success?: Maybe<Scalars['Boolean']>;
  trial?: Maybe<Trial>;
};

export type DestroyUserFieldsInput = {
  id: Scalars['ID'];
};

export type DestroyUserInput = {
  delete?: InputMaybe<DestroyUserFieldsInput>;
};

export type DestroyUserOutput = {
  __typename?: 'DestroyUserOutput';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type DistanceFromSearchInput = {
  address: Scalars['String'];
};

/** Document details */
export type DocumentOutput = {
  __typename?: 'DocumentOutput';
  file_name: Scalars['String'];
  url: Scalars['String'];
};

export type Element = {
  __typename?: 'Element';
  content?: Maybe<Scalars['String']>;
  media_uri?: Maybe<Scalars['String']>;
  type: ElementEnum;
};

/** Indicates the type of an element */
export enum ElementEnum {
  Audio = 'AUDIO',
  RichText = 'RICH_TEXT',
  Video = 'VIDEO',
}

/** An email event type */
export type EmailEvent = {
  __typename?: 'EmailEvent';
  campaign_id?: Maybe<Scalars['Int']>;
  campaign_name?: Maybe<Scalars['String']>;
  channel_id?: Maybe<Scalars['Int']>;
  channel_name?: Maybe<Scalars['String']>;
  created_at: Scalars['String'];
  email_link?: Maybe<Scalars['String']>;
  event_type: Scalars['String'];
  message_id?: Maybe<Scalars['String']>;
  message_name?: Maybe<Scalars['String']>;
  message_type_id?: Maybe<Scalars['Int']>;
  template_id?: Maybe<Scalars['Int']>;
};

/** Indicates the emotion the exercise is for */
export enum Emotion {
  Anxious = 'ANXIOUS',
  Curious = 'CURIOUS',
  Restless = 'RESTLESS',
  Sad = 'SAD',
  Sleepless = 'SLEEPLESS',
  Stressed = 'STRESSED',
}

export type EmotionInput = {
  emotion: Emotion;
  position: Scalars['Int'];
};

export enum EntityEnumType {
  /** a care provider */
  CareProvider = 'CARE_PROVIDER',
  /** a covered life */
  CoveredLife = 'COVERED_LIFE',
  /** a member */
  Member = 'MEMBER',
  /** a user */
  User = 'USER',
}

/** A list of email events */
export type Events = {
  __typename?: 'Events';
  data: Array<Maybe<EmailEvent>>;
};

export type Exercise = {
  __typename?: 'Exercise';
  activity_type: Activity;
  categories: Array<ExerciseCategory>;
  completed: Scalars['Boolean'];
  content_formats: Array<Scalars['String']>;
  duration: Scalars['String'];
  emotions: Array<ExerciseEmotion>;
  id: Scalars['ID'];
  images: Array<Image>;
  starting_step: Step;
  steps: Array<Step>;
  title: Scalars['String'];
  total_steps: Scalars['Int'];
};

export type ExerciseCategory = {
  __typename?: 'ExerciseCategory';
  category: ExerciseCategoryEnum;
  position?: Maybe<Scalars['Int']>;
};

/** Indicates the category of an exercise */
export enum ExerciseCategoryEnum {
  Anxiety = 'ANXIETY',
  BodyImage = 'BODY_IMAGE',
  Breakup = 'BREAKUP',
  Burnout = 'BURNOUT',
  Depression = 'DEPRESSION',
  Eating = 'EATING',
  Emotions = 'EMOTIONS',
  Exercise = 'EXERCISE',
  Focus = 'FOCUS',
  GettingStarted = 'GETTING_STARTED',
  JobCareer = 'JOB_CAREER',
  Loneliness = 'LONELINESS',
  Mindfulness = 'MINDFULNESS',
  Parenting = 'PARENTING',
  PersonalGrowth = 'PERSONAL_GROWTH',
  Relationships = 'RELATIONSHIPS',
  SelfEsteem = 'SELF_ESTEEM',
  Sleep = 'SLEEP',
  Stress = 'STRESS',
  SubstanceUse = 'SUBSTANCE_USE',
}

export type ExerciseEmotion = {
  __typename?: 'ExerciseEmotion';
  emotion: Emotion;
  position: Scalars['Int'];
};

/** The experience state for a member */
export type ExperienceState = {
  __typename?: 'ExperienceState';
  banner_moments_count?: Maybe<Scalars['Int']>;
  family_todo_count?: Maybe<Scalars['Int']>;
  mobile_banner_moments_count?: Maybe<Scalars['Int']>;
  modal_mobile_app_count?: Maybe<Scalars['Int']>;
  should_update_settings?: Maybe<Scalars['Boolean']>;
  update_settings_shown_count?: Maybe<Scalars['Int']>;
  visited_mobile_app_home_tab?: Maybe<Scalars['Boolean']>;
  visited_your_journey?: Maybe<Scalars['Boolean']>;
  workplace_banner_dismissed?: Maybe<Scalars['Boolean']>;
  workplace_page_viewed?: Maybe<Scalars['Boolean']>;
};

/** an external calendar sync item of a provider */
export type ExternalCalendarSyncItem = {
  __typename?: 'ExternalCalendarSyncItem';
  /** @deprecated This field was never used. */
  calendar_timezone: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  end_at: Scalars['DateTime'];
  external_id: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  start_at: Scalars['DateTime'];
};

/** external calendar sync items of a provider */
export type ExternalCalendarSyncItems = {
  __typename?: 'ExternalCalendarSyncItems';
  data: Array<Maybe<ExternalCalendarSyncItem>>;
};

/** Returned data from a FetchAppointmentSlots query */
export type FetchAppointmentSlotsOutput = {
  __typename?: 'FetchAppointmentSlotsOutput';
  admin_available: Array<Maybe<Scalars['DateTime']>>;
  available: Array<Maybe<Scalars['DateTime']>>;
  blocked: Array<Maybe<Scalars['DateTime']>>;
  duration: Scalars['Int'];
  kind: AppointmentKindEnum;
  slot_increment: Scalars['Int'];
};

/** Returned data from a FetchAppointmentSlots query */
export type FetchAvailableAppointmentSlotsOutput = {
  __typename?: 'FetchAvailableAppointmentSlotsOutput';
  in_person?: Maybe<AvailabilityOutput>;
  next_available_appointment?: Maybe<NextAvailableAppointment>;
  phone?: Maybe<AvailabilityOutput>;
  video?: Maybe<AvailabilityOutput>;
};

/** Return from a google_calendars query */
export type FetchGoogleCalendarOutput = {
  __typename?: 'FetchGoogleCalendarOutput';
  calendars?: Maybe<Array<GoogleCalendarOutput>>;
  success?: Maybe<Scalars['Boolean']>;
};

/** List of Member Preferences */
export type FetchMemberPreference = {
  __typename?: 'FetchMemberPreference';
  names: Array<PreferenceEnumType>;
};

/** Return from a external_calendar_sync_status query */
export type FetchSyncStatusOutput = {
  __typename?: 'FetchSyncStatusOutput';
  additional_calendars?: Maybe<Array<GoogleCalendarOutput>>;
  email?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  primary_calendar?: Maybe<GoogleCalendarOutput>;
  synced?: Maybe<Scalars['Boolean']>;
};

/** Result of a search for a covered life */
export type FindCoveredLifeOutput = {
  __typename?: 'FindCoveredLifeOutput';
  /** @deprecated No longer used */
  child_dependents_allowed?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  /** @deprecated Use email_sent instead */
  claimed?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer used */
  cohort_id?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  covered_life_id?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['ID']>;
  /** @deprecated No longer used */
  customer_name?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_sent?: Maybe<Scalars['Boolean']>;
  first_name?: Maybe<Scalars['String']>;
  invite_token?: Maybe<Scalars['String']>;
  is_dependent?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** @deprecated Use is_dependent instead */
  primary_covered_life_id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  total_visits_covered?: Maybe<Scalars['Int']>;
};

/** A geographical location */
export type FindHelpLocation = {
  __typename?: 'FindHelpLocation';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** The recommended action for a seeker or helper to contact a program and receive services or more information */
export type FindHelpNextStep = {
  __typename?: 'FindHelpNextStep';
  /** The purpose with which to use this next step */
  action?: Maybe<Scalars['String']>;
  /** The channel that a person should use to act upon the next step */
  channel?: Maybe<Scalars['String']>;
  /**
   * When combined with a specific channel, provides a url, email address, or other
   * actionable information to enact on the next step
   */
  contact?: Maybe<Scalars['String']>;
};

/** An office returned from the FindHelp API */
export type FindHelpOffice = {
  __typename?: 'FindHelpOffice';
  /** The first line of the office's physical address */
  address1?: Maybe<Scalars['String']>;
  /** The second line of the office's physical address */
  address2?: Maybe<Scalars['String']>;
  /** The third line of the office's physical address */
  address3?: Maybe<Scalars['String']>;
  /** The city portion of the office's physical address */
  city?: Maybe<Scalars['String']>;
  /**
   * The decimal distance of the location, in miles, from the center of the ZIP
   * code being searched. This is returned for the program and for each office
   */
  distance?: Maybe<Scalars['Float']>;
  /** The email address for the office */
  email?: Maybe<Scalars['String']>;
  /** The fax number for the office */
  fax_number?: Maybe<Scalars['String']>;
  /** An array of the attributes necessary to calculate hours of operation */
  hours?: Maybe<FindHelpOfficeHour>;
  /** Is an administrative office (as opposed to a Service office where individuals may get help) */
  is_administrative?: Maybe<Scalars['Boolean']>;
  /** A geographical location */
  location?: Maybe<FindHelpLocation>;
  /** The name of the office */
  name?: Maybe<Scalars['String']>;
  /** Text notes about the office’s operations */
  notes?: Maybe<Scalars['String']>;
  /** Unique identifier for this office */
  office_numeric_id?: Maybe<Scalars['ID']>;
  /** A list of types of activities done at this office */
  office_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Information indicating whether or not this office is currently open, and its hours of operation */
  open_now_info?: Maybe<FindHelpOpenNowInfo>;
  /** The main contact number for the office */
  phone_number?: Maybe<Scalars['String']>;
  /** The zip code portion of the office's physical address */
  postal?: Maybe<Scalars['String']>;
  /** The state portion of the office's physical address */
  state?: Maybe<Scalars['String']>;
  /** A list of languages spoken at this office */
  supported_languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Abbrevation of the timezone of the office */
  timezone_abbreviation?: Maybe<Scalars['String']>;
  /** A string value that uniquely identifies this office */
  url_safe_key?: Maybe<Scalars['String']>;
  /** The url for this specific office */
  website_url?: Maybe<Scalars['String']>;
};

/** The hours of operations for an office */
export type FindHelpOfficeHour = {
  __typename?: 'FindHelpOfficeHour';
  /** A boolean value to determine if the office is open on Friday */
  friday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Friday */
  friday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Friday (ex: 05:00PM) */
  friday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Friday (ex: 07:00AM) */
  friday_start?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Monday */
  monday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Monday */
  monday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Monday (ex: 05:00PM) */
  monday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Monday (ex: 07:00AM) */
  monday_start?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Saturday */
  saturday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Saturday */
  saturday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Saturday (ex: 05:00PM) */
  saturday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Saturday (ex: 07:00AM) */
  saturday_start?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Sunday */
  sunday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Sunday */
  sunday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Sunday (ex: 05:00PM) */
  sunday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Sunday (ex: 07:00AM) */
  sunday_start?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Thursday */
  thursday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Thursday */
  thursday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Thursday (ex: 05:00PM) */
  thursday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Thursday (ex: 07:00AM) */
  thursday_start?: Maybe<Scalars['String']>;
  /** An integer offset from GMT timezone (e.g.5 for EST) for this office */
  timezone?: Maybe<Scalars['Int']>;
  /** A string representing the name of the timezone (e.g. America/New_York) for this office */
  timezone_name?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Tuesday */
  tuesday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Tuesday */
  tuesday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Tuesday (ex: 05:00PM) */
  tuesday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Tuesday (ex: 07:00AM) */
  tuesday_start?: Maybe<Scalars['String']>;
  /** A boolean value to determine if the office is open on Wednesday */
  wednesday?: Maybe<Scalars['Boolean']>;
  /** A boolean value to determine if the office is open all day on Wednesday */
  wednesday_all_day?: Maybe<Scalars['Boolean']>;
  /** The closing time if the office is open on Wednesday (ex: 05:00PM) */
  wednesday_finish?: Maybe<Scalars['String']>;
  /** The start time if the office is open on Wednesday (ex: 07:00AM) */
  wednesday_start?: Maybe<Scalars['String']>;
};

/** Information indicating whether or not this office is currently open, and its hours of operation */
export type FindHelpOpenNowInfo = {
  __typename?: 'FindHelpOpenNowInfo';
  /** The local time the office closes today */
  close_time?: Maybe<Scalars['String']>;
  /** Current day */
  day_of_the_week?: Maybe<Scalars['String']>;
  /** Whether the office is open 24 hours */
  open_all_day?: Maybe<Scalars['Boolean']>;
  /** Whether the office is currently open */
  open_now?: Maybe<Scalars['Boolean']>;
  /** The local time the office opens today */
  open_time?: Maybe<Scalars['String']>;
};

/** One of possible locations supporting or offering a program from the FindHelp API */
export type FindHelpProgram = {
  __typename?: 'FindHelpProgram';
  /** Whether the program receives referrals made through the FindHelp API */
  accepts_referrals?: Maybe<Scalars['Boolean']>;
  /** All attribute tags that have been associated with this program */
  attribute_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The level of available services */
  availability?: Maybe<Scalars['String']>;
  /** Human-readable description of where this program is available */
  coverage_description?: Maybe<Scalars['String']>;
  /** An HTML formatted description of the program */
  description?: Maybe<Scalars['String']>;
  /** A String formatted description of the program */
  description_string?: Maybe<Scalars['String']>;
  /** Application directions for someone seeking assistance */
  directions?: Maybe<Scalars['String']>;
  /**
   * The decimal distance of the location, in miles, from the center of the ZIP
   * code being searched. This is returned for the program and for each office
   */
  distance?: Maybe<Scalars['String']>;
  /** Date this program was entered */
  entry_date?: Maybe<Scalars['String']>;
  /** Facebook URL for Program */
  facebook_url?: Maybe<Scalars['String']>;
  /** Text value describing the cost to the seeker for this program */
  free_or_reduced?: Maybe<Scalars['String']>;
  /** Google URL for Program */
  google_plus_id?: Maybe<Scalars['String']>;
  /** The coverage area type. Used to calculate the area served by the program */
  grain?: Maybe<Scalars['String']>;
  /** The coverage location */
  grain_location?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID of the program */
  id?: Maybe<Scalars['ID']>;
  /** Flag noting whether this office is currently available */
  is_office_available?: Maybe<Scalars['Boolean']>;
  /** The unique key of the program. Used to generate referrals using Referrals Create API */
  key?: Maybe<Scalars['String']>;
  /** The name of the program */
  name?: Maybe<Scalars['String']>;
  /**
   * A list of the steps necessary for a seeker to obtain services. This combines
   * the channel, action, and contact information associated with the channel
   */
  next_steps?: Maybe<Array<Maybe<FindHelpNextStep>>>;
  /**
   * A list of offices supporting the program; all offices in the listed zip code
   * are included, if there are more outside the area then they are listed by
   * geographical proximity up to a total count of ten
   */
  offices?: Maybe<Array<Maybe<FindHelpOffice>>>;
  /** Numeric identifier for this program */
  program_numeric_id?: Maybe<Scalars['String']>;
  /** The name of the organization providing the program */
  provider_name?: Maybe<Scalars['String']>;
  /** Numeric identifier for the provider of this program */
  provider_numeric_id?: Maybe<Scalars['String']>;
  /** Discrete attributes specifying which seekers are eligible for this program, by age */
  rule_attributes?: Maybe<Array<Maybe<FindHelpRuleAttribute>>>;
  /** Free text specifying which seekers are eligible for this program */
  rules?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Algorithmically determined rank order score for a program */
  score?: Maybe<Scalars['Int']>;
  /** Algorithmically determined rank order score for a program */
  screener_required?: Maybe<Scalars['Boolean']>;
  /** All service tags that have been associated with this program */
  service_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Languages supported by the program */
  supported_languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Twitter handle of program */
  twitter_id?: Maybe<Scalars['String']>;
  /**
   * Date this program was last updated. The program may be updated by the provider
   * directly or by the Findhelp Data Operations team
   */
  update_date?: Maybe<Scalars['String']>;
  /** @deprecated Find Help docs list updated_date, but data actually returned in update_date */
  updated_date?: Maybe<Scalars['String']>;
  /** Date this program was last validated */
  validation_date?: Maybe<Scalars['String']>;
  /** Video URL of program */
  video_url?: Maybe<Scalars['String']>;
  /** The program's website, if available; may be the provider's website */
  website_url?: Maybe<Scalars['String']>;
  /**
   * Featured Program score associated. Returns 0 if program is not a Featured
   * Program, and returns the Featured Program associated score if the program is Featured
   */
  wl_score?: Maybe<Scalars['String']>;
};

/** Discrete eligibility criterion for a program's admission */
export type FindHelpRuleAttribute = {
  __typename?: 'FindHelpRuleAttribute';
  /** The purpose with which to use this next step */
  rule_comparison?: Maybe<Scalars['String']>;
  /** The maximum of a ranged value for 'between' comparison. For other comparisons, will be equal to the min age */
  rule_max_age?: Maybe<Scalars['String']>;
  /** The minimum of a ranged value for 'between' comparison. For other comparisons, will be equal to the max age */
  rule_min_age?: Maybe<Scalars['String']>;
  /** age, age at diagnosis */
  rule_subtype?: Maybe<Scalars['String']>;
  /** The type of the rule. Today, only 'Age' rules are indicated by API */
  rule_type?: Maybe<Scalars['String']>;
};

/**
 * Responses can be either: relevance or distance. By default, programs will be
 * sorted by relevance. If the value is “distance”, the programs returned will be
 * sorted by distance regardless of their relevance or white label scores (wl_score).
 */
export enum FindHelpSortByEnum {
  /** sorted by distance */
  Distance = 'DISTANCE',
  /** sorted by relevance */
  Relevance = 'RELEVANCE',
}

export type FinishAssessmentInput = {
  campaign?: InputMaybe<CampaignInput>;
  id: Scalars['ID'];
};

/** Return from a FinishAssessment mutation */
export type FinishAssessmentOutput = {
  __typename?: 'FinishAssessmentOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

/** Result of a search for the first upcoming appointments for a user */
export type FirstUpcomingAppointmentOutputType = {
  __typename?: 'FirstUpcomingAppointmentOutputType';
  care_navigation?: Maybe<Appointment>;
  coaching?: Maybe<Appointment>;
  medication_management?: Maybe<Appointment>;
  peer_recovery?: Maybe<Appointment>;
  /** @deprecated Use peer_recovery instead */
  peer_recovery_specialist?: Maybe<Appointment>;
  therapy?: Maybe<Appointment>;
};

/** A single data point based on a float */
export type FloatDataPoint = {
  __typename?: 'FloatDataPoint';
  label: Scalars['String'];
  value: Scalars['Float'];
};

export type GatedBookingFlowType = {
  __typename?: 'GatedBookingFlowType';
  show_gated_booking?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GenericUpdateMemberOutput = {
  __typename?: 'GenericUpdateMemberOutput';
  member: Member;
  success?: Maybe<Scalars['Boolean']>;
};

/** A single health goal */
export type Goal = {
  __typename?: 'Goal';
  created_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  goal_type: Scalars['String'];
  id: Scalars['ID'];
  improvement_description?: Maybe<Scalars['String']>;
  neutral_description?: Maybe<Scalars['String']>;
  questionnaires: Array<Maybe<Scalars['String']>>;
  regression_description?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A google calendar input object */
export type GoogleCalendarInput = {
  id: Scalars['String'];
  readonly_access?: InputMaybe<Scalars['Boolean']>;
  summary: Scalars['String'];
};

/** A google calendar output object */
export type GoogleCalendarOutput = {
  __typename?: 'GoogleCalendarOutput';
  id?: Maybe<Scalars['String']>;
  readonly_access?: Maybe<Scalars['Boolean']>;
  summary?: Maybe<Scalars['String']>;
};

/** Help Info for members */
export type HelpInfo = {
  __typename?: 'HelpInfo';
  available_countries?: Maybe<Array<CountryInfo>>;
  /** @deprecated use region data instead */
  available_languages?: Maybe<Array<Scalars['String']>>;
  /** @deprecated use region data instead */
  chat_availability?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  crisis_support_availability?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  crisis_support_number?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  email?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  email_availability?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  general_support_availability?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  general_support_number?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  selected_country?: Maybe<Scalars['String']>;
  spring_internal_crisis_support_number?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  work_life_direct_dial?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  work_life_email?: Maybe<Scalars['String']>;
  /** @deprecated use region data instead */
  work_life_free_phone?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  context: Scalars['String'];
  url: Scalars['String'];
};

export type ImageInput = {
  image_context: Scalars['String'];
  url: Scalars['String'];
};

/** Eligibility of a member's insurance */
export type InsuranceEligibility = {
  __typename?: 'InsuranceEligibility';
  insurance_group_id: Scalars['ID'];
  plan_name?: Maybe<Scalars['String']>;
};

/** Eligibility Check of a member's insurance */
export type InsuranceEligibilityCheck = {
  __typename?: 'InsuranceEligibilityCheck';
  eligibility?: Maybe<InsuranceEligibility>;
  failure_reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type InsurancePayer = {
  __typename?: 'InsurancePayer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InsurancePayerSearchResult = {
  __typename?: 'InsurancePayerSearchResult';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A single data point based on an integer */
export type IntegerDataPoint = {
  __typename?: 'IntegerDataPoint';
  label: Scalars['String'];
  value: Scalars['Int'];
};

/** A set of data points based on integers */
export type IntegerDataSet = {
  __typename?: 'IntegerDataSet';
  data_points: Array<IntegerDataPoint>;
  total: Scalars['Int'];
};

/** A time series with integer values */
export type IntegerTimeSeries = {
  __typename?: 'IntegerTimeSeries';
  series: Array<TimeSeriesIntegerDataPoint>;
  total: Scalars['Int'];
};

/** The session type of an appointment type */
export enum InternalAppointmentSessionEnumType {
  FollowUp = 'FOLLOW_UP',
  Intake = 'INTAKE',
}

export type LicensesAppliedFiltersOutput = {
  __typename?: 'LicensesAppliedFiltersOutput';
  countries?: Maybe<Array<Scalars['String']>>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type LicensesPossibleFiltersOutput = {
  __typename?: 'LicensesPossibleFiltersOutput';
  countries: Array<Scalars['String']>;
  states: Array<Scalars['String']>;
};

/** An appointment with limited visibility for loginless actions */
export type LoginlessAppointment = {
  __typename?: 'LoginlessAppointment';
  derived_status: Scalars['String'];
  id: Scalars['ID'];
  provider: LoginlessCareProvider;
  start_at: Scalars['DateTime'];
  type?: Maybe<Scalars['String']>;
};

export type LoginlessCancelAppointmentInput = {
  /** A note to attach to the the cancellation email */
  cancel_note?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  memberId: Scalars['ID'];
  /** Skips sending notifications when true */
  no_email?: InputMaybe<Scalars['Boolean']>;
};

/** A care provider with limited visibility for loginless actions */
export type LoginlessCareProvider = {
  __typename?: 'LoginlessCareProvider';
  avatar_cdn_url: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name: Scalars['String'];
};

export type ManageUserSubscriptionInput = {
  entity_type?: InputMaybe<EntityEnumType>;
  group_id?: InputMaybe<Scalars['ID']>;
  group_type?: InputMaybe<Scalars['String']>;
  subscribed?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['ID']>;
};

/** Medication management benefit information for a member */
export type MedicationManagementBenefit = {
  __typename?: 'MedicationManagementBenefit';
  delete_me?: Maybe<Scalars['Boolean']>;
};

/** A member */
export type Member = {
  __typename?: 'Member';
  access_limit_date?: Maybe<Scalars['DateTime']>;
  active: Scalars['Boolean'];
  administration_notes?: Maybe<Scalars['String']>;
  aged_out_consent_fields?: Maybe<AgedOutRecord>;
  apero_record_id?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  assessments?: Maybe<Array<Maybe<Assessment>>>;
  attended_appointment_within_last_90_days?: Maybe<Scalars['Boolean']>;
  attended_appointment_within_last_180_days?: Maybe<Scalars['Boolean']>;
  beneficiaries?: Maybe<Array<Maybe<PrimaryGrantor>>>;
  care_advocate_supported?: Maybe<Scalars['Boolean']>;
  care_consultant_supported?: Maybe<Scalars['Boolean']>;
  care_items: CareItems;
  care_navigation_benefit?: Maybe<CareNavigationBenefit>;
  care_preference?: Maybe<CarePreference>;
  care_preference_questionnaire?: Maybe<Array<PreferenceQuestion>>;
  care_team?: Maybe<CareTeam>;
  ccof_policy_agreed_at?: Maybe<Scalars['DateTime']>;
  chart?: Maybe<Chart>;
  check_in_frequency_days?: Maybe<Scalars['Int']>;
  choose_user_cta_tag?: Maybe<Scalars['String']>;
  coaching_benefit?: Maybe<CoachingBenefit>;
  /** @deprecated Use coaching_benefit instead */
  coaching_tier?: Maybe<Scalars['String']>;
  cohort?: Maybe<Cohort>;
  community_care_supported?: Maybe<Scalars['Boolean']>;
  coppa_notice?: Maybe<Scalars['Boolean']>;
  country_code?: Maybe<Scalars['String']>;
  covered_life?: Maybe<CoveredLife>;
  created_at: Scalars['DateTime'];
  customer_site?: Maybe<CustomerSite>;
  customer_site_id?: Maybe<Scalars['ID']>;
  date_of_birth?: Maybe<Scalars['Date']>;
  deactivation_date?: Maybe<Scalars['DateTime']>;
  dependents_benefit?: Maybe<DependentsBenefit>;
  eap_email?: Maybe<Scalars['String']>;
  eap_link?: Maybe<Scalars['String']>;
  eap_phone?: Maybe<Scalars['String']>;
  electronic_comm_consent?: Maybe<Scalars['Boolean']>;
  eligible_for_insurance_validation?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  experience_state?: Maybe<ExperienceState>;
  first_name?: Maybe<Scalars['String']>;
  first_past_unrated_appointment?: Maybe<Appointment>;
  first_upcoming_appointments?: Maybe<FirstUpcomingAppointmentOutputType>;
  first_upcoming_appointments_by_provider: Array<Appointment>;
  full_address_available?: Maybe<Scalars['Boolean']>;
  get_specialty_program?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<Maybe<MemberGoal>>>;
  grantor?: Maybe<PrimaryGrantor>;
  has_access_to_cost_estimation?: Maybe<Scalars['Boolean']>;
  has_received_care?: Maybe<Scalars['Boolean']>;
  health_info_consent?: Maybe<Scalars['Boolean']>;
  hipaa_privacy_policy?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  individual_deactivation: Scalars['Boolean'];
  informed_consent?: Maybe<Scalars['Boolean']>;
  initial_sud_risk?: Maybe<Scalars['String']>;
  inside_inactivation_window: Scalars['Boolean'];
  insurance_payers?: Maybe<Array<Maybe<InsurancePayer>>>;
  insurance_policy?: Maybe<MemberInsurancePolicy>;
  is_a_teenager?: Maybe<Scalars['Boolean']>;
  is_eligible_for_peer_recovery_support?: Maybe<Scalars['Boolean']>;
  is_global_t2?: Maybe<Scalars['Boolean']>;
  is_past_cancellation_threshold: Scalars['Boolean'];
  is_peer_recovery_specialist_supported?: Maybe<Scalars['Boolean']>;
  is_private_practice: Scalars['Boolean'];
  is_provisional: Scalars['Boolean'];
  is_work_life_supported?: Maybe<Scalars['Boolean']>;
  kareo_patient_id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  last_notified_at?: Maybe<Scalars['DateTime']>;
  latest_sud_risk?: Maybe<Scalars['String']>;
  list_goal_types?: Maybe<Array<Scalars['String']>>;
  list_issues?: Maybe<Array<Scalars['String']>>;
  managed_dependents?: Maybe<Array<Maybe<Member>>>;
  manager?: Maybe<Member>;
  medication_management_benefit?: Maybe<MedicationManagementBenefit>;
  minor: Scalars['Boolean'];
  missed_late_cancel_policy_ack_required: Scalars['Boolean'];
  most_recent_assessment?: Maybe<Assessment>;
  most_recently_created_appointment_by_provider: Array<Appointment>;
  name?: Maybe<Scalars['String']>;
  no_show_late_cancel_acknowledged_at?: Maybe<Scalars['DateTime']>;
  offboarding_preferences?: Maybe<OffboardingPreferences>;
  opt_in_personal?: Maybe<Scalars['Boolean']>;
  optimism?: Maybe<Scalars['Int']>;
  patient_practice_location?: Maybe<PatientPracticeLocation>;
  payment_preference?: Maybe<MemberPaymentPreferenceEnumType>;
  phone?: Maybe<Scalars['String']>;
  /** represents available member preferences for therapist recommendations */
  possible_filters?: Maybe<RecommendedTherapistsPossibleFiltersOutputType>;
  postal_address?: Maybe<PostalAddress>;
  preferred_language?: Maybe<Scalars['String']>;
  preferred_name?: Maybe<Scalars['String']>;
  previsit?: Maybe<MemberPrevisit>;
  primary_covered_life?: Maybe<CoveredLife>;
  pronouns?: Maybe<Scalars['String']>;
  proposed_grantor_email?: Maybe<Scalars['String']>;
  push_opt_in?: Maybe<Scalars['Boolean']>;
  recently_transitioned_account?: Maybe<Scalars['String']>;
  recommended_therapists?: Maybe<Array<Maybe<RecommendedTherapistsResult>>>;
  risk?: Maybe<Scalars['String']>;
  scheduling_access: Scalars['Boolean'];
  sms_opt_in?: Maybe<Scalars['Boolean']>;
  spring_benefit?: Maybe<SpringBenefit>;
  sud_program?: Maybe<SudProgram>;
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
  terminate_at?: Maybe<Scalars['DateTime']>;
  terminate_reason?: Maybe<Scalars['String']>;
  therapy_benefit?: Maybe<TherapyBenefit>;
  /** @deprecated Use cohort scheduling access instead */
  tier_group: Scalars['Int'];
  time_zone?: Maybe<Scalars['String']>;
  todos?: Maybe<Array<Maybe<Todo>>>;
  total_completed_exercises?: Maybe<Scalars['Int']>;
  tracked_goals?: Maybe<Array<Maybe<MemberGoal>>>;
  unlimited_basic_visits: Scalars['Boolean'];
  unlimited_coaching_visits: Scalars['Boolean'];
  user: User;
  user_id?: Maybe<Scalars['String']>;
  valid_privacy_policy_agreement?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  visits_covered_basic: Scalars['Int'];
  visits_covered_coaching?: Maybe<Scalars['Int']>;
  visits_covered_specialist: Scalars['Int'];
  visits_covered_total?: Maybe<Scalars['Int']>;
  visits_eligibility_limit_date?: Maybe<Scalars['DateTime']>;
  visits_remaining_basic: Scalars['Int'];
  visits_remaining_coaching?: Maybe<Scalars['Int']>;
  visits_remaining_specialist: Scalars['Int'];
  visits_remaining_total?: Maybe<Scalars['Int']>;
  visits_used_basic: Scalars['Int'];
  visits_used_coaching: Scalars['Int'];
  visits_used_specialist: Scalars['Int'];
  visits_used_total: Scalars['Int'];
  within_aging_out?: Maybe<Scalars['String']>;
  workplace_consultation_supported?: Maybe<Scalars['Boolean']>;
  workplace_manager?: Maybe<Scalars['Boolean']>;
};

/** A member */
export type MemberAssessmentsArgs = {
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** A member */
export type MemberRecommended_TherapistsArgs = {
  current_path?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<RecommendedTherapistsFiltersInputType>;
  version?: InputMaybe<Scalars['String']>;
};

/** Return from a MemberCoachingTier query */
export type MemberCoachingTier = {
  __typename?: 'MemberCoachingTier';
  allow_direct_scheduling: Scalars['Boolean'];
  qualifies_for_coaching_via_CN: Scalars['Boolean'];
  show_coaching_paid_experience: Scalars['Boolean'];
  unlimited_coaching_cn: Scalars['Boolean'];
  unlimited_coaching_direct_scheduling: Scalars['Boolean'];
};

/** Member's Connected Benefit */
export type MemberConnectedBenefit = {
  __typename?: 'MemberConnectedBenefit';
  benefits_type: Scalars['String'];
  benefits_url: Scalars['String'];
  dependent_enabled: Scalars['Boolean'];
  description: Scalars['String'];
  id: Scalars['String'];
  logo_url: Scalars['String'];
  name: Scalars['String'];
  recommended: Scalars['Boolean'];
};

export type MemberConnectedBenefitOutputType = {
  __typename?: 'MemberConnectedBenefitOutputType';
  benefits: Array<MemberConnectedBenefit>;
};

/** Tracks a goal for a specific member */
export type MemberGoal = {
  __typename?: 'MemberGoal';
  created_at?: Maybe<Scalars['DateTime']>;
  ended_at?: Maybe<Scalars['DateTime']>;
  goal: Goal;
  id: Scalars['ID'];
  member: Member;
  started_at?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  tracked?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** Tracks progress on a goal for a specific member */
export type MemberGoalProgress = {
  __typename?: 'MemberGoalProgress';
  data?: Maybe<IntegerTimeSeries>;
  goal?: Maybe<Goal>;
  member_goal?: Maybe<MemberGoal>;
  trend?: Maybe<MemberGoalProgressTrend>;
};

/** Trend data for a member goal progress object */
export type MemberGoalProgressTrend = {
  __typename?: 'MemberGoalProgressTrend';
  description?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
  percentage_change?: Maybe<Scalars['Float']>;
};

/** Autogenerated input type of Member */
export type MemberInput = {
  /** A unique identifier for the client performing the mutation. */
  client_mutation_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  patch: UpdateMemberFieldsInput;
};

/** A member's insurance policy information */
export type MemberInsurancePolicy = {
  __typename?: 'MemberInsurancePolicy';
  carrier_name?: Maybe<Scalars['String']>;
  cost_estimate_requested_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  insurance_card_back_id?: Maybe<Scalars['ID']>;
  insurance_card_front_id?: Maybe<Scalars['ID']>;
  insurance_group_id?: Maybe<Scalars['String']>;
  insurance_member_id?: Maybe<Scalars['String']>;
  insurance_payer_id?: Maybe<Scalars['ID']>;
  member: Member;
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** The preferred payment method for a member */
export enum MemberPaymentPreferenceEnumType {
  /** Pay for the appointment via medical insurance */
  Insurance = 'INSURANCE',
  /** Pay for the appointment out-of-pocket */
  OutOfPocket = 'OUT_OF_POCKET',
}

/** Member Preference */
export type MemberPreference = {
  __typename?: 'MemberPreference';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** A member's previsit form */
export type MemberPrevisit = {
  __typename?: 'MemberPrevisit';
  can_schedule?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  member: Member;
  status: Scalars['String'];
};

/** Autogenerated input type of MemberPrivacyPolicyAgreedAt */
export type MemberPrivacyPolicyAgreedAtInput = {
  /** A unique identifier for the client performing the mutation. */
  client_mutation_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** A member */
export type MemberSearchResult = {
  __typename?: 'MemberSearchResult';
  active: Scalars['Boolean'];
  age?: Maybe<Scalars['Int']>;
  customer_name?: Maybe<Scalars['String']>;
  date_of_birth?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of MemberTermination */
export type MemberTerminationInput = {
  /** A unique identifier for the client performing the mutation. */
  client_mutation_id?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  immediate_termination?: InputMaybe<Scalars['Boolean']>;
};

export type MergeMembers = {
  __typename?: 'MergeMembers';
  destination_member_id: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  source_member_id: Scalars['ID'];
  success?: Maybe<Scalars['Boolean']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acknowledgeLateCancelMissedPolicy?: Maybe<AcknowledgeLateCancelMissedPolicyPayload>;
  /** Syncs appointment and its corresponding member and care provider with apero */
  appointmentAperoSync?: Maybe<AperoSyncOutput>;
  cancelAppointment?: Maybe<CancelAppointmentOutput>;
  cancelAssessment?: Maybe<CancelAssessmentOutput>;
  /** Syncs care provider with apero */
  careProviderAperoSync?: Maybe<AperoSyncOutput>;
  /** Complete a step in an exercise */
  completeStep?: Maybe<CompleteStepOutput>;
  contractTermClone?: Maybe<ClonePlanYearsOutputType>;
  createAdmin?: Maybe<CreateAdminOutput>;
  createAperoCustomer?: Maybe<CreateAperoCustomerOutput>;
  createAperoInvoice?: Maybe<CreateAperoInvoiceOutput>;
  createAppointment?: Maybe<CreateAppointmentOutput>;
  createCareProvider?: Maybe<CreateCareProviderOutput>;
  createCareProviderAvailabilityEvent?: Maybe<CreateCareProviderAvailabilityEventOutput>;
  createChannelPartner?: Maybe<CreateChannelPartnerOutput>;
  createCheckIn?: Maybe<CreateEvaluationOutput>;
  createCohort?: Maybe<CreateCohortOutput>;
  createCohortEmailCustomization?: Maybe<CreateCohortEmailCustomizationOutput>;
  createCohortMoveRequest?: Maybe<CreateCohortMoveRequestOutput>;
  /** Creates a cohort platform service */
  createCohortPlatformService?: Maybe<CreateCohortPlatformServiceOutput>;
  createCompassUser?: Maybe<CreateCompassUserOutput>;
  createConditionsScreen?: Maybe<CreateEvaluationOutput>;
  /** Creates a new connected_benefit */
  createConnectedBenefit: CreateConnectedBenefitOutput;
  /**
   * Create a new contract term - Deprecated
   * @deprecated No longer used
   */
  createContractTerm?: Maybe<CreateContractTermOutput>;
  createCoveredLife?: Maybe<CreateCoveredLifeOutput>;
  createCoveredLifeWithoutCensus?: Maybe<CreateCoveredLifeOutput>;
  createCoveredLifeWithoutCensusAndCohort?: Maybe<CreateCoveredLifeOutput>;
  createCustomer?: Maybe<CreateCustomerOutput>;
  /** Creates a new customer connected_benefit */
  createCustomerConnectedBenefit: CreateCustomerConnectedBenefitOutput;
  /** Create a Customer Deactivation */
  createCustomerDeactivation?: Maybe<CustomerDeactivationOutput>;
  createDemoUserCoveredLife?: Maybe<DemoUserCoveredLifeOutput>;
  createEHR?: Maybe<CreateEhrOutput>;
  /** Creates a new exercise */
  createExercise: CreateExerciseOutput;
  createExternalCalendarSync?: Maybe<CreateExternalCalendarSyncOutput>;
  createGoogleToken?: Maybe<CreateGoogleTokenOutput>;
  createInitialEvaluation?: Maybe<CreateEvaluationOutput>;
  /** charge a member payment method in Apero. */
  createMemberPayment?: Maybe<PaymentChargeType>;
  /** Create a members payment method in Apero. */
  createMemberPaymentMethod?: Maybe<PaymentMethodInfoType>;
  /** Create member preferences */
  createMemberPreference: CreateMemberPreferenceOutputType;
  createPersonalizedProviderBioFeedback?: Maybe<CreatePersonalizedProviderBioFeedbackOutput>;
  createPlanYear?: Maybe<CreateContractTermOutput>;
  /** Creates a platform service */
  createPlatformService?: Maybe<CreatePlatformServiceOutput>;
  createPotentialCoveredLife?: Maybe<CreatePotentialCoveredLifeOutput>;
  createPrivatePracticeInvitedMember?: Maybe<PrivatePracticeInvitedMember>;
  createProgressUpdate?: Maybe<CreateEvaluationOutput>;
  /** Create zendesk ticket for inquiries about provider availability */
  createProviderRequestAvailabilityZendeskTicket?: Maybe<CreateProviderRequestAvailabilityZendeskTicketType>;
  /** Create a Referral */
  createReferral?: Maybe<CreateReferralOutput>;
  createReportingIntegration?: Maybe<CreateReportingIntegrationOutput>;
  createReportingIntegrationPartner?: Maybe<CreateReportingIntegrationPartnerOutput>;
  /** Enqueues zendesk ticket creation job for SUD */
  createSUDZendeskTicket?: Maybe<CreateSudZendeskTicket>;
  createSetting?: Maybe<CreateSettingOutput>;
  /** Creates a new step in an exercise */
  createStep: UpdateStepOutput;
  /** Deploy survey answers to s3 */
  createSurvey?: Maybe<TherapeuticAllianceSurveyPayload>;
  createTeenCheckIn?: Maybe<CreateEvaluationOutput>;
  createTrial?: Maybe<CreateTrialOutput>;
  /** Get document details for the uploaded document to EHR */
  create_document_upload_presigned_status?: Maybe<CreateDocumentUploadPresignedStatusOutput>;
  /** Get document presigned url for the document to be uploaded to EHR */
  create_document_upload_presigned_url?: Maybe<CreateDocumentUploadPresignedUrlOutput>;
  /** Deletes a cohort platform service */
  deleteCohortPlatformService?: Maybe<DeleteCohortPlatformServiceOutput>;
  /** Delete a connected benefit */
  deleteConnectedBenefit: DeleteConnectedBenefitOutput;
  /** Delete a contract term */
  deleteContractTerm?: Maybe<DeleteContractTermOutput>;
  /** Delete a customer connected benefit */
  deleteCustomerConnectedBenefit: DeleteConnectedBenefitOutput;
  deleteExternalCalendarSync?: Maybe<DeleteExternalCalendarSyncOutput>;
  /** Delete plan rates for a contract term */
  deletePlanRate?: Maybe<DeleteContractTermOutput>;
  destroyCareProviderAvailabilityEvent?: Maybe<DestroyCareProviderAvailabilityEventOutput>;
  destroySetting?: Maybe<DestroySettingOutput>;
  destroyTrial?: Maybe<DestroyTrialOutput>;
  destroyUser?: Maybe<DestroyUserOutput>;
  finishAssessment?: Maybe<FinishAssessmentOutput>;
  generateAssessment?: Maybe<CreateEvaluationOutput>;
  generateTeenAssessment?: Maybe<CreateEvaluationOutput>;
  loginlessCancelAppointment?: Maybe<CancelAppointmentOutput>;
  loginlessConfirmAppointment?: Maybe<ConfirmAppointmentOutput>;
  loginlessUpdateUnattendanceReason?: Maybe<UnattendanceReasonOutput>;
  manageUserSubscription?: Maybe<UserSubscriptionOutput>;
  /** Syncs member with apero */
  memberAperoSync?: Maybe<AperoSyncOutput>;
  /**
   * Merge source member into the destination member. This is used when a user
   * has a member with Company A and then works for Company B which also has
   * springhealth.
   */
  mergeMembers?: Maybe<MergeMembers>;
  /** Transition an appointment to the next billing status */
  nextAppointmentBillingStatus?: Maybe<UpdateAppointmentBillingStatusOutput>;
  /** Transition an appointment to the previous billing status */
  previousAppointmentBillingStatus?: Maybe<UpdateAppointmentBillingStatusOutput>;
  processCustomerDeactivation?: Maybe<ProcessCustomerDeactivationOutput>;
  /** Creates a new ticket or updates sud ticket referrer */
  referToSudProgram?: Maybe<SudProgram>;
  requestAppointment?: Maybe<RequestAppointmentOutput>;
  /** @deprecated Not compatible with new assessment data format. */
  requestAssessment?: Maybe<RequestAssessmentOutput>;
  /** Contact the care navigation team for assistance based on the provided reason */
  requestCareNavigatorAssistance?: Maybe<CareNavigatorRequestAssistanceOutput>;
  requestCareTeamChange?: Maybe<RequestCareTeamChangeOutput>;
  /** Enqueues country change zendesk ticket creation job */
  requestCountryChange?: Maybe<RequestCountryChange>;
  rescheduleAppointment?: Maybe<RescheduleAppointmentOutput>;
  /** Resend a signup invitation email */
  resendSignupInvitation?: Maybe<ResendSignupInvitationOutput>;
  resendUserConfirmation?: Maybe<ResendUserConfirmationOutput>;
  resetDemoUserCoveredLife?: Maybe<DemoUserCoveredLifeOutput>;
  /** Send an appointment confirmation via email */
  sendAppointmentConfirmationEmail?: Maybe<SendAppointmentConfirmationEmailOutputType>;
  /** Send Application Download links via email */
  sendDownloadAppEmail: SendDownloadAppEmailOutputType;
  /**
   * Reset the reminder state of all unclaimed covered lives for a given customer
   *                so invitation reminders are sent by the scheduled RemindInviteScannerJob.
   */
  sendSignupInvitations?: Maybe<SendSignupInvitationsOutput>;
  setDefaultCohort?: Maybe<UpdateCohortOutput>;
  /** Creates an employer user */
  signupEmployer?: Maybe<SignupEmployerOutput>;
  signupMember?: Maybe<SignupMemberOutput>;
  /** Skip a step in the care plan */
  skipCarePlanProgressStep?: Maybe<SkipCarePlanProgressStepOutput>;
  startAssessment?: Maybe<StartAssessmentOutput>;
  /** Submit responses to questions in an assessment */
  submitAssessmentResponses: SubmitResponsesOutput;
  /**
   * Transfer the details of the source member to the target member to consolidate duplicate accounts (deprecated)
   * @deprecated No longer used.
   */
  transferMembership?: Maybe<TransferMembershipOutput>;
  /** updates aged_out_record record for a given member. */
  updateAgedOutRecord?: Maybe<UpdateOutput>;
  updateAppointment?: Maybe<UpdateAppointmentOutput>;
  updateAppointmentSentimentRating?: Maybe<AppointmentSentimentRatingPayload>;
  updateAssessment?: Maybe<UpdateAssessmentOutput>;
  updateCarePreference?: Maybe<UpdateMemberCarePreferenceOutput>;
  updateCareProvider?: Maybe<UpdateCareProviderOutput>;
  updateCareProviderAvailabilityEvent?: Maybe<UpdateCareProviderAvailabilityEventOutput>;
  updateCareProviderRecurringAvailabilityBlocks?: Maybe<UpdateCareProviderRecurringAvailabilityBlocksOutput>;
  updateCareTeam?: Maybe<UpdateCareTeamOutput>;
  updateChannelPartner?: Maybe<UpdateChannelPartnerOutput>;
  /** Update information about a cohort */
  updateCohort?: Maybe<UpdateCohortOutput>;
  updateCohortEmailCustomization?: Maybe<UpdateCohortEmailCustomizationOutput>;
  /** Updates a cohort platform service */
  updateCohortPlatformService?: Maybe<UpdateCohortPlatformServiceOutput>;
  updateCompassUser?: Maybe<UpdateCompassUserOutput>;
  /** Update a connected_benefit */
  updateConnectedBenefit?: Maybe<UpdateConnectedBenefitOutput>;
  /**
   * Update information about a contract term - Deprecated
   * @deprecated No longer used
   */
  updateContractTerm?: Maybe<UpdateContractTermOutput>;
  updateCoveredLife?: Maybe<CoveredLifePayload>;
  updateCoveredLifePrimary?: Maybe<CoveredLifePrimaryPayload>;
  /** Move covered lives */
  updateCoveredLivesCohort?: Maybe<UpdateCoveredLifeCohortOutput>;
  updateCustomer?: Maybe<UpdateCustomerOutput>;
  /** Update a customer connected_benefit */
  updateCustomerConnectedBenefit: UpdateCustomerConnectedBenefitOutput;
  updateDemoUserCoveredLife?: Maybe<DemoUserCoveredLifeOutput>;
  /** Update an exercise */
  updateExercise: UpdateExerciseOutput;
  updateExternalCalendarSync?: Maybe<UpdateExternalCalendarSyncOutput>;
  updateMember?: Maybe<UpdateMemberOutput>;
  /**
   * Update the cohort for a member (deprecated)
   * @deprecated No longer used
   */
  updateMemberCohort?: Maybe<UpdateMemberCohortOutput>;
  /** Update the covered life record for a member */
  updateMemberCoveredLife?: Maybe<UpdateMemberOutput>;
  /** Update the experience state for a member */
  updateMemberExperienceState?: Maybe<GenericUpdateMemberOutput>;
  /** Update the goals for a member */
  updateMemberGoals?: Maybe<UpdateMemberGoalsOutput>;
  updateMemberInsurancePolicy?: Maybe<UpdateMemberInsurancePolicyOutputType>;
  /** Updates a members payment method in Apero. */
  updateMemberPaymentMethod?: Maybe<PaymentMethodInfoType>;
  /** Update member preferences */
  updateMemberPreference: CreateMemberPreferenceOutputType;
  updateMemberPrevisit?: Maybe<UpdateMemberPrevisitOutput>;
  updateMemberPrivacyPolicyAgreedAt?: Maybe<UpdateMemberOutput>;
  updateMemberTermination?: Maybe<UpdateMemberOutput>;
  /** Updates the workplace_manager attribute on a given member (true/false) */
  updateMemberWorkplaceManager: UpdateMemberOutput;
  /** Update the offboarding preferences for a member */
  updateOffboardingPreferences?: Maybe<GenericUpdateMemberOutput>;
  /** Update information about a contract term */
  updatePlanYear?: Maybe<UpdateContractTermOutput>;
  /** Update information about a platform service */
  updatePlatformService?: Maybe<UpdatePlatformServiceOutput>;
  updatePotentialCoveredLife?: Maybe<CreatePotentialCoveredLifeOutput>;
  updateQuestionnaire?: Maybe<UpdateQuestionnaireOutput>;
  /** Update a Referral */
  updateReferral?: Maybe<UpdateReferralOutput>;
  updateReportingIntegration?: Maybe<UpdateReportingIntegrationOutput>;
  updateSchedulingConstraints?: Maybe<SchedulingConstraintsOutput>;
  updateSchedulingSetting?: Maybe<SchedulingSettingOutput>;
  updateSetting?: Maybe<UpdateSettingOutput>;
  /** Updates an ExerciseStep */
  updateStep: UpdateStepOutput;
  updateTrial?: Maybe<UpdateTrialOutput>;
  updateUser?: Maybe<UpdateUserOutput>;
  /** Updates EHR db once document is succssfully uploaded */
  upload_document?: Maybe<UploadDocumentOutput>;
  /** Revokes / Enables access for a user */
  userToggleAccess?: Maybe<ToggleAccessOutput>;
};

export type MutationAcknowledgeLateCancelMissedPolicyArgs = {
  member_id: Scalars['ID'];
};

export type MutationAppointmentAperoSyncArgs = {
  id: Scalars['ID'];
};

export type MutationCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};

export type MutationCancelAssessmentArgs = {
  input: CancelAssessmentInput;
};

export type MutationCareProviderAperoSyncArgs = {
  id: Scalars['ID'];
};

export type MutationCompleteStepArgs = {
  current_step_id: Scalars['ID'];
  exercise_id: Scalars['ID'];
};

export type MutationContractTermCloneArgs = {
  input: ContractTermCloneInputType;
};

export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};

export type MutationCreateAperoCustomerArgs = {
  input: CreateAperoCustomerFieldsInput;
};

export type MutationCreateAperoInvoiceArgs = {
  input: CreateAperoInvoiceFieldsInput;
};

export type MutationCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationCreateCareProviderArgs = {
  input: CreateCareProviderFieldsInput;
};

export type MutationCreateCareProviderAvailabilityEventArgs = {
  input: CareProviderAvailabilityEventCreateFieldsInputType;
};

export type MutationCreateChannelPartnerArgs = {
  input: CreateChannelPartnerInput;
};

export type MutationCreateCheckInArgs = {
  campaign?: InputMaybe<CampaignInput>;
  member_id: Scalars['ID'];
  start?: InputMaybe<Scalars['Boolean']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateCohortArgs = {
  input: CreateCohortFieldsInput;
};

export type MutationCreateCohortEmailCustomizationArgs = {
  input: CreateCohortEmailCustomizationInput;
};

export type MutationCreateCohortMoveRequestArgs = {
  input: CreateCohortMoveRequestInput;
};

export type MutationCreateCohortPlatformServiceArgs = {
  input: CreateCohortPlatformServiceInput;
};

export type MutationCreateCompassUserArgs = {
  input: CreateCompassUserFieldsInput;
};

export type MutationCreateConditionsScreenArgs = {
  campaign?: InputMaybe<CampaignInput>;
  member_id: Scalars['ID'];
  start?: InputMaybe<Scalars['Boolean']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateConnectedBenefitArgs = {
  input: CreateConnectedBenefitInput;
};

export type MutationCreateContractTermArgs = {
  input: CreateContractTermFieldsInput;
};

export type MutationCreateCoveredLifeArgs = {
  input: CoveredLifeInput;
};

export type MutationCreateCoveredLifeWithoutCensusArgs = {
  input: CoveredLifeWithoutCensusInputType;
};

export type MutationCreateCoveredLifeWithoutCensusAndCohortArgs = {
  input: CoveredLifeWithoutCensusAndCohortInputType;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerConnectedBenefitArgs = {
  input: CreateCustomerConnectedBenefitInput;
};

export type MutationCreateCustomerDeactivationArgs = {
  input: CreateCustomerDeactivationInputType;
};

export type MutationCreateDemoUserCoveredLifeArgs = {
  input: CreateDemoUserCoveredLifeInput;
};

export type MutationCreateEhrArgs = {
  member_id: Scalars['ID'];
};

export type MutationCreateExerciseArgs = {
  input: CreateExerciseInput;
};

export type MutationCreateExternalCalendarSyncArgs = {
  input: CreateExternalCalendarSyncInput;
};

export type MutationCreateGoogleTokenArgs = {
  input: CreateGoogleTokenInput;
};

export type MutationCreateInitialEvaluationArgs = {
  campaign?: InputMaybe<CampaignInput>;
  member_id: Scalars['ID'];
  start?: InputMaybe<Scalars['Boolean']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateMemberPaymentArgs = {
  input: PaymentInputType;
};

export type MutationCreateMemberPaymentMethodArgs = {
  auto_pay_enabled?: InputMaybe<Scalars['Boolean']>;
  member_id: Scalars['ID'];
  token_id: Scalars['String'];
};

export type MutationCreateMemberPreferenceArgs = {
  member_id: Scalars['ID'];
  preferences: Array<PreferenceEnumType>;
};

export type MutationCreatePersonalizedProviderBioFeedbackArgs = {
  input: CreatePersonalizedProviderBioFeedbackInput;
};

export type MutationCreatePlanYearArgs = {
  input: CreatePlanYearFieldsInput;
};

export type MutationCreatePlatformServiceArgs = {
  input: CreatePlatformServiceInput;
};

export type MutationCreatePotentialCoveredLifeArgs = {
  input: PotentialCoveredLifeInput;
  send_notification?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreatePrivatePracticeInvitedMemberArgs = {
  input: PrivatePracticeInvitedMemberInput;
};

export type MutationCreateProgressUpdateArgs = {
  campaign?: InputMaybe<CampaignInput>;
  member_id: Scalars['ID'];
  questionnaires_order: Array<InputMaybe<QuestionnaireName>>;
  start?: InputMaybe<Scalars['Boolean']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateProviderRequestAvailabilityZendeskTicketArgs = {
  input: ProviderRequestAvailabilityZendeskTicketInput;
};

export type MutationCreateReferralArgs = {
  input: CreateReferralInput;
};

export type MutationCreateReportingIntegrationArgs = {
  input: CreateReportingIntegrationInput;
};

export type MutationCreateReportingIntegrationPartnerArgs = {
  input: CreateReportingIntegrationPartnerInput;
};

export type MutationCreateSudZendeskTicketArgs = {
  care_navigator_id?: InputMaybe<Scalars['ID']>;
  care_navigator_name?: InputMaybe<Scalars['String']>;
  schedule_attempt_type?: InputMaybe<Scalars['String']>;
  sud_signal_flag?: InputMaybe<Scalars['String']>;
};

export type MutationCreateSettingArgs = {
  input: CreateSettingInput;
};

export type MutationCreateStepArgs = {
  input: CreateStepInput;
};

export type MutationCreateSurveyArgs = {
  survey: SurveyInputType;
};

export type MutationCreateTeenCheckInArgs = {
  campaign?: InputMaybe<CampaignInput>;
  member_id: Scalars['ID'];
  start?: InputMaybe<Scalars['Boolean']>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateTrialArgs = {
  input: CreateTrialInput;
};

export type MutationCreate_Document_Upload_Presigned_StatusArgs = {
  input: CreateDocumentPresignedStatusInputType;
};

export type MutationCreate_Document_Upload_Presigned_UrlArgs = {
  input: CreateDocumentPresignedUrlInputType;
};

export type MutationDeleteCohortPlatformServiceArgs = {
  input: DeleteCohortPlatformServiceInput;
};

export type MutationDeleteConnectedBenefitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteContractTermArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerConnectedBenefitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteExternalCalendarSyncArgs = {
  care_provider_id: Scalars['ID'];
};

export type MutationDeletePlanRateArgs = {
  contract_term_id: Scalars['ID'];
};

export type MutationDestroyCareProviderAvailabilityEventArgs = {
  input: DestroyCareProviderAvailabilityEventInput;
};

export type MutationDestroySettingArgs = {
  input: DestroySettingInput;
};

export type MutationDestroyTrialArgs = {
  input: DestroyTrialInput;
};

export type MutationDestroyUserArgs = {
  input: DestroyUserInput;
};

export type MutationFinishAssessmentArgs = {
  input: FinishAssessmentInput;
};

export type MutationGenerateAssessmentArgs = {
  kind: AssessmentKindEnumType;
  member_id: Scalars['ID'];
  questionnaires_order?: InputMaybe<Array<InputMaybe<QuestionnaireName>>>;
  version_number?: InputMaybe<Scalars['Int']>;
};

export type MutationGenerateTeenAssessmentArgs = {
  campaign?: InputMaybe<CampaignInput>;
  kind?: InputMaybe<AssessmentKindEnumType>;
  member_id: Scalars['ID'];
  questionnaires_order?: InputMaybe<Array<InputMaybe<QuestionnaireName>>>;
};

export type MutationLoginlessCancelAppointmentArgs = {
  input: LoginlessCancelAppointmentInput;
};

export type MutationLoginlessConfirmAppointmentArgs = {
  input: ConfirmAppointmentInput;
};

export type MutationLoginlessUpdateUnattendanceReasonArgs = {
  input: UnattendanceReasonInput;
};

export type MutationManageUserSubscriptionArgs = {
  input: ManageUserSubscriptionInput;
};

export type MutationMemberAperoSyncArgs = {
  id: Scalars['ID'];
};

export type MutationMergeMembersArgs = {
  destination_member_id: Scalars['ID'];
  source_member_id: Scalars['ID'];
};

export type MutationNextAppointmentBillingStatusArgs = {
  id: Scalars['ID'];
};

export type MutationPreviousAppointmentBillingStatusArgs = {
  id: Scalars['ID'];
};

export type MutationProcessCustomerDeactivationArgs = {
  input: ProcessCustomerDeactivationInput;
};

export type MutationReferToSudProgramArgs = {
  assignee_id?: InputMaybe<Scalars['ID']>;
  member_id: Scalars['ID'];
  referrer_note?: InputMaybe<Scalars['String']>;
};

export type MutationRequestAppointmentArgs = {
  input: RequestInput;
};

export type MutationRequestAssessmentArgs = {
  input: RequestAssessmentInput;
};

export type MutationRequestCareNavigatorAssistanceArgs = {
  reason: AssistanceReasonEnumType;
  user_reason?: InputMaybe<Scalars['String']>;
};

export type MutationRequestCareTeamChangeArgs = {
  input: RequestCareTeamChangeInput;
};

export type MutationRequestCountryChangeArgs = {
  selected_country: Scalars['String'];
};

export type MutationRescheduleAppointmentArgs = {
  input: RescheduleAppointmentInput;
};

export type MutationResendSignupInvitationArgs = {
  corporate_email?: InputMaybe<Scalars['String']>;
  personal_phone_number?: InputMaybe<Scalars['String']>;
};

export type MutationResendUserConfirmationArgs = {
  input: ResendUserConfirmationInput;
};

export type MutationResetDemoUserCoveredLifeArgs = {
  id: Scalars['ID'];
};

export type MutationSendAppointmentConfirmationEmailArgs = {
  appointment_id: Scalars['ID'];
};

export type MutationSendSignupInvitationsArgs = {
  customer_id: Scalars['ID'];
};

export type MutationSetDefaultCohortArgs = {
  input: SetDefaultCohortInput;
};

export type MutationSignupEmployerArgs = {
  input: SignupEmployerInput;
};

export type MutationSignupMemberArgs = {
  input: SignupMemberInput;
};

export type MutationSkipCarePlanProgressStepArgs = {
  care_plan_progress_step_id: Scalars['ID'];
};

export type MutationStartAssessmentArgs = {
  input: StartAssessmentInput;
};

export type MutationSubmitAssessmentResponsesArgs = {
  campaign?: InputMaybe<CampaignInput>;
  prompt_responses: Array<PromptResponseInputType>;
  questionnaire_id: Scalars['ID'];
  skipped?: InputMaybe<Scalars['Boolean']>;
};

export type MutationTransferMembershipArgs = {
  source_member_id?: InputMaybe<Scalars['ID']>;
  target_member_id?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateAgedOutRecordArgs = {
  member_id: Scalars['ID'];
};

export type MutationUpdateAppointmentArgs = {
  input: UpdateAppointmentInput;
};

export type MutationUpdateAppointmentSentimentRatingArgs = {
  appointment_id: Scalars['ID'];
  sentiment_rating: Scalars['Int'];
};

export type MutationUpdateAssessmentArgs = {
  input: UpdateAssessmentInput;
};

export type MutationUpdateCarePreferenceArgs = {
  input: UpdateMemberCarePreferenceInput;
  member_id: Scalars['ID'];
};

export type MutationUpdateCareProviderArgs = {
  input: UpdateCareProviderInput;
};

export type MutationUpdateCareProviderAvailabilityEventArgs = {
  input: UpdateCareProviderAvailabilityEventInput;
};

export type MutationUpdateCareProviderRecurringAvailabilityBlocksArgs = {
  input: UpdateCareProviderRecurringAvailabilityBlocksInput;
};

export type MutationUpdateCareTeamArgs = {
  input: UpdateCareTeamInput;
};

export type MutationUpdateChannelPartnerArgs = {
  input: UpdateChannelPartnerInput;
};

export type MutationUpdateCohortArgs = {
  id: Scalars['ID'];
  input: UpdateCohortFieldsInput;
};

export type MutationUpdateCohortEmailCustomizationArgs = {
  input: UpdateCohortEmailCustomizationInput;
};

export type MutationUpdateCohortPlatformServiceArgs = {
  input: UpdateCohortPlatformServiceInput;
};

export type MutationUpdateCompassUserArgs = {
  input: UpdateCompassUserInput;
};

export type MutationUpdateConnectedBenefitArgs = {
  input: UpdateConnectedBenefitInput;
};

export type MutationUpdateContractTermArgs = {
  id: Scalars['ID'];
  input: UpdateContractTermFieldsInput;
};

export type MutationUpdateCoveredLifeArgs = {
  attributes: CoveredLifeAttributes;
  id: Scalars['ID'];
};

export type MutationUpdateCoveredLifePrimaryArgs = {
  covered_life_id: Scalars['ID'];
  primary_covered_life_id: Scalars['ID'];
};

export type MutationUpdateCoveredLivesCohortArgs = {
  cohort_move_request_id: Scalars['ID'];
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerConnectedBenefitArgs = {
  input: UpdateCustomerConnectedBenefitInput;
};

export type MutationUpdateDemoUserCoveredLifeArgs = {
  input: UpdateDemoUserCoveredLifeInput;
};

export type MutationUpdateExerciseArgs = {
  exercise_id: Scalars['ID'];
  input: UpdateExerciseInput;
};

export type MutationUpdateExternalCalendarSyncArgs = {
  input: UpdateExternalCalendarSyncInput;
};

export type MutationUpdateMemberArgs = {
  input: MemberInput;
};

export type MutationUpdateMemberCohortArgs = {
  member_id?: InputMaybe<Scalars['ID']>;
  new_cohort_id?: InputMaybe<Scalars['ID']>;
};

export type MutationUpdateMemberCoveredLifeArgs = {
  member_id: Scalars['ID'];
  new_covered_life_id: Scalars['ID'];
};

export type MutationUpdateMemberExperienceStateArgs = {
  banner_moments_count?: InputMaybe<Scalars['Int']>;
  family_todo_count?: InputMaybe<Scalars['Int']>;
  member_id?: InputMaybe<Scalars['ID']>;
  mobile_banner_moments_count?: InputMaybe<Scalars['Int']>;
  modal_mobile_app_count?: InputMaybe<Scalars['Int']>;
  should_update_settings?: InputMaybe<Scalars['Boolean']>;
  update_settings_shown_count?: InputMaybe<Scalars['Int']>;
  visited_mobile_app_home_tab?: InputMaybe<Scalars['Boolean']>;
  visited_your_journey?: InputMaybe<Scalars['Boolean']>;
  workplace_banner_dismissed?: InputMaybe<Scalars['Boolean']>;
  workplace_page_viewed?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateMemberGoalsArgs = {
  input: UpdateMemberGoalsInput;
};

export type MutationUpdateMemberInsurancePolicyArgs = {
  carrier_name?: InputMaybe<Scalars['String']>;
  insurance_card_back_id?: InputMaybe<Scalars['ID']>;
  insurance_card_front_id?: InputMaybe<Scalars['ID']>;
  insurance_group_id?: InputMaybe<Scalars['String']>;
  insurance_member_id?: InputMaybe<Scalars['String']>;
  insurance_payer_id?: InputMaybe<Scalars['ID']>;
  insurance_type?: InputMaybe<Scalars['String']>;
  member_id: Scalars['ID'];
  plan_name?: InputMaybe<Scalars['String']>;
  trigger_cost_estimate_zendesk_ticket?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateMemberPaymentMethodArgs = {
  auto_pay_enabled?: InputMaybe<Scalars['Boolean']>;
  member_id: Scalars['ID'];
  payment_method?: InputMaybe<UpdatePaymentMethodType>;
};

export type MutationUpdateMemberPreferenceArgs = {
  member_id: Scalars['ID'];
  preferences: Array<PreferenceEnumType>;
};

export type MutationUpdateMemberPrevisitArgs = {
  input: UpdateMemberPrevisitInput;
};

export type MutationUpdateMemberPrivacyPolicyAgreedAtArgs = {
  input: MemberPrivacyPolicyAgreedAtInput;
};

export type MutationUpdateMemberTerminationArgs = {
  input: MemberTerminationInput;
};

export type MutationUpdateMemberWorkplaceManagerArgs = {
  member_id: Scalars['ID'];
  workplace_manager: Scalars['Boolean'];
};

export type MutationUpdateOffboardingPreferencesArgs = {
  member_id?: InputMaybe<Scalars['ID']>;
  offboarding_preferences: OffboardingPreferencesInput;
};

export type MutationUpdatePlanYearArgs = {
  id: Scalars['ID'];
  input: UpdatePlanYearFieldsInput;
};

export type MutationUpdatePlatformServiceArgs = {
  input: UpdatePlatformServiceInput;
};

export type MutationUpdatePotentialCoveredLifeArgs = {
  id: Scalars['ID'];
  input: PotentialCoveredLifeInput;
  send_notification?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};

export type MutationUpdateReferralArgs = {
  input: UpdateReferralInput;
};

export type MutationUpdateReportingIntegrationArgs = {
  input: UpdateReportingIntegrationInput;
};

export type MutationUpdateSchedulingConstraintsArgs = {
  input: SchedulingConstraintsInput;
};

export type MutationUpdateSchedulingSettingArgs = {
  input: SchedulingSettingInput;
};

export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};

export type MutationUpdateStepArgs = {
  input: UpdateStepInput;
  step_id: Scalars['ID'];
};

export type MutationUpdateTrialArgs = {
  input: UpdateTrialInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpload_DocumentArgs = {
  input: UploadDocumentInputType;
};

export type MutationUserToggleAccessArgs = {
  id: Scalars['ID'];
};

/** Care provider statuses */
export enum NetworkStatusEnumType {
  Approved = 'APPROVED',
  Disabled = 'DISABLED',
  GeneralHold = 'GENERAL_HOLD',
  NoNewPatients = 'NO_NEW_PATIENTS',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  QualityHold = 'QUALITY_HOLD',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

/** The next available appointment a provider has. */
export type NextAvailableAppointment = {
  __typename?: 'NextAvailableAppointment';
  appointment_slot?: Maybe<Scalars['DateTime']>;
  medium?: Maybe<AppointmentMediumEnum>;
};

/** No show term configuration attached to a given Contract Term */
export type NoShowTerm = {
  __typename?: 'NoShowTerm';
  are_comped_sessions_deducted: Scalars['Boolean'];
  comped_appointment_categories?: Maybe<Array<AppointmentCategory>>;
  comped_session_count: Scalars['Int'];
  enable_members_pay: Scalars['Boolean'];
  id: Scalars['ID'];
  start_date?: Maybe<Scalars['ISO8601Date']>;
};

export type NoShowTermFieldsInput = {
  are_comped_sessions_deducted?: InputMaybe<Scalars['Boolean']>;
  comped_appointment_category_names?: InputMaybe<Array<Scalars['String']>>;
  comped_session_count?: InputMaybe<Scalars['Int']>;
  enable_members_pay: Scalars['Boolean'];
  start_date?: InputMaybe<Scalars['ISO8601Date']>;
};

export type OffboardingPreferences = {
  __typename?: 'OffboardingPreferences';
  continue_with_care?: Maybe<Scalars['Boolean']>;
  continue_with_spring?: Maybe<Scalars['Boolean']>;
  find_provider_help?: Maybe<Scalars['Boolean']>;
  prefer_out_of_pocket_payments?: Maybe<Scalars['Boolean']>;
};

export type OffboardingPreferencesInput = {
  continue_with_care?: InputMaybe<Scalars['Boolean']>;
  continue_with_spring?: InputMaybe<Scalars['Boolean']>;
  find_provider_help?: InputMaybe<Scalars['Boolean']>;
  prefer_out_of_pocket_payments?: InputMaybe<Scalars['Boolean']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A paginated list of app tasks */
export type PaginatedAppTasks = {
  __typename?: 'PaginatedAppTasks';
  data: Array<AppTask>;
  paging: PagingData;
};

/** A paginated list of appointments */
export type PaginatedAppointments = {
  __typename?: 'PaginatedAppointments';
  data: Array<Maybe<Appointment>>;
  paging: PagingData;
};

/** A paginated list of assessments */
export type PaginatedAssessments = {
  __typename?: 'PaginatedAssessments';
  data: Array<Maybe<Assessment>>;
  paging: PagingData;
};

/** A paginated list of care provider tags */
export type PaginatedCareProviderTags = {
  __typename?: 'PaginatedCareProviderTags';
  data: Array<CareProviderTag>;
  paging: PagingData;
};

/** A paginated list of care providers */
export type PaginatedCareProviders = {
  __typename?: 'PaginatedCareProviders';
  data: Array<Maybe<CareProvider>>;
  paging: PagingData;
};

/** A paginated list of care providers */
export type PaginatedCareProvidersResults = {
  __typename?: 'PaginatedCareProvidersResults';
  data?: Maybe<Array<Maybe<BrowseCareProviderResult>>>;
  paging?: Maybe<PagingData>;
};

/** A paginated list of census errors */
export type PaginatedCensusErrors = {
  __typename?: 'PaginatedCensusErrors';
  data: Array<Maybe<CensusError>>;
  paging: PagingData;
};

/** A paginated list of census ingestions rows */
export type PaginatedCensusIngestionRows = {
  __typename?: 'PaginatedCensusIngestionRows';
  data: Array<Maybe<CensusIngestionRow>>;
  paging: PagingData;
};

/** A paginated list of census ingestions */
export type PaginatedCensusIngestions = {
  __typename?: 'PaginatedCensusIngestions';
  data: Array<Maybe<CensusIngestion>>;
  paging: PagingData;
};

/** A paginated list of channel partners */
export type PaginatedChannelPartner = {
  __typename?: 'PaginatedChannelPartner';
  data: Array<Maybe<ChannelPartner>>;
  paging: PagingData;
};

/** A paginated list of members and covered lives */
export type PaginatedClientsResults = {
  __typename?: 'PaginatedClientsResults';
  data?: Maybe<Array<Maybe<CompassSearchClient>>>;
  paging?: Maybe<PagingData>;
};

/** A paginated list of cohort_email_customizations */
export type PaginatedCohortEmailCustomizations = {
  __typename?: 'PaginatedCohortEmailCustomizations';
  data: Array<Maybe<CohortEmailCustomization>>;
  paging: PagingData;
};

/** A paginated list of cohort_platform_services */
export type PaginatedCohortPlatformServices = {
  __typename?: 'PaginatedCohortPlatformServices';
  data: Array<Maybe<CohortPlatformService>>;
  paging: PagingData;
};

/** A paginated list of connected benefits */
export type PaginatedConnectedBenefits = {
  __typename?: 'PaginatedConnectedBenefits';
  data?: Maybe<Array<Maybe<ConnectedBenefit>>>;
  paging: PagingData;
};

/** A paginated list of appointments */
export type PaginatedCoveredAppointments = {
  __typename?: 'PaginatedCoveredAppointments';
  data?: Maybe<Array<Maybe<CoveredAppointment>>>;
  paging: PagingData;
};

/** A paginated list of covered lives */
export type PaginatedCoveredLives = {
  __typename?: 'PaginatedCoveredLives';
  data: Array<Maybe<CoveredLife>>;
  paging: PagingData;
};

/** A paginated list of customers */
export type PaginatedCustomers = {
  __typename?: 'PaginatedCustomers';
  data: Array<Maybe<Customer>>;
  paging: PagingData;
};

/** A paginated list of demo user covered lives */
export type PaginatedDemoUserCoveredLives = {
  __typename?: 'PaginatedDemoUserCoveredLives';
  data: Array<Maybe<DemoUserCoveredLife>>;
  paging: PagingData;
};

/** A paginated list of exercises */
export type PaginatedExercises = {
  __typename?: 'PaginatedExercises';
  data: Array<Exercise>;
  paging: PagingData;
};

/** A paginated list of FindHelp Programs */
export type PaginatedFindHelpProgramsType = {
  __typename?: 'PaginatedFindHelpProgramsType';
  attribute_tag_counts?: Maybe<Array<Maybe<Scalars['FindHelpAttributeTagType']>>>;
  data?: Maybe<Array<Maybe<FindHelpProgram>>>;
  paging: PagingData;
};

/** A paginated list of goals */
export type PaginatedGoals = {
  __typename?: 'PaginatedGoals';
  data: Array<Maybe<Goal>>;
  paging: PagingData;
};

/** A paginated list of member goals */
export type PaginatedMemberGoals = {
  __typename?: 'PaginatedMemberGoals';
  data: Array<Maybe<MemberGoal>>;
  paging: PagingData;
};

/** A paginated list of members */
export type PaginatedMemberResults = {
  __typename?: 'PaginatedMemberResults';
  data?: Maybe<Array<Maybe<CompassMemberSearchResult>>>;
  paging?: Maybe<PagingData>;
};

/** A paginated list of members */
export type PaginatedMembers = {
  __typename?: 'PaginatedMembers';
  data: Array<Maybe<Member>>;
  paging: PagingData;
};

/** A paginated list of patient practices */
export type PaginatedPatientPractices = {
  __typename?: 'PaginatedPatientPractices';
  data: Array<Maybe<PatientPractice>>;
  paging: PagingData;
};

/** A paginated list of platform_services */
export type PaginatedPlatformServices = {
  __typename?: 'PaginatedPlatformServices';
  data: Array<Maybe<PlatformService>>;
  paging: PagingData;
};

/** A paginated list of questionnaires */
export type PaginatedQuestionnaires = {
  __typename?: 'PaginatedQuestionnaires';
  data: Array<Maybe<Questionnaire>>;
  paging: PagingData;
};

/** A paginated list of referrals */
export type PaginatedReferralsType = {
  __typename?: 'PaginatedReferralsType';
  data: Array<Maybe<Referral>>;
  paging: PagingData;
};

/** A paginated list of ReportingIntegrationPartners */
export type PaginatedReportingIntegrationPartners = {
  __typename?: 'PaginatedReportingIntegrationPartners';
  data: Array<Maybe<ReportingIntegrationPartner>>;
  paging: PagingData;
};

/** A paginated list of ReportingIntegrationRuns */
export type PaginatedReportingIntegrationRuns = {
  __typename?: 'PaginatedReportingIntegrationRuns';
  data: Array<Maybe<ReportingIntegrationRun>>;
  paging: PagingData;
};

/** A paginated list of ReportingIntegrations */
export type PaginatedReportingIntegrations = {
  __typename?: 'PaginatedReportingIntegrations';
  data: Array<Maybe<ReportingIntegration>>;
  paging: PagingData;
};

/** A paginated list of care providers */
export type PaginatedSearchCareProvidersResult = {
  __typename?: 'PaginatedSearchCareProvidersResult';
  applied_filters?: Maybe<AppliedFiltersOutput>;
  paging?: Maybe<PagingData>;
  /** Available filters for provider search */
  possible_filters?: Maybe<PossibleFiltersOutput>;
  /** Available filters for provider search, includes additional attributes */
  possible_filters_v2?: Maybe<PossibleFiltersV2OutputType>;
  providers?: Maybe<Array<Maybe<CareProvider>>>;
  query_request_id?: Maybe<Scalars['String']>;
};

/** A paginated list of settings */
export type PaginatedSettings = {
  __typename?: 'PaginatedSettings';
  data: Array<Maybe<Setting>>;
  paging: PagingData;
};

/** A paginated list of trials */
export type PaginatedTrials = {
  __typename?: 'PaginatedTrials';
  data: Array<Maybe<Trial>>;
  paging: PagingData;
};

/** A paginated list of users */
export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  data: Array<Maybe<User>>;
  paging: PagingData;
};

/** A paginated list of workplace videos */
export type PaginatedWorkplaceVideos = {
  __typename?: 'PaginatedWorkplaceVideos';
  data: Array<WorkplaceVideo>;
  paging: PagingData;
};

/** Information about the current place in dataset */
export type PagingData = {
  __typename?: 'PagingData';
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

/** A Patient Practice operated by Spring Health */
export type PatientPractice = {
  __typename?: 'PatientPractice';
  billdotcom_account_name: Scalars['String'];
  billdotcom_account_number: Scalars['String'];
  id: Scalars['ID'];
  locations: Array<Maybe<PatientPracticeLocation>>;
  name: Scalars['String'];
};

/** A location in which a Patient Practice operates */
export type PatientPracticeLocation = {
  __typename?: 'PatientPracticeLocation';
  id: Scalars['ID'];
  location: Scalars['String'];
  patient_practice?: Maybe<PatientPractice>;
};

/** charge payment method for a given member */
export type PaymentChargeType = {
  __typename?: 'PaymentChargeType';
  payment: PaymentInfoType;
  success?: Maybe<Scalars['Boolean']>;
};

/** Payment history for a given member */
export type PaymentHistory = {
  __typename?: 'PaymentHistory';
  next_page?: Maybe<Scalars['String']>;
  payments: Array<PaymentHistoryData>;
  previous_page?: Maybe<Scalars['String']>;
};

/** Payment history data for each payment */
export type PaymentHistoryData = {
  __typename?: 'PaymentHistoryData';
  amount: Scalars['String'];
  date_paid: Scalars['String'];
  date_posted?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  receipt?: Maybe<Scalars['String']>;
};

/** Payment Info Type */
export type PaymentInfoType = {
  __typename?: 'PaymentInfoType';
  amount?: Maybe<Scalars['String']>;
  date_paid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type PaymentInputType = {
  amount: Scalars['String'];
  card_id?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  member_id: Scalars['ID'];
  outstanding_balance: Scalars['String'];
  save_card?: InputMaybe<Scalars['Boolean']>;
  token_id?: InputMaybe<Scalars['String']>;
};

/** Card Output Type */
export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  auto_pay_enabled?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

/** payment method info for a given member */
export type PaymentMethodInfoType = {
  __typename?: 'PaymentMethodInfoType';
  info: PaymentMethod;
  success?: Maybe<Scalars['Boolean']>;
};

/** The personalized match response */
export type PersonalizedProviderBioMatchDescription = {
  __typename?: 'PersonalizedProviderBioMatchDescription';
  error?: Maybe<Scalars['String']>;
  explanation_modalities?: Maybe<Scalars['String']>;
  explanation_modality_goal?: Maybe<Scalars['String']>;
  explanation_specialties?: Maybe<Scalars['String']>;
  explanation_treatment_concern?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  therapeutic_approach?: Maybe<Scalars['String']>;
};

/** Plan rates for contract */
export type PlanRate = {
  __typename?: 'PlanRate';
  appt_type: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  cpt_code: Scalars['String'];
  currency: Scalars['String'];
  display_rate: Scalars['String'];
  medium: Scalars['String'];
  service_description: Scalars['String'];
};

/** A service that is capable of being offered through the platform */
export type PlatformService = {
  __typename?: 'PlatformService';
  appointment_kind: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type PossibleFiltersOption = {
  __typename?: 'PossibleFiltersOption';
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type PossibleFiltersOutput = {
  __typename?: 'PossibleFiltersOutput';
  allow_company_exclusive: Scalars['Boolean'];
  conditions: Array<Scalars['String']>;
  days_of_week: Array<Scalars['String']>;
  description_tags: Array<CareProviderTagDescriptionTagEnumType>;
  ethnicities: Array<Scalars['String']>;
  genders: Array<Scalars['String']>;
  languages: Array<Scalars['String']>;
  licenses: LicensesPossibleFiltersOutput;
  mediums: Array<Scalars['String']>;
  specialties: Array<Scalars['String']>;
  time_of_day: Array<Scalars['String']>;
  treatment_modalities: Array<Scalars['String']>;
};

export type PossibleFiltersType = {
  __typename?: 'PossibleFiltersType';
  key: Scalars['String'];
  options: Array<PossibleFiltersOption>;
  title: Scalars['String'];
};

export type PossibleFiltersV2OutputType = {
  __typename?: 'PossibleFiltersV2OutputType';
  conditions: PossibleFiltersType;
  days_of_week: PossibleFiltersType;
  description_tags: PossibleFiltersType;
  ethnicities: PossibleFiltersType;
  genders: PossibleFiltersType;
  languages: PossibleFiltersType;
  mediums: PossibleFiltersType;
  specialties: PossibleFiltersType;
  time_of_day: PossibleFiltersType;
  treatment_modalities: PossibleFiltersType;
};

/** An address for a physical location */
export type PostalAddress = {
  __typename?: 'PostalAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_display_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type PotentialCoveredLifeInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  is_minor?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  signup_source?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export enum PreferenceEnumType {
  AlcoholOrSubstanceUse = 'ALCOHOL_OR_SUBSTANCE_USE',
  EmotionalWellBeing = 'EMOTIONAL_WELL_BEING',
  FertilityChallenges = 'FERTILITY_CHALLENGES',
  Finances = 'FINANCES',
  ParentingSkills = 'PARENTING_SKILLS',
  PhysicalHealth = 'PHYSICAL_HEALTH',
  Relationships = 'RELATIONSHIPS',
}

/** A preference type */
export type PreferenceQuestion = {
  __typename?: 'PreferenceQuestion';
  component?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Array<Scalars['String']>>;
  question_order?: Maybe<Scalars['Int']>;
  question_text: Scalars['String'];
  question_type: Scalars['String'];
  slider_options?: Maybe<Array<TherapyStyleOption>>;
  type_options?: Maybe<Array<Scalars['JSON']>>;
};

/** A covered life or member object */
export type PrimaryGrantor = CoveredLife | Member;

/** An Invitation to a member to join a Care Provider's Private Practice */
export type PrivatePracticeInvitedMember = {
  __typename?: 'PrivatePracticeInvitedMember';
  care_provider: CareProvider;
  created_at: Scalars['DateTime'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name: Scalars['String'];
  member?: Maybe<Member>;
  phone_number?: Maybe<Scalars['String']>;
};

/** An Invitation to a Member to join Spring Health */
export type PrivatePracticeInvitedMemberInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone_number?: InputMaybe<Scalars['String']>;
};

/** Attributes for processing a customer deactivation */
export type ProcessCustomerDeactivationInput = {
  customer_deactivation_id: Scalars['ID'];
};

export type ProcessCustomerDeactivationOutput = {
  __typename?: 'ProcessCustomerDeactivationOutput';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** A prompt in a questionnaire */
export type Prompt = {
  __typename?: 'Prompt';
  id: Scalars['ID'];
  newSubtitle?: Maybe<Scalars['String']>;
  newTitle?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  questions: Array<Question>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** A prompt in a questionnaire */
export type PromptTitleArgs = {
  format?: InputMaybe<PromptTitleFormatEnum>;
};

export type PromptResponseInputType = {
  prompt_id: Scalars['ID'];
  question_responses: Scalars['JSON'];
};

/** The format of the prompt title */
export enum PromptTitleFormatEnum {
  Markdown = 'MARKDOWN',
  Plaintext = 'PLAINTEXT',
}

export type ProviderDistance = {
  distance_miles?: InputMaybe<Scalars['Float']>;
};

export type ProviderRequestAvailabilityZendeskTicketInput = {
  additional_note?: InputMaybe<Scalars['String']>;
  conditions?: InputMaybe<Array<Scalars['String']>>;
  ethnicities?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  preferred_day?: InputMaybe<Array<Scalars['String']>>;
  preferred_location?: InputMaybe<Array<Scalars['String']>>;
  preferred_time?: InputMaybe<Array<Scalars['String']>>;
  provider_role: Scalars['String'];
  specialties?: InputMaybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** Fetch all Acuity accounts */
  acuity_accounts?: Maybe<AcuityAccountList>;
  /** Find appointment types */
  acuity_types?: Maybe<AppointmentTypes>;
  /** Find appointment times for multiple CNs */
  all_available_cn_appointment_times?: Maybe<Array<Maybe<AvailableCnAppointmentTimes>>>;
  /** Find appointment times with multiple CNs */
  all_available_cn_appointment_times_v2?: Maybe<Array<Maybe<AvailableCnAppointmentTimeOutputType>>>;
  /** Get info for all countries */
  all_countries: Array<CountryInfo>;
  /** Fetch all Apero Customers */
  apero_customers?: Maybe<AperoCustomerList>;
  /** View AppTasks in the application */
  app_tasks: PaginatedAppTasks;
  /** Find an Appointment by ID */
  appointment?: Maybe<Appointment>;
  /**
   * Determine appointment kind based on member, provider, and provider role
   * @deprecated Doesn't functionlly work for new WPMHC roles. Incorrect assumption about role to kind relationship.
   */
  appointment_kinds?: Maybe<AppointmentKindOutput>;
  /** Get list of appointments using connections */
  appointment_nodes?: Maybe<AppointmentConnection>;
  /**
   * Display available slots for an appointment
   * @deprecated Use careProviderAvailabilities instead
   */
  appointment_slots?: Maybe<FetchAppointmentSlotsOutput>;
  /**
   * Display the available appointment slots between a member & provider
   * @deprecated Use careProviderAvailabilities instead
   */
  appointment_slots_v2?: Maybe<FetchAvailableAppointmentSlotsOutput>;
  /** Find appointments */
  appointments?: Maybe<PaginatedAppointments>;
  /** Find an Assessment by id */
  assessment?: Maybe<Assessment>;
  /** Find assessments for a member */
  assessments?: Maybe<PaginatedAssessments>;
  /** Returns all the benefits a member has */
  benefits: Benefits;
  /**
   * Find an Booking by ID
   * @deprecated Use direct relation on appointments instead
   */
  booking?: Maybe<Booking>;
  /** Browse care providers for members */
  browse_care_providers?: Maybe<PaginatedCareProvidersResults>;
  /**
   * Browse care providers for members
   * @deprecated Use care_providers_search instead
   */
  browse_recommended_care_providers?: Maybe<PaginatedCareProvidersResults>;
  /**
   * Find a Calendar by ID
   * @deprecated Use direct relation on appointments instead
   */
  calendar?: Maybe<Calendar>;
  /** Find member's Care Plan Progress */
  care_plan_progress?: Maybe<CarePlanProgress>;
  /** Find a Care Provider by ID */
  care_provider?: Maybe<CareProvider>;
  /** Display the available appointment slots between a member & provider */
  care_provider_availabilities?: Maybe<FetchAvailableAppointmentSlotsOutput>;
  /** Fetch one-off override events for a provider's availability */
  care_provider_availability_events?: Maybe<CareProviderAvailabilityEvents>;
  /** Fetch availability slots for a care provider */
  care_provider_availability_slots?: Maybe<CareProviderAvailabilitySlots>;
  /** Find recurring availability blocks by provider */
  care_provider_recurring_availability_blocks?: Maybe<CareProviderRecurringAvailabilityBlocks>;
  /** Find scheduling constraints for a provider */
  care_provider_scheduling_constraints?: Maybe<SchedulingConstraintsType>;
  /** Find scheduling settings for a provider */
  care_provider_scheduling_settings?: Maybe<SchedulingSettingsType>;
  /** Fetch all care provider tags */
  care_provider_tags: PaginatedCareProviderTags;
  /** Find care providers */
  care_providers?: Maybe<PaginatedCareProviders>;
  /** Find care providers by id */
  care_providers_by_id?: Maybe<Array<CareProvider>>;
  /** Search through all providers */
  care_providers_search?: Maybe<PaginatedSearchCareProvidersResult>;
  /** Find a CareTeam by ID */
  care_team?: Maybe<CareTeam>;
  /**
   * View CensusErrors in the application
   * @deprecated Use census_ingestion_rows instead
   */
  census_errors?: Maybe<PaginatedCensusErrors>;
  /** Find a Census Ingestion by ID */
  census_ingestion?: Maybe<CensusIngestion>;
  /** View CensusIngestionsRows in the application */
  census_ingestion_rows?: Maybe<PaginatedCensusIngestionRows>;
  /** View CensusIngestions in the application */
  census_ingestions?: Maybe<PaginatedCensusIngestions>;
  /** Find a Channel Partner by ID */
  channel_partner?: Maybe<ChannelPartner>;
  /** Find channel partners */
  channel_partners?: Maybe<PaginatedChannelPartner>;
  /** Find a Cohort by ID */
  cohort?: Maybe<Cohort>;
  /** Fetch covered lives for a particular cohort */
  cohort_covered_lives?: Maybe<PaginatedCoveredLives>;
  /** Find cohort_email_customizations */
  cohort_email_customizations?: Maybe<PaginatedCohortEmailCustomizations>;
  /** Find a CohortPlatformService by ID */
  cohort_platform_service?: Maybe<CohortPlatformService>;
  /** Find cohort platform services */
  cohort_platform_services?: Maybe<PaginatedCohortPlatformServices>;
  /** Fetch signup details for a cohort */
  cohort_signup_details?: Maybe<CohortSignupDetails>;
  /** Find cohorts */
  cohorts?: Maybe<Array<Cohort>>;
  /** Covered Life and Member Search for Compass */
  compass_client_search?: Maybe<PaginatedClientsResults>;
  /** Find a Member that could have a null user by ID */
  compass_member?: Maybe<CompassMember>;
  /** Member Search for Compass */
  compass_member_search?: Maybe<PaginatedMemberResults>;
  /** Fetch all Connected Benefits */
  connected_benefits: PaginatedConnectedBenefits;
  /** Finds contract terms for a given customer */
  contract_terms: Array<ContractTerm>;
  /** Get all state info for country */
  country_states: Array<StateInfo>;
  /** Find a covered life by ID */
  covered_life?: Maybe<CoveredLife>;
  /** Fetch covered life deactivations */
  covered_life_deactivations?: Maybe<CoveredLifeDeactivations>;
  /** Find a Covered Life by first name, last name, email, and phone */
  covered_lives?: Maybe<PaginatedCoveredLives>;
  /** Find a Covered Life by customer_id */
  covered_lives_by_customer_id?: Maybe<PaginatedCoveredLives>;
  /** Find a Customer by ID */
  customer?: Maybe<Customer>;
  /** Find a Customer on Apero by ID */
  customer_apero_record?: Maybe<CustomerAperoRecord>;
  /** Get a list of a customer's cohorts */
  customer_cohorts?: Maybe<CohortConnection>;
  /** Fetch all Customer's Connected Benefits */
  customer_connected_benefits: CustomerConnectedBenefitOutputType;
  /** Finds a Customer's custom role name */
  customer_custom_role_name?: Maybe<CustomerRoleName>;
  /** Fetch a single Customer Deactivation */
  customer_deactivation?: Maybe<CustomerDeactivation>;
  /** Fetch customer deactivations */
  customer_deactivations?: Maybe<CustomerDeactivations>;
  /** Fetch metrics for a specific customer */
  customer_metrics?: Maybe<CustomerMetrics>;
  /** Find a CustomerSite by ID */
  customer_site: CustomerSite;
  /** Get a list of a customer's sites */
  customer_sites?: Maybe<Array<CustomerSite>>;
  customer_workplace_customization?: Maybe<WorkplaceCustomization>;
  /** Find customers */
  customers?: Maybe<PaginatedCustomers>;
  /** Find demo user covered lives */
  demo_user_covered_lives?: Maybe<PaginatedDemoUserCoveredLives>;
  /** Find a Document by ID from EHR */
  document?: Maybe<DocumentOutput>;
  /** Find iterable email events for a member by ID */
  email_events?: Maybe<Events>;
  /** Get Cost Estimate for an Encounter */
  encounter_cost_estimate?: Maybe<CostEstimate>;
  /** Get Cost Estimate Log Entries */
  encounter_cost_estimate_log_entries?: Maybe<CostEstimateLogEntries>;
  /** Get Cost Estimate Log Entry */
  encounter_cost_estimate_log_entry?: Maybe<CostEstimateLogEntry>;
  /** Find an Exercise by ID */
  exercise?: Maybe<Exercise>;
  /** Fetch all exercises */
  exercises: PaginatedExercises;
  /** Find provider external calendar sync items */
  external_calendar_sync_items?: Maybe<ExternalCalendarSyncItems>;
  /** Fetch the sync status for External Calendars */
  external_calendar_sync_status: FetchSyncStatusOutput;
  /** Find featured Workplace Videos */
  featured_workplace_videos: PaginatedWorkplaceVideos;
  find_covered_life?: Maybe<FindCoveredLifeOutput>;
  /** Find if a survey is expired or already submitted for an appointment id */
  get_survey_status?: Maybe<SurveyOutputType>;
  /** Find a goal by ID */
  goal?: Maybe<Goal>;
  /** Get all goals */
  goals?: Maybe<PaginatedGoals>;
  /** Fetch a care providers Google calendars for external calendar sync */
  google_calendars: FetchGoogleCalendarOutput;
  /** Find help info by country */
  help_info: HelpInfo;
  /** Eligibility Check for member's insurance */
  insurance_eligibility_check?: Maybe<InsuranceEligibilityCheck>;
  /** Insurance Payers search */
  insurance_payer_search?: Maybe<Array<InsurancePayerSearchResult>>;
  /** Search for and fetch covered lives that can be linked to a member */
  linkable_covered_lives?: Maybe<PaginatedCoveredLives>;
  /** Find a Member by ID */
  member?: Maybe<Member>;
  /** Fetch appointment invoices for a given member. */
  member_appointment_invoices: AppointmentInvoices;
  /** Get list of appointments for a single member, using connections */
  member_appointments: AppointmentConnection;
  /** Fetch outstanding balance for a given member. */
  member_balance: Balance;
  /** Returns the level of coaching a member has access to. */
  member_coaching_tier?: Maybe<MemberCoachingTier>;
  /** Fetch all Connected Benefits for a member */
  member_connected_benefits?: Maybe<MemberConnectedBenefitOutputType>;
  /** Fetch covered appointments for a given member. */
  member_covered_appointments: PaginatedCoveredAppointments;
  /** Find a member goal by ID */
  member_goal?: Maybe<MemberGoal>;
  /** Find a member's progress for a specific goal */
  member_goal_progress?: Maybe<MemberGoalProgress>;
  /** Get all member goals */
  member_goals?: Maybe<PaginatedMemberGoals>;
  /** Fetch payment history for a given member. */
  member_payment_history: PaymentHistory;
  /** Fetch payment method for a given member. */
  member_payment_method?: Maybe<PaymentMethod>;
  /** Fetch Preferences for a member */
  member_preferences?: Maybe<FetchMemberPreference>;
  /** Find a Member Previsit by ID */
  member_previsit?: Maybe<MemberPrevisit>;
  /** Find referrals for a member */
  member_referrals?: Maybe<PaginatedReferralsType>;
  /** Fetch statement pdf for a given member. */
  member_statement_pdf: Statement;
  /** Find members */
  members?: Maybe<PaginatedMembers>;
  /** Find members for a given care provider */
  members_by_care_provider?: Maybe<ByCareProviderOutputType>;
  /** Find members by ID */
  members_by_id?: Maybe<Array<Member>>;
  /** List patient practices and their locations */
  patient_practices?: Maybe<PaginatedPatientPractices>;
  /** Fetch receipt for a given member. */
  payment_receipt_pdf: Receipt;
  /** Find a PlatformService by ID */
  platform_service?: Maybe<PlatformService>;
  /** Find platform services */
  platform_services?: Maybe<PaginatedPlatformServices>;
  /** Fetch Find Help Program */
  program?: Maybe<FindHelpProgram>;
  /** Fetch Find Help Programs */
  programs?: Maybe<PaginatedFindHelpProgramsType>;
  /** Find an Questionnaire by id */
  questionnaire?: Maybe<Questionnaire>;
  /** Retrieve questionnaire kinds */
  questionnaire_kinds?: Maybe<Array<QuestionnaireKind>>;
  /** Find questionnaires by member id */
  questionnaires?: Maybe<PaginatedQuestionnaires>;
  /** Find a ReportingIntegration by ID */
  reporting_integration?: Maybe<ReportingIntegration>;
  /** List reporting integration partners */
  reporting_integration_partners?: Maybe<PaginatedReportingIntegrationPartners>;
  /** List a reporting integration's runs */
  reporting_integration_runs: PaginatedReportingIntegrationRuns;
  /** List reporting integrations */
  reporting_integrations?: Maybe<PaginatedReportingIntegrations>;
  /** Updates livekit permissions for a participant in a given appointment */
  session_admit_participant?: Maybe<SessionAdmitParticipantStatusOutput>;
  /** Fetches data to start an in-house video session for a given appointment */
  session_check_in?: Maybe<SessionCheckInOutput>;
  /**
   * Find a Setting by ID or Name
   * @deprecated No longer used
   */
  setting?: Maybe<Setting>;
  /**
   * Find settings
   * @deprecated No longer used
   */
  settings?: Maybe<PaginatedSettings>;
  /** Returns boolean flag to show gated booking */
  show_gated_booking_flow: GatedBookingFlowType;
  /** Fetch all SUD care navigators */
  sud_care_navigators?: Maybe<Array<SudProvider>>;
  /**
   * Find suggested Care Providers
   * @deprecated No longer used
   */
  suggested_care_providers?: Maybe<Array<CareProvider>>;
  /**
   * Fetches all unique languages supported by our therapists or medication managers
   * @deprecated No longer used
   */
  supported_languages?: Maybe<SupportedLanguagesOutputType>;
  /** return a temporary password used for sign in when a parent goes into dependent account */
  temporary_password?: Maybe<TemporaryPassword>;
  /** Find a Trial by ID */
  trial?: Maybe<Trial>;
  /** List trials */
  trials?: Maybe<PaginatedTrials>;
  /** Find a User by ID */
  user?: Maybe<User>;
  /** Find users */
  users?: Maybe<PaginatedUsers>;
  /** Find users by ID */
  users_by_id?: Maybe<Array<User>>;
  /** Find Workplace Exercises */
  workplace_exercises: Array<Exercise>;
  /** Returns a list of workplace videos that are marked as top picks */
  workplace_top_picks: PaginatedWorkplaceVideos;
  /** Find a Workplace Video by ID, categories, or content_type */
  workplace_video?: Maybe<WorkplaceVideo>;
  /** Find all Workplace Videos by ID, categories, or content_type */
  workplace_videos: PaginatedWorkplaceVideos;
};

export type QueryAcuity_TypesArgs = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryAll_Available_Cn_Appointment_TimesArgs = {
  member_id: Scalars['ID'];
  start_date: Scalars['DateTime'];
  sud_only?: InputMaybe<Scalars['Boolean']>;
  utc_offset?: InputMaybe<Scalars['String']>;
};

export type QueryAll_Available_Cn_Appointment_Times_V2Args = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  member_id: Scalars['ID'];
  start_date: Scalars['DateTime'];
  sud_only?: InputMaybe<Scalars['Boolean']>;
};

export type QueryAll_CountriesArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type QueryApero_CustomersArgs = {
  apero_customer_category_name?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['ID'];
};

export type QueryApp_TasksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['ID']>;
  parent_type?: InputMaybe<AppTaskParentEnum>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  status?: InputMaybe<AppTaskStatusEnum>;
};

export type QueryAppointmentArgs = {
  id: Scalars['ID'];
};

export type QueryAppointment_KindsArgs = {
  member: Scalars['ID'];
  provider: Scalars['ID'];
  provider_role: CareProviderRoleForMembers;
};

export type QueryAppointment_NodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  booking_name?: InputMaybe<Scalars['String']>;
  booking_status?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  kind?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  paid?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  starting_after?: InputMaybe<Scalars['Date']>;
  starting_after_timestamp?: InputMaybe<Scalars['DateTime']>;
  starting_before?: InputMaybe<Scalars['Date']>;
  starting_before_timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type QueryAppointment_SlotsArgs = {
  end_span: Scalars['DateTime'];
  filters?: InputMaybe<CareProviderFiltersInputType>;
  kind?: InputMaybe<AppointmentKindEnum>;
  medium: AppointmentMediumEnum;
  page_number?: InputMaybe<Scalars['Int']>;
  provider_index?: InputMaybe<Scalars['Int']>;
  should_infer_kind?: InputMaybe<Scalars['Boolean']>;
  slot_increment?: InputMaybe<Scalars['Int']>;
  start_span: Scalars['DateTime'];
  upcoming_slots_count?: InputMaybe<Scalars['Int']>;
  upcoming_slots_only?: InputMaybe<Scalars['Boolean']>;
  user_ids: Array<InputMaybe<Scalars['ID']>>;
};

export type QueryAppointment_Slots_V2Args = {
  filters?: InputMaybe<CareProviderFiltersInputType>;
  kind: AppointmentKindEnum;
  medium?: InputMaybe<AppointmentMediumEnum>;
  range: AppointmentDateRangeInput;
  upcoming_slots_count?: InputMaybe<Scalars['Int']>;
  upcoming_slots_only?: InputMaybe<Scalars['Boolean']>;
  user_ids: AppointmentUsersInput;
};

export type QueryAppointmentsArgs = {
  attended?: InputMaybe<AppointmentAttendedEnumType>;
  attended_source?: InputMaybe<AppointmentAttendedSourceEnumType>;
  billing_status?: InputMaybe<Array<InputMaybe<AppointmentBillingStatusEnumType>>>;
  booking_name?: InputMaybe<Scalars['String']>;
  booking_user_id?: InputMaybe<Scalars['ID']>;
  care_appointments_only?: InputMaybe<Scalars['Boolean']>;
  care_provider_id?: InputMaybe<Scalars['ID']>;
  ending_after_timestamp?: InputMaybe<Scalars['DateTime']>;
  ending_before_timestamp?: InputMaybe<Scalars['DateTime']>;
  exclude_attended?: InputMaybe<Array<AppointmentAttendedEnumType>>;
  exclude_ids?: InputMaybe<Array<Scalars['ID']>>;
  exclude_status?: InputMaybe<Scalars['String']>;
  include_ids?: InputMaybe<Array<Scalars['ID']>>;
  internal_kind?: InputMaybe<AppointmentKindEnum>;
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  member_has_customer?: InputMaybe<Scalars['ID']>;
  member_has_customer_name?: InputMaybe<Scalars['String']>;
  member_id?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  paid?: InputMaybe<Scalars['String']>;
  patient_practice_name?: InputMaybe<Scalars['String']>;
  payment_preference?: InputMaybe<Array<InputMaybe<MemberPaymentPreferenceEnumType>>>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  sorting_by_member_name?: InputMaybe<Scalars['Boolean']>;
  starting_after?: InputMaybe<Scalars['Date']>;
  starting_after_timestamp?: InputMaybe<Scalars['DateTime']>;
  starting_before?: InputMaybe<Scalars['Date']>;
  starting_before_timestamp?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryAssessmentArgs = {
  id: Scalars['ID'];
};

export type QueryAssessmentsArgs = {
  kind?: InputMaybe<AssessmentKindEnumType>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  risk?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  triaged?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBenefitsArgs = {
  id: Scalars['ID'];
};

export type QueryBookingArgs = {
  id: Scalars['ID'];
};

export type QueryBrowse_Care_ProvidersArgs = {
  browsing_as_member_id?: InputMaybe<Scalars['String']>;
  care_provider_tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  care_provider_tag_ids_exact_match?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  dedicated_only?: InputMaybe<Scalars['Boolean']>;
  distance_from?: InputMaybe<DistanceFromSearchInput>;
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  exclude_recommended?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  name_or_email?: InputMaybe<Scalars['String']>;
  network_status?: InputMaybe<Array<InputMaybe<NetworkStatusEnumType>>>;
  offset?: InputMaybe<Scalars['Int']>;
  radius_search?: InputMaybe<RadiusSearchInput>;
  role?: InputMaybe<CareProviderRoleForMembers>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBrowse_Recommended_Care_ProvidersArgs = {
  country?: InputMaybe<Scalars['String']>;
  distance_from?: InputMaybe<DistanceFromSearchInput>;
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  name_or_email?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  radius_search?: InputMaybe<RadiusSearchInput>;
  role?: InputMaybe<CareProviderRoleForMembers>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryCare_Plan_ProgressArgs = {
  member_id: Scalars['ID'];
};

export type QueryCare_ProviderArgs = {
  distance_from?: InputMaybe<DistanceFromSearchInput>;
  id: Scalars['ID'];
};

export type QueryCare_Provider_AvailabilitiesArgs = {
  filters?: InputMaybe<CareProviderFiltersInputType>;
  kind: AppointmentKindEnum;
  medium?: InputMaybe<AppointmentMediumEnum>;
  range: AppointmentDateRangeInput;
  upcoming_slots_count?: InputMaybe<Scalars['Int']>;
  upcoming_slots_only?: InputMaybe<Scalars['Boolean']>;
  user_ids: AppointmentUsersInput;
};

export type QueryCare_Provider_Availability_EventsArgs = {
  care_provider_id: Scalars['ID'];
};

export type QueryCare_Provider_Availability_SlotsArgs = {
  care_provider_id: Scalars['ID'];
  end_at: Scalars['DateTime'];
  medium?: InputMaybe<Scalars['String']>;
  start_at: Scalars['DateTime'];
};

export type QueryCare_Provider_Recurring_Availability_BlocksArgs = {
  care_provider_id: Scalars['ID'];
};

export type QueryCare_Provider_Scheduling_ConstraintsArgs = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCare_Provider_Scheduling_SettingsArgs = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCare_Provider_TagsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  show_spring_verified_tags?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCare_ProvidersArgs = {
  by_dedicated_customer_id?: InputMaybe<Scalars['ID']>;
  care_navigator_customer_assignment_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  care_provider_tag_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  created_after?: InputMaybe<Scalars['Date']>;
  created_before?: InputMaybe<Scalars['Date']>;
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gender?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  name_or_email?: InputMaybe<Scalars['String']>;
  network_status?: InputMaybe<Array<InputMaybe<NetworkStatusEnumType>>>;
  offset?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  standard_care_navigators_only?: InputMaybe<Scalars['Boolean']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCare_Providers_By_IdArgs = {
  id: Array<Scalars['ID']>;
};

export type QueryCare_Providers_SearchArgs = {
  filters?: InputMaybe<CareProviderFiltersInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  roles: Array<CareProviderRoleEnum>;
  version?: InputMaybe<Scalars['String']>;
};

export type QueryCare_TeamArgs = {
  id: Scalars['ID'];
};

export type QueryCensus_ErrorsArgs = {
  cohort_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  validation_id?: InputMaybe<Scalars['ID']>;
};

export type QueryCensus_IngestionArgs = {
  id: Scalars['ID'];
};

export type QueryCensus_Ingestion_RowsArgs = {
  census_ingestion_id: Scalars['ID'];
  corporate_email?: InputMaybe<Scalars['String']>;
  covered_life_id?: InputMaybe<Scalars['String']>;
  failed_rows_only?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCensus_IngestionsArgs = {
  cohort_name?: InputMaybe<Scalars['String']>;
  covered_life_id?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  with_failed_rows_only?: InputMaybe<Scalars['Boolean']>;
};

export type QueryChannel_PartnerArgs = {
  id: Scalars['ID'];
};

export type QueryChannel_PartnersArgs = {
  customer_or_employer_or_peo?: InputMaybe<Scalars['String']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCohortArgs = {
  id: Scalars['ID'];
};

export type QueryCohort_Covered_LivesArgs = {
  corporate_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  office_city?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  other_1?: InputMaybe<Scalars['String']>;
  other_2?: InputMaybe<Scalars['String']>;
  other_3?: InputMaybe<Scalars['String']>;
  other_4?: InputMaybe<Scalars['String']>;
  other_5?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCohort_Email_CustomizationsArgs = {
  cohort_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCohort_Platform_ServiceArgs = {
  id: Scalars['ID'];
};

export type QueryCohort_Platform_ServicesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCohort_Signup_DetailsArgs = {
  cohort_id: Scalars['ID'];
};

export type QueryCohortsArgs = {
  basic_visits_covered?: InputMaybe<Scalars['Int']>;
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  default?: InputMaybe<Scalars['Boolean']>;
  dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  specialist_visits_covered?: InputMaybe<Scalars['Int']>;
};

export type QueryCompass_Client_SearchArgs = {
  customer_id?: InputMaybe<Scalars['ID']>;
  page: Scalars['Int'];
  per_page: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  show_all_clients?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<SortType>;
  status?: InputMaybe<CompassClientSearchStatusEnumType>;
};

export type QueryCompass_MemberArgs = {
  id: Scalars['ID'];
};

export type QueryCompass_Member_SearchArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  show_all_members?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<SortType>;
};

export type QueryConnected_BenefitsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryContract_TermsArgs = {
  cohort_id?: InputMaybe<Scalars['ID']>;
  customer_id: Scalars['ID'];
  end_date_before?: InputMaybe<Scalars['ISO8601Date']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  plan_status?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCountry_StatesArgs = {
  country: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
};

export type QueryCovered_LifeArgs = {
  id: Scalars['ID'];
};

export type QueryCovered_Life_DeactivationsArgs = {
  cohort_deactivation_id?: InputMaybe<Scalars['ID']>;
  corporate_email?: InputMaybe<Scalars['String']>;
  covered_life_id?: InputMaybe<Scalars['ID']>;
  customer_deactivation_id: Scalars['ID'];
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCovered_LivesArgs = {
  corporate_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  covered_life_id?: InputMaybe<Scalars['ID']>;
  customer_or_employer_or_peo?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCovered_Lives_By_Customer_IdArgs = {
  customer_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCustomer_Apero_RecordArgs = {
  apero_customer_category_name?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type QueryCustomer_CohortsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCustomer_Connected_BenefitsArgs = {
  customer_id: Scalars['ID'];
};

export type QueryCustomer_Custom_Role_NameArgs = {
  customer_id: Scalars['ID'];
  roles: Array<Scalars['String']>;
};

export type QueryCustomer_DeactivationArgs = {
  id: Scalars['ID'];
};

export type QueryCustomer_DeactivationsArgs = {
  automatic?: InputMaybe<Scalars['Boolean']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  customer_name?: InputMaybe<Scalars['String']>;
  failsafes_triggered?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCustomer_MetricsArgs = {
  customer_id?: InputMaybe<Scalars['ID']>;
};

export type QueryCustomer_SiteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryCustomer_SitesArgs = {
  customer_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryCustomer_Workplace_CustomizationArgs = {
  customer_id: Scalars['ID'];
};

export type QueryCustomersArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  care_navigation_contract_type?: InputMaybe<Array<Scalars['String']>>;
  channel_partner_id?: InputMaybe<Scalars['String']>;
  dedicated?: InputMaybe<Scalars['Boolean']>;
  direct_employer_or_peo?: InputMaybe<Scalars['String']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryDemo_User_Covered_LivesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryDocumentArgs = {
  id: Scalars['ID'];
};

export type QueryEmail_EventsArgs = {
  id: Scalars['ID'];
};

export type QueryEncounter_Cost_EstimateArgs = {
  cpt_code: Scalars['String'];
  cpt_code_modifier?: InputMaybe<Scalars['String']>;
  date_of_service: Scalars['Date'];
  member_id: Scalars['ID'];
};

export type QueryEncounter_Cost_Estimate_Log_EntriesArgs = {
  member_id: Scalars['ID'];
};

export type QueryEncounter_Cost_Estimate_Log_EntryArgs = {
  id: Scalars['ID'];
};

export type QueryExerciseArgs = {
  id: Scalars['ID'];
};

export type QueryExercisesArgs = {
  category?: InputMaybe<ExerciseCategoryEnum>;
  daily_moments?: InputMaybe<Scalars['Boolean']>;
  emotion?: InputMaybe<Emotion>;
  exercise_ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  t2_member_only?: InputMaybe<Scalars['Boolean']>;
  teen_spotlighted_only?: InputMaybe<Scalars['Boolean']>;
};

export type QueryExternal_Calendar_Sync_ItemsArgs = {
  care_provider_id: Scalars['ID'];
  end_date: Scalars['Date'];
  start_date: Scalars['Date'];
};

export type QueryExternal_Calendar_Sync_StatusArgs = {
  care_provider_id: Scalars['ID'];
};

export type QueryFeatured_Workplace_VideosArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryFind_Covered_LifeArgs = {
  covered_life_id?: InputMaybe<Scalars['ID']>;
  customer?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['TrimWhitespace']>;
  event_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['TrimWhitespace']>;
  form_name?: InputMaybe<Scalars['String']>;
  invite_token?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['TrimWhitespace']>;
  request_kind?: InputMaybe<RequestKindEnum>;
  secure?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGet_Survey_StatusArgs = {
  appointment_id: Scalars['ID'];
};

export type QueryGoalArgs = {
  id: Scalars['ID'];
};

export type QueryGoalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  version?: InputMaybe<Scalars['String']>;
};

export type QueryGoogle_CalendarsArgs = {
  care_provider_id: Scalars['ID'];
};

export type QueryHelp_InfoArgs = {
  country?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
};

export type QueryInsurance_Eligibility_CheckArgs = {
  insurance_member_id: Scalars['ID'];
  insurance_payer_id: Scalars['ID'];
  member_id: Scalars['ID'];
  on_success_create_coverage?: InputMaybe<Scalars['Boolean']>;
};

export type QueryInsurance_Payer_SearchArgs = {
  name: Scalars['String'];
};

export type QueryLinkable_Covered_LivesArgs = {
  corporate_email?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  home_state?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryMemberArgs = {
  id: Scalars['ID'];
};

export type QueryMember_Appointment_InvoicesArgs = {
  member_id: Scalars['ID'];
  page?: InputMaybe<Scalars['String']>;
  status: StatusEnumType;
};

export type QueryMember_AppointmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id: Scalars['String'];
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  starting_after?: InputMaybe<Scalars['Date']>;
  starting_before?: InputMaybe<Scalars['Date']>;
};

export type QueryMember_BalanceArgs = {
  member_id: Scalars['ID'];
  use_cache?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMember_Coaching_TierArgs = {
  id: Scalars['ID'];
};

export type QueryMember_Connected_BenefitsArgs = {
  member_id: Scalars['ID'];
  recommended_only?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMember_Covered_AppointmentsArgs = {
  end_timestamp?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  start_timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type QueryMember_GoalArgs = {
  id: Scalars['ID'];
};

export type QueryMember_Goal_ProgressArgs = {
  id: Scalars['ID'];
};

export type QueryMember_GoalsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  show_untracked?: InputMaybe<Scalars['Boolean']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryMember_Payment_HistoryArgs = {
  member_id: Scalars['ID'];
  page?: InputMaybe<Scalars['String']>;
};

export type QueryMember_Payment_MethodArgs = {
  member_id: Scalars['ID'];
};

export type QueryMember_PreferencesArgs = {
  member_id: Scalars['ID'];
};

export type QueryMember_PrevisitArgs = {
  id: Scalars['ID'];
};

export type QueryMember_ReferralsArgs = {
  external_program_id?: InputMaybe<Scalars['String']>;
  external_referral_id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryMember_Statement_PdfArgs = {
  member_id: Scalars['ID'];
  statement_type: StatementEnumType;
};

export type QueryMembersArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  channel_partner_id?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_after?: InputMaybe<Scalars['Date']>;
  created_before?: InputMaybe<Scalars['Date']>;
  customer_id?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  customer_or_employer_or_peo?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name_or_email?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMembers_By_Care_ProviderArgs = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
};

export type QueryMembers_By_IdArgs = {
  id: Array<Scalars['ID']>;
};

export type QueryPatient_PracticesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryPayment_Receipt_PdfArgs = {
  member_id: Scalars['ID'];
  payment_id: Scalars['ID'];
};

export type QueryPlatform_ServiceArgs = {
  id: Scalars['ID'];
};

export type QueryPlatform_ServicesArgs = {
  appointment_kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryProgramArgs = {
  program_id: Scalars['ID'];
};

export type QueryProgramsArgs = {
  attribute_tags?: InputMaybe<Array<Scalars['String']>>;
  include_attribute_tags?: InputMaybe<Scalars['Boolean']>;
  keywords?: InputMaybe<Array<Scalars['TrimWhitespace']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  postal_code: Scalars['String'];
  service_tags?: InputMaybe<Array<Scalars['String']>>;
  sort_by?: InputMaybe<FindHelpSortByEnum>;
};

export type QueryQuestionnaireArgs = {
  id: Scalars['ID'];
};

export type QueryQuestionnairesArgs = {
  kind?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  member_id: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
  risk?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  status?: InputMaybe<Scalars['String']>;
};

export type QueryReporting_IntegrationArgs = {
  id: Scalars['ID'];
};

export type QueryReporting_Integration_PartnersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryReporting_Integration_RunsArgs = {
  integration_id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryReporting_IntegrationsArgs = {
  customer_name?: InputMaybe<Scalars['String']>;
  integration_type?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  reporting_integration_partner_id?: InputMaybe<Scalars['ID']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QuerySession_Admit_ParticipantArgs = {
  appointment_id: Scalars['ID'];
  participant_id: Scalars['ID'];
};

export type QuerySession_Check_InArgs = {
  appointmentId: Scalars['ID'];
  participantId?: InputMaybe<Scalars['ID']>;
};

export type QuerySettingArgs = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QuerySettingsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryShow_Gated_Booking_FlowArgs = {
  appointment_kind: Scalars['String'];
  member_id: Scalars['ID'];
};

export type QuerySuggested_Care_ProvidersArgs = {
  role?: InputMaybe<Scalars['String']>;
};

export type QuerySupported_LanguagesArgs = {
  role?: InputMaybe<Scalars['String']>;
};

export type QueryTemporary_PasswordArgs = {
  id: Scalars['ID'];
};

export type QueryTrialArgs = {
  id: Scalars['ID'];
};

export type QueryTrialsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryUserArgs = {
  current_path?: InputMaybe<Scalars['String']>;
  distance_from?: InputMaybe<DistanceFromSearchInput>;
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_after?: InputMaybe<Scalars['Date']>;
  created_before?: InputMaybe<Scalars['Date']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  name_or_email?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<InputMaybe<UserRoleEnumType>>>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
  using_in_house_availability?: InputMaybe<Scalars['Boolean']>;
};

export type QueryUsers_By_IdArgs = {
  id: Array<Scalars['ID']>;
};

export type QueryWorkplace_Top_PicksArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

export type QueryWorkplace_VideoArgs = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  content_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryWorkplace_VideosArgs = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  content_type?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_by?: InputMaybe<Array<InputMaybe<SortType>>>;
};

/** A single question in a questionnaire */
export type Question = {
  __typename?: 'Question';
  element: QuestionElement;
  id: Scalars['ID'];
  key: Scalars['String'];
  newSubtitle?: Maybe<Scalars['String']>;
  newTitle?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  prompt: Prompt;
  required: Scalars['Boolean'];
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type QuestionElement = {
  __typename?: 'QuestionElement';
  allow_float?: Maybe<Scalars['Boolean']>;
  appetite_options?: Maybe<QuestionElementAppetiteOptionsType>;
  border?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  full_width?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  kind: QuestionElementKindEnumType;
  label?: Maybe<Scalars['String']>;
  left_label?: Maybe<Scalars['String']>;
  markers?: Maybe<Array<Scalars['String']>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  options?: Maybe<Array<QuestionElementOptionType>>;
  placeholder?: Maybe<Scalars['String']>;
  right_label?: Maybe<Scalars['String']>;
  show_conditions?: Maybe<QuestionElementConditionsType>;
  step?: Maybe<Scalars['Float']>;
};

export type QuestionElementAppetiteOptionsType = {
  __typename?: 'QuestionElementAppetiteOptionsType';
  left: Array<QuestionElementOptionType>;
  right: Array<QuestionElementOptionType>;
  top: Array<QuestionElementOptionType>;
};

export type QuestionElementConditionsType = {
  __typename?: 'QuestionElementConditionsType';
  dependent_element_id: Scalars['String'];
  field_key: Scalars['String'];
  operator: Scalars['String'];
  score: Scalars['Float'];
};

export enum QuestionElementKindEnumType {
  Appetite = 'APPETITE',
  Checkbox = 'CHECKBOX',
  Number = 'NUMBER',
  Radio = 'RADIO',
  Range = 'RANGE',
  Select = 'SELECT',
  Text = 'TEXT',
  Textarea = 'TEXTAREA',
}

export type QuestionElementOptionType = {
  __typename?: 'QuestionElementOptionType';
  label: Scalars['String'];
  value: Scalars['JSON'];
};

export type QuestionInputType = {
  answer: Scalars['String'];
  question_index: Scalars['Int'];
  question_text: Scalars['String'];
};

/** A questionnaire */
export type Questionnaire = {
  __typename?: 'Questionnaire';
  assessment?: Maybe<Assessment>;
  current_prompts?: Maybe<Array<Prompt>>;
  ended_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  information?: Maybe<Scalars['JSON']>;
  kind: Scalars['String'];
  num_prompts_answered?: Maybe<Scalars['Int']>;
  num_prompts_total: Scalars['Int'];
  prompts: Array<Prompt>;
  questionnaire_kind?: Maybe<QuestionnaireKind>;
  responses: Scalars['JSON'];
  results?: Maybe<Scalars['JSON']>;
  results_sanitized?: Maybe<Scalars['JSON']>;
  risk?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
};

/** A single type of questionnaire available in the application currently */
export type QuestionnaireKind = {
  __typename?: 'QuestionnaireKind';
  abbreviation?: Maybe<Scalars['String']>;
  condition_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_condition_name?: Maybe<Scalars['String']>;
  full_clinical_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  maximum_score?: Maybe<Scalars['Int']>;
  minimum_score?: Maybe<Scalars['Int']>;
  prompts: Array<Prompt>;
};

/** Supported questionnaires */
export enum QuestionnaireName {
  /** The ASRS questionnaire */
  Asrs = 'ASRS',
  /** The AUDIT questionnaire */
  Audit = 'AUDIT',
  /** The AUDITC questionnaire */
  Auditc = 'AUDITC',
  /** The BAM questionnaire */
  Bam = 'BAM',
  /** The CSSRS questionnaire */
  Cssrs1 = 'CSSRS1',
  /** The CSSRS questionnaire */
  Cssrs7 = 'CSSRS7',
  /** The DUMMY questionnaire */
  Dummy = 'DUMMY',
  /** The EPDS questionnaire */
  Epds = 'EPDS',
  /** The FI questionnaire */
  Fi = 'FI',
  /** The GAD2 questionnaire */
  Gad2 = 'GAD2',
  /** The GAD7 questionnaire */
  Gad7 = 'GAD7',
  /** The Issues questionnaire */
  Issues = 'Issues',
  /** The Issues questionnaire */
  IssuesV2 = 'Issues_v2',
  /** The MDQ questionnaire */
  Mdq = 'MDQ',
  /** The MH questionnaire */
  Mh = 'MH',
  /** The PCL5 questionnaire */
  Pcl5 = 'PCL5',
  /** The PCPTSD questionnaire */
  Pcptsd = 'PCPTSD',
  /** The PHQ2 questionnaire */
  Phq2 = 'PHQ2',
  /** The PHQ9 questionnaire */
  Phq9 = 'PHQ9',
  /** The QIDS questionnaire */
  Qids = 'QIDS',
  /** The SCOFF questionnaire */
  Scoff = 'SCOFF',
  /** The SDOH questionnaire */
  Sdoh = 'SDOH',
  /** The SDS questionnaire */
  Sds = 'SDS',
  /** The SIB questionnaire */
  Sib = 'SIB',
  /** The SPRASS questionnaire */
  Sprass = 'SPRASS',
}

export type RadiusSearchInput = {
  address: Scalars['String'];
  radius_mile: Scalars['Float'];
};

/** Receipt file for a member */
export type Receipt = {
  __typename?: 'Receipt';
  file_name?: Maybe<Scalars['String']>;
  pdf_file?: Maybe<Scalars['String']>;
};

export type RecommendedTherapistsFiltersInputType = {
  conditions?: InputMaybe<Array<Scalars['String']>>;
  days_of_week?: InputMaybe<Array<Scalars['String']>>;
  ethnicities?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  languages?: InputMaybe<Array<Scalars['String']>>;
  mediums?: InputMaybe<Array<AppointmentMediumEnum>>;
  specialties?: InputMaybe<Array<Scalars['String']>>;
  time_of_day?: InputMaybe<Array<Scalars['String']>>;
};

export type RecommendedTherapistsPossibleFiltersOutputType = {
  __typename?: 'RecommendedTherapistsPossibleFiltersOutputType';
  ethnicities: Array<Scalars['String']>;
  genders: Array<Scalars['String']>;
  mediums: Array<Scalars['String']>;
  specialties: Array<Scalars['String']>;
};

export type RecommendedTherapistsResult = {
  __typename?: 'RecommendedTherapistsResult';
  es_doc?: Maybe<Scalars['JSON']>;
  /** Corresponding group based on results with filters vs default. Options are default or full_match */
  group_name: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
  query_request_id: Scalars['String'];
  therapist: CareProvider;
};

/** A referral to a comunity care program */
export type Referral = {
  __typename?: 'Referral';
  care_status: ReferralCareStatusEnum;
  created_at: Scalars['DateTime'];
  customer: Customer;
  external_created_at?: Maybe<Scalars['DateTime']>;
  external_program_id: Scalars['String'];
  external_program_name?: Maybe<Scalars['String']>;
  external_provider_id?: Maybe<Scalars['String']>;
  external_provider_name?: Maybe<Scalars['String']>;
  external_receiver_id?: Maybe<Scalars['String']>;
  external_referral_id?: Maybe<Scalars['String']>;
  external_referral_key?: Maybe<Scalars['String']>;
  external_seeker_key?: Maybe<Scalars['String']>;
  external_sender_id?: Maybe<Scalars['String']>;
  external_updated_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  is_external_referral: Scalars['Boolean'];
  member: Member;
  /** service_tags associated to the program for the referral */
  service_tags: Array<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

/** Care status of the referral */
export enum ReferralCareStatusEnum {
  CouldntContact = 'COULDNT_CONTACT',
  CouldntGetHelp = 'COULDNT_GET_HELP',
  Eligible = 'ELIGIBLE',
  GotHelp = 'GOT_HELP',
  NeedsClientAction = 'NEEDS_CLIENT_ACTION',
  NotEligible = 'NOT_ELIGIBLE',
  NotUpdated = 'NOT_UPDATED',
  NoCapacity = 'NO_CAPACITY',
  NoLongerInterested = 'NO_LONGER_INTERESTED',
  Pending = 'PENDING',
  ReferredElsewhere = 'REFERRED_ELSEWHERE',
}

/** A region */
export type Region = {
  __typename?: 'Region';
  chat_availability?: Maybe<Scalars['String']>;
  crisis_support_availability?: Maybe<Scalars['String']>;
  crisis_support_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_availability?: Maybe<Scalars['String']>;
  general_support_availability?: Maybe<Scalars['String']>;
  general_support_number?: Maybe<Scalars['String']>;
  iso2: Scalars['String'];
  languages?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  spring_internal_crisis_support_number?: Maybe<Scalars['String']>;
  work_life_direct_dial?: Maybe<Scalars['String']>;
  work_life_email?: Maybe<Scalars['String']>;
  work_life_free_phone?: Maybe<Scalars['String']>;
};

/** A reporting integration */
export type ReportingIntegration = {
  __typename?: 'ReportingIntegration';
  active: Scalars['Boolean'];
  aws_handle: Scalars['String'];
  changelog: Array<ReportingIntegrationChangelog>;
  created_at: Scalars['DateTime'];
  customer_id?: Maybe<Scalars['ID']>;
  delimiter: Scalars['String'];
  delivery_method: Scalars['String'];
  delta_only: Scalars['Boolean'];
  file_content_type: Scalars['String'];
  filename: Scalars['String'];
  frequency: Scalars['String'];
  header_casing: Scalars['String'];
  id: Scalars['Int'];
  include_record_count: Scalars['Boolean'];
  integration_type: Scalars['String'];
  last_run_at?: Maybe<Scalars['DateTime']>;
  last_run_status?: Maybe<Scalars['String']>;
  last_run_succeeded?: Maybe<Scalars['Boolean']>;
  monthly_reporting_date: Scalars['Boolean'];
  name: Scalars['String'];
  partner_customization: Scalars['String'];
  partner_customization_options?: Maybe<Scalars['JSON']>;
  pgp_encrypted_file: Scalars['Boolean'];
  prepend_count_label?: Maybe<Scalars['String']>;
  report_type: Scalars['String'];
  reporting_integration_partner?: Maybe<ReportingIntegrationPartner>;
  row_terminator: Scalars['String'];
  runs: Array<ReportingIntegrationRun>;
  scheduled_to_run_at?: Maybe<Scalars['DateTime']>;
  snowflake_query_statement?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

/** A record of an update to a reporting integration */
export type ReportingIntegrationChangelog = {
  __typename?: 'ReportingIntegrationChangelog';
  author_name: Scalars['String'];
  changeset: Scalars['JSON'];
  created_at: Scalars['ISO8601DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  updated_at: Scalars['ISO8601DateTime'];
};

export type ReportingIntegrationPartner = {
  __typename?: 'ReportingIntegrationPartner';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A reporting integration run */
export type ReportingIntegrationRun = {
  __typename?: 'ReportingIntegrationRun';
  actual_run_at?: Maybe<Scalars['DateTime']>;
  aws_handle?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  integration: ReportingIntegration;
  row_count?: Maybe<Scalars['Int']>;
  scheduled_to_run_at: Scalars['DateTime'];
  status: Scalars['String'];
};

/** Return from a RequestAppointment mutation */
export type RequestAppointmentOutput = {
  __typename?: 'RequestAppointmentOutput';
  success: Scalars['Boolean'];
};

export type RequestAssessmentInput = {
  kind: AssessmentKindEnumType;
  member_id: Scalars['ID'];
  questionnaires_order: Array<InputMaybe<Scalars['String']>>;
};

/** Return from a RequestAssessment mutation */
export type RequestAssessmentOutput = {
  __typename?: 'RequestAssessmentOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestCareTeamChangeInput = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  member_id: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  role: Scalars['String'];
};

export type RequestCareTeamChangeOutput = {
  __typename?: 'RequestCareTeamChangeOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestInput = {
  availability_days_of_week: Array<InputMaybe<Scalars['String']>>;
  availability_time_of_day: Array<InputMaybe<Scalars['String']>>;
  care_provider_id?: InputMaybe<Scalars['ID']>;
  kind: RequestableAppointmentKindEnum;
  medium: AppointmentMediumEnum;
  notes?: InputMaybe<Scalars['String']>;
};

/** Indicates the kind of request made for CL */
export enum RequestKindEnum {
  /** Find a CL for the primary of the person making the request. */
  Primary = 'PRIMARY',
  /** Find a CL for the person making the request. */
  Self = 'SELF',
}

/** Indicates all the requestable kinds of an appointment */
export enum RequestableAppointmentKindEnum {
  /** An appointment with a Spring Health care navigator. */
  CareNavigation = 'CARE_NAVIGATION',
  /** An appointment with a coach. */
  Coaching = 'COACHING',
  /** An appointment with a therapist, for couples. */
  CouplesTherapy = 'COUPLES_THERAPY',
  /** An appointment with a care consultant focused on the employee and finding solutions. */
  EmployeeConsultation = 'EMPLOYEE_CONSULTATION',
  /** An appointment with a coach. */
  FollowUpCoaching = 'FOLLOW_UP_COACHING',
  /** A follow up appointment with a physician for medication management. */
  FollowUpMedicationManagement = 'FOLLOW_UP_MEDICATION_MANAGEMENT',
  /** An appointment with a WMPHC focused on the individual and finding solutions. */
  IndividualSolutionsFocusedConsultation = 'INDIVIDUAL_SOLUTIONS_FOCUSED_CONSULTATION',
  /** An appointment with a coach. */
  InitialCoaching = 'INITIAL_COACHING',
  /** An initial appointment with a physician for medication management. */
  InitialMedicationManagement = 'INITIAL_MEDICATION_MANAGEMENT',
  /** An appointment with a WMPHC to help folks in the manager role. */
  ManagerConsultationAndSupport = 'MANAGER_CONSULTATION_AND_SUPPORT',
  /** An appointment with a physician for medication management. */
  MedicationManagement = 'MEDICATION_MANAGEMENT',
  /** An appointment with a therapist, for a minor. */
  MinorTherapy = 'MINOR_THERAPY',
  /** An appointment with a peer recovery specialist. */
  PeerRecovery = 'PEER_RECOVERY',
  /** An appointment with a Spring Health specialty care navigator. */
  SpecialtyCareNavigation = 'SPECIALTY_CARE_NAVIGATION',
  /** An appointment with a therapist. */
  Therapy = 'THERAPY',
  /** An appointment with a WMPHC focused on training and awareness. */
  TrainingAndAwarenessEventConsultation = 'TRAINING_AND_AWARENESS_EVENT_CONSULTATION',
  /** An appointment with a CCA to discuss wellness and support opportunities. */
  WellnessAndSocialSupportSession = 'WELLNESS_AND_SOCIAL_SUPPORT_SESSION',
}

export type RescheduleAppointmentInput = {
  campaign?: InputMaybe<CampaignInput>;
  id: Scalars['ID'];
  /** Skips sending notifications when true */
  no_email?: InputMaybe<Scalars['Boolean']>;
  reason_for_scheduling?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['DateTime']>;
};

/** Return from a RescheduleAppointment mutation */
export type RescheduleAppointmentOutput = {
  __typename?: 'RescheduleAppointmentOutput';
  appointment: Appointment;
  success: Scalars['Boolean'];
};

export type ResendSignupInvitationOutput = {
  __typename?: 'ResendSignupInvitationOutput';
  success: Scalars['Boolean'];
};

export type ResendUserConfirmationInput = {
  confirm_success_url?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type ResendUserConfirmationOutput = {
  __typename?: 'ResendUserConfirmationOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** SUD benefit information for a member */
export type SudBenefit = {
  __typename?: 'SUDBenefit';
  enabled: Scalars['Boolean'];
  initial_sud_risk?: Maybe<Scalars['String']>;
  latest_sud_risk?: Maybe<Scalars['String']>;
  program_info?: Maybe<SudProgram>;
  recommended?: Maybe<Scalars['Boolean']>;
  specialized_cn_enabled?: Maybe<Scalars['Boolean']>;
};

/** Member SUD Program */
export type SudProgram = {
  __typename?: 'SUDProgram';
  appointments?: Maybe<Array<Appointment>>;
  care_navigator?: Maybe<CareProvider>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
  zendesk_url?: Maybe<Scalars['String']>;
};

/** A SUD care provider */
export type SudProvider = {
  __typename?: 'SUDProvider';
  accepted_client_types: Array<Scalars['String']>;
  acuity_account?: Maybe<AcuityAccount>;
  acuity_calendar_id?: Maybe<Scalars['String']>;
  acuity_calendar_sync_enabled?: Maybe<Scalars['Boolean']>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  assigned_supervisors?: Maybe<Array<CareProvider>>;
  associate_status?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_cdn_url?: Maybe<Scalars['String']>;
  away_message?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  care_navigator_customer_assignment?: Maybe<CareNavigatorCustomerAssignment>;
  care_provider_tags?: Maybe<Array<CareProviderTag>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  cn_caseload?: Maybe<Scalars['Int']>;
  cohorts?: Maybe<Array<Scalars['ID']>>;
  coordinates?: Maybe<Coordinate>;
  country?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  created_with_in_house_availability: Scalars['Boolean'];
  customer_role_name?: Maybe<Scalars['String']>;
  customer_sites: Array<CustomerSite>;
  dedicated?: Maybe<Scalars['Boolean']>;
  dedicated_customer?: Maybe<Customer>;
  distance_kilometers?: Maybe<Scalars['Float']>;
  /** @deprecated use `distance_kilometers` */
  distance_meters?: Maybe<Scalars['Float']>;
  distance_miles?: Maybe<Scalars['Float']>;
  email: Scalars['String'];
  /** @deprecated Use `care_provider_tags` instead. */
  ethnicities?: Maybe<Array<Maybe<Scalars['String']>>>;
  external_calendar_sync_enabled?: Maybe<Scalars['Boolean']>;
  first_name: Scalars['String'];
  /** @deprecated Use `care_provider_tags` instead. */
  gender?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  in_house_availability_source_of_truth_at?: Maybe<Scalars['DateTime']>;
  in_person_supported?: Maybe<Scalars['Boolean']>;
  instructions?: Maybe<Scalars['String']>;
  is_private_practice: Scalars['Boolean'];
  /** @deprecated Use `care_provider_tags` instead. */
  languages?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_name?: Maybe<Scalars['String']>;
  license_number?: Maybe<Scalars['String']>;
  licensed_countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  licenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  members: Array<Maybe<PaginatedMembers>>;
  name?: Maybe<Scalars['String']>;
  network_status: NetworkStatusEnumType;
  network_status_changes?: Maybe<Array<Version>>;
  on_site?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer used */
  on_site_instructions?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  personalized_provider_match_description?: Maybe<PersonalizedProviderBioMatchDescription>;
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  scheduling_constraints?: Maybe<SchedulingConstraintType>;
  scheduling_setting?: Maybe<SchedulingSettingType>;
  scheduling_url?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: Scalars['String'];
  street_address_1?: Maybe<Scalars['String']>;
  street_address_2?: Maybe<Scalars['String']>;
  supervisor_status?: Maybe<Scalars['String']>;
  supported_appointment_kinds: Array<Maybe<RequestableAppointmentKindEnum>>;
  supported_appointment_mediums: Array<Maybe<AppointmentMediumEnum>>;
  synced_with_apero_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `care_provider_tags` instead. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  time_zone?: Maybe<Scalars['String']>;
  top_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  user: User;
  user_id?: Maybe<Scalars['String']>;
  virtual_supported: Scalars['Boolean'];
  zip_code?: Maybe<Scalars['String']>;
  zoom_url?: Maybe<Scalars['String']>;
};

/** A scheduling access of a contract term */
export type SchedulingAccess = {
  __typename?: 'SchedulingAccess';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Provider scheduling constraints */
export type SchedulingConstraintType = {
  __typename?: 'SchedulingConstraintType';
  appointment_start_time_setting: AppointmentStartTimeSetting;
  care_provider: CareProvider;
  id: Scalars['ID'];
  max_appts_day?: Maybe<Scalars['Int']>;
  max_intake_appts_per_week?: Maybe<Scalars['Int']>;
  max_lead_time: Scalars['Int'];
  min_lead_time: Scalars['Int'];
};

export type SchedulingConstraintsFieldsInput = {
  max_appts_day?: InputMaybe<Scalars['Int']>;
  max_intake_appts_per_week?: InputMaybe<Scalars['Int']>;
  max_lead_time?: InputMaybe<Scalars['Int']>;
  min_lead_time?: InputMaybe<Scalars['Int']>;
};

export type SchedulingConstraintsInput = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  patch: SchedulingConstraintsFieldsInput;
};

export type SchedulingConstraintsInputType = {
  appointment_start_time_setting?: InputMaybe<AppointmentStartTimeSetting>;
  max_appts_day?: InputMaybe<Scalars['Int']>;
  max_intake_appts_per_week?: InputMaybe<Scalars['Int']>;
  max_lead_time?: InputMaybe<Scalars['Int']>;
  min_lead_time?: InputMaybe<Scalars['Int']>;
};

export type SchedulingConstraintsOutput = {
  __typename?: 'SchedulingConstraintsOutput';
  scheduling_constraints: SchedulingConstraintType;
  success?: Maybe<Scalars['Boolean']>;
};

/** All scheduling constraints */
export type SchedulingConstraintsType = {
  __typename?: 'SchedulingConstraintsType';
  data: SchedulingConstraintType;
};

export type SchedulingSettingFieldsInput = {
  max_appts_per_day?: InputMaybe<Scalars['Int']>;
  max_hours_lead_time?: InputMaybe<Scalars['Int']>;
  max_intake_appts_per_week?: InputMaybe<Scalars['Int']>;
  min_hours_lead_time?: InputMaybe<Scalars['Int']>;
};

export type SchedulingSettingInput = {
  care_provider_id?: InputMaybe<Scalars['ID']>;
  patch: SchedulingSettingFieldsInput;
};

export type SchedulingSettingInputType = {
  appointment_start_time?: InputMaybe<AppointmentStartTimeSetting>;
  max_appts_per_day?: InputMaybe<Scalars['Int']>;
  max_hours_lead_time?: InputMaybe<Scalars['Int']>;
  max_intake_appts_per_week?: InputMaybe<Scalars['Int']>;
  min_hours_lead_time?: InputMaybe<Scalars['Int']>;
};

export type SchedulingSettingOutput = {
  __typename?: 'SchedulingSettingOutput';
  scheduling_setting: SchedulingSettingType;
  success?: Maybe<Scalars['Boolean']>;
};

/** Provider scheduling setting */
export type SchedulingSettingType = {
  __typename?: 'SchedulingSettingType';
  appointment_start_time: AppointmentStartTimeSetting;
  care_provider: CareProvider;
  id: Scalars['ID'];
  max_appts_per_day?: Maybe<Scalars['Int']>;
  max_hours_lead_time: Scalars['Int'];
  max_intake_appts_per_week?: Maybe<Scalars['Int']>;
  min_hours_lead_time: Scalars['Int'];
};

/** All scheduling settings */
export type SchedulingSettingsType = {
  __typename?: 'SchedulingSettingsType';
  data: SchedulingSettingType;
};

export type SendAppointmentConfirmationEmailOutputType = {
  __typename?: 'SendAppointmentConfirmationEmailOutputType';
  success: Scalars['Boolean'];
};

export type SendDownloadAppEmailOutputType = {
  __typename?: 'SendDownloadAppEmailOutputType';
  success: Scalars['Boolean'];
};

export type SendSignupInvitationsOutput = {
  __typename?: 'SendSignupInvitationsOutput';
  pending_count: Scalars['Int'];
  success: Scalars['Boolean'];
};

/** Return from a SessionAdmitParticipant query */
export type SessionAdmitParticipantStatusOutput = {
  __typename?: 'SessionAdmitParticipantStatusOutput';
  message?: Maybe<Scalars['String']>;
};

/** Return from a SessionCheckIn query */
export type SessionCheckInOutput = {
  __typename?: 'SessionCheckInOutput';
  /** @deprecated Not used */
  customer_id?: Maybe<Scalars['String']>;
  /** @deprecated Not used */
  customer_name?: Maybe<Scalars['String']>;
  join_window_end?: Maybe<Scalars['DateTime']>;
  join_window_start?: Maybe<Scalars['DateTime']>;
  jwt?: Maybe<Scalars['String']>;
  /** @deprecated Not used */
  kind?: Maybe<Scalars['String']>;
  livekit_url?: Maybe<Scalars['String']>;
  /** @deprecated Not used */
  member_id?: Maybe<Scalars['String']>;
  member_name?: Maybe<Scalars['String']>;
  participant_name?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  sdk_client_id?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['DateTime']>;
  virtual_session_type?: Maybe<Scalars['String']>;
  zoom_meeting_number?: Maybe<Scalars['String']>;
  zoom_meeting_password?: Maybe<Scalars['String']>;
};

export type SetDefaultCohortInput = {
  id: Scalars['ID'];
};

/** An application wide settting */
export type Setting = {
  __typename?: 'Setting';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['JSON'];
};

/** Input fields for registering a user */
export type SignupEmployerFieldsInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customer_id: Scalars['ID'];
  date_of_birth?: InputMaybe<Scalars['Date']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  privacy_policy: Scalars['Boolean'];
  state?: InputMaybe<Scalars['String']>;
  terms_of_service: Scalars['Boolean'];
  zip_code?: InputMaybe<Scalars['String']>;
};

/** Input for registering a user */
export type SignupEmployerInput = {
  post?: InputMaybe<SignupEmployerFieldsInput>;
};

/** Response from a SignupEmployer mutation */
export type SignupEmployerOutput = {
  __typename?: 'SignupEmployerOutput';
  employer?: Maybe<User>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SignupMemberFieldsInput = {
  campaign?: InputMaybe<CampaignInput>;
  check_in_frequency_days?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  cohort_id?: InputMaybe<Scalars['ID']>;
  confirm_success_url?: InputMaybe<Scalars['String']>;
  coppa_notice?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  covered_life_id?: InputMaybe<Scalars['ID']>;
  date_of_birth: Scalars['Date'];
  electronic_comm_consent?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['TrimWhitespace'];
  /** @deprecated Census required */
  employment_verification?: InputMaybe<Scalars['String']>;
  external_user_id?: InputMaybe<Scalars['String']>;
  first_name: Scalars['TrimWhitespace'];
  guardian_account?: InputMaybe<Scalars['Boolean']>;
  health_info_consent?: InputMaybe<Scalars['Boolean']>;
  hipaa_privacy_policy?: InputMaybe<Scalars['Boolean']>;
  informed_consent?: InputMaybe<Scalars['Boolean']>;
  invite_token?: InputMaybe<Scalars['String']>;
  last_name: Scalars['TrimWhitespace'];
  manager_id?: InputMaybe<Scalars['ID']>;
  optimism?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
  password_confirmation?: InputMaybe<Scalars['String']>;
  /** @deprecated Census required */
  personal_identification?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
  privacy_policy: Scalars['Boolean'];
  pronouns?: InputMaybe<Scalars['String']>;
  proposed_grantor_email?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  relay_token?: InputMaybe<Scalars['String']>;
  signup_source?: InputMaybe<Scalars['String']>;
  sms_opt_in?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  terms_of_service: Scalars['Boolean'];
  zip_code?: InputMaybe<Scalars['String']>;
};

export type SignupMemberInput = {
  put?: InputMaybe<SignupMemberFieldsInput>;
};

/** Return from a SignupMember mutation with member (if admin) */
export type SignupMemberOutput = {
  __typename?: 'SignupMemberOutput';
  member?: Maybe<Member>;
  ready_for_care?: Maybe<Scalars['Boolean']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SkipCarePlanProgressStepOutput = {
  __typename?: 'SkipCarePlanProgressStepOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** a sort direction */
export type SortType = {
  column?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
};

/** Spring Health benefit information for a member */
export type SpringBenefit = {
  __typename?: 'SpringBenefit';
  member_benefits?: Maybe<Array<Scalars['String']>>;
};

export type StartAssessmentInput = {
  id: Scalars['ID'];
};

/** Return from a StartAssessment mutation */
export type StartAssessmentOutput = {
  __typename?: 'StartAssessmentOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

/** State Info */
export type StateInfo = {
  __typename?: 'StateInfo';
  code: Scalars['String'];
  name: Scalars['String'];
};

/** Statement pdf file for a member */
export type Statement = {
  __typename?: 'Statement';
  file_name?: Maybe<Scalars['String']>;
  pdf_file?: Maybe<Scalars['String']>;
};

/** Type of statement document type that can exist */
export enum StatementEnumType {
  AccountHistory = 'ACCOUNT_HISTORY',
  LatestSummary = 'LATEST_SUMMARY',
}

/** The status of invoices type that can exist */
export enum StatusEnumType {
  ActionRequired = 'ACTION_REQUIRED',
  All = 'ALL',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type Step = {
  __typename?: 'Step';
  audio_url?: Maybe<Scalars['String']>;
  caption_url?: Maybe<Scalars['String']>;
  content_format: Scalars['String'];
  elements: Array<Element>;
  exercise_id: Scalars['ID'];
  final_step: Scalars['Boolean'];
  id: Scalars['ID'];
  position: Scalars['Int'];
  rich_text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  video_url?: Maybe<Scalars['String']>;
};

export type StepInput = {
  audio_url?: InputMaybe<Scalars['String']>;
  content_format?: InputMaybe<Scalars['String']>;
  exercise_id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Int']>;
  rich_text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  video_url?: InputMaybe<Scalars['String']>;
};

export type SubmitResponsesOutput = {
  __typename?: 'SubmitResponsesOutput';
  assessment: Assessment;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  channel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** An Iterable message type */
export type SubscriptionMessage = {
  __typename?: 'SubscriptionMessage';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
};

/** A list of user subscriptions */
export type Subscriptions = {
  __typename?: 'Subscriptions';
  appointment_reminders_email: Subscription;
  appointment_reminders_push?: Maybe<Subscription>;
  channels?: Maybe<Array<Maybe<Channel>>>;
  marketing_channel_email: Subscription;
  marketing_channel_push?: Maybe<Subscription>;
  message_types?: Maybe<Array<Maybe<SubscriptionMessage>>>;
  news_and_announcements_email: Subscription;
  news_and_announcements_push?: Maybe<Subscription>;
  progress_checkins_email: Subscription;
  progress_checkins_push?: Maybe<Subscription>;
};

/** Return from a CreateEvaluation mutation */
export type SupportedLanguagesOutputType = {
  __typename?: 'SupportedLanguagesOutputType';
  languages?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SurveyInputType = {
  appointment_id: Scalars['ID'];
  is_response_shareable: Scalars['Boolean'];
  name: Scalars['String'];
  preferred_language: Scalars['String'];
  questions: Array<QuestionInputType>;
  timestamp: Scalars['String'];
  version: Scalars['String'];
};

/** Result of Survey APIs */
export type SurveyOutputType = {
  __typename?: 'SurveyOutputType';
  msg?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** return generated temporary password string */
export type TemporaryPassword = {
  __typename?: 'TemporaryPassword';
  temporary_password?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TherapeuticAllianceSurvey. */
export type TherapeuticAllianceSurveyPayload = {
  __typename?: 'TherapeuticAllianceSurveyPayload';
  msg?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Therapy benefit information for a member */
export type TherapyBenefit = {
  __typename?: 'TherapyBenefit';
  delete_me?: Maybe<Scalars['Boolean']>;
};

/** A therapy style option */
export type TherapyStyleOption = {
  __typename?: 'TherapyStyleOption';
  component?: Maybe<Scalars['String']>;
  constraints?: Maybe<Scalars['JSON']>;
  key?: Maybe<Scalars['String']>;
  left_label?: Maybe<Scalars['String']>;
  mid_label?: Maybe<Scalars['String']>;
  right_label?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** A single data point in a time series based on an integer */
export type TimeSeriesIntegerDataPoint = {
  __typename?: 'TimeSeriesIntegerDataPoint';
  date: Scalars['DateTime'];
  value: Scalars['Int'];
};

/** A todo for a member */
export type Todo = {
  __typename?: 'Todo';
  data: Scalars['JSON'];
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: Scalars['String'];
  title: Scalars['String'];
};

export type ToggleAccessOutput = {
  __typename?: 'ToggleAccessOutput';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type TransferMembershipOutput = {
  __typename?: 'TransferMembershipOutput';
  member: Member;
  success?: Maybe<Scalars['Boolean']>;
};

/** A trial */
export type Trial = {
  __typename?: 'Trial';
  child_dependents_allowed: Scalars['Boolean'];
  coaching_supported: Scalars['Boolean'];
  cohort_name?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
  create_passwords: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  customer_id?: Maybe<Scalars['String']>;
  dependents_allowed: Scalars['Boolean'];
  end_date: Scalars['Date'];
  id: Scalars['ID'];
  in_person_supported: Scalars['Boolean'];
  moments_supported: Scalars['Boolean'];
  start_date: Scalars['Date'];
  status: Scalars['String'];
  updated_at: Scalars['DateTime'];
  user_count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<TrialUserOutput>>;
};

export type TrialUserInput = {
  corporate_email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type TrialUserOutput = {
  __typename?: 'TrialUserOutput';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
};

export type UnattendanceReasonInput = {
  id: Scalars['ID'];
  member_id: Scalars['ID'];
  /** Reason the appointment was missed or late cancelled */
  unattendance_reason: Scalars['String'];
};

/** Minimal fields returned for loginless unattendance reason updates. */
export type UnattendanceReasonOutput = {
  __typename?: 'UnattendanceReasonOutput';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
};

export type UpdateAppointmentBillingStatusOutput = {
  __typename?: 'UpdateAppointmentBillingStatusOutput';
  appointment: Appointment;
  success: Scalars['Boolean'];
};

export type UpdateAppointmentFieldsInput = {
  /** Marks shows and no shows by any attendees */
  attended?: InputMaybe<Scalars['String']>;
  /** where the attended information is coming from */
  attended_source?: InputMaybe<AppointmentAttendedSourceEnumType>;
  /** The reason this appointment was cancelled */
  cancellation_reason?: InputMaybe<Scalars['String']>;
  /** Additional text for appointment cancellation */
  cancellation_text?: InputMaybe<Scalars['String']>;
  /** Indicates the medium of an appointment */
  medium?: InputMaybe<AppointmentMediumEnum>;
  /** Skips sending notifications when true */
  no_email?: InputMaybe<Scalars['Boolean']>;
  /** Notes if the provider was paid for this visit */
  paid?: InputMaybe<Scalars['String']>;
  /** The start time/date of an appointment */
  start_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateAppointmentInput = {
  id: Scalars['ID'];
  patch: UpdateAppointmentFieldsInput;
};

export type UpdateAppointmentOutput = {
  __typename?: 'UpdateAppointmentOutput';
  appointment: Appointment;
  success: Scalars['Boolean'];
};

export type UpdateAssessmentFieldsInput = {
  questionnaires_order?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  triaged?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateAssessmentInput = {
  id: Scalars['ID'];
  patch: UpdateAssessmentFieldsInput;
};

export type UpdateAssessmentOutput = {
  __typename?: 'UpdateAssessmentOutput';
  assessment: Assessment;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCareNavigatorCustomerAssignmentFieldsInput = {
  assignment_type?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['String']>;
};

export type UpdateCareProviderAvailabilityEventFieldsInput = {
  end_at?: InputMaybe<Scalars['DateTime']>;
  for_in_person?: InputMaybe<Scalars['Boolean']>;
  for_phone?: InputMaybe<Scalars['Boolean']>;
  for_video?: InputMaybe<Scalars['Boolean']>;
  medium?: InputMaybe<Scalars['String']>;
  send_away_messages?: InputMaybe<Scalars['Boolean']>;
  start_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCareProviderAvailabilityEventInput = {
  care_provider_id: Scalars['ID'];
  id: Scalars['ID'];
  patch: UpdateCareProviderAvailabilityEventFieldsInput;
};

export type UpdateCareProviderAvailabilityEventOutput = {
  __typename?: 'UpdateCareProviderAvailabilityEventOutput';
  care_provider_availability_event: CareProviderAvailabilityEvent;
  success: Scalars['Boolean'];
};

export type UpdateCareProviderFieldsInput = {
  accepted_client_types?: InputMaybe<Array<Scalars['String']>>;
  acuity_account?: InputMaybe<Scalars['String']>;
  acuity_calendar_id?: InputMaybe<Scalars['String']>;
  acuity_calendar_sync_enabled?: InputMaybe<Scalars['Boolean']>;
  avatar?: InputMaybe<Scalars['DataURL']>;
  away_message?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  bio?: InputMaybe<Scalars['String']>;
  care_navigator_customer_assignment?: InputMaybe<UpdateCareNavigatorCustomerAssignmentFieldsInput>;
  care_provider_tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  cohorts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country?: InputMaybe<Scalars['String']>;
  customer_sites?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first_name?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  gender?: InputMaybe<Scalars['String']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last_name?: InputMaybe<Scalars['String']>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  network_status?: InputMaybe<NetworkStatusEnumType>;
  on_site?: InputMaybe<Scalars['Boolean']>;
  on_site_instructions?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scheduling_constraints?: InputMaybe<SchedulingConstraintsFieldsInput>;
  scheduling_setting?: InputMaybe<SchedulingSettingFieldsInput>;
  scheduling_url?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time_zone?: InputMaybe<Scalars['String']>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
  zoom_url?: InputMaybe<Scalars['String']>;
};

export type UpdateCareProviderInput = {
  id: Scalars['ID'];
  patch: UpdateCareProviderFieldsInput;
};

export type UpdateCareProviderOutput = {
  __typename?: 'UpdateCareProviderOutput';
  care_provider: CareProvider;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCareProviderRecurringAvailabilityBlocksFieldsInput = {
  customer_site_id?: InputMaybe<Scalars['ID']>;
  end_at_minute: Scalars['Int'];
  for_in_person?: InputMaybe<Scalars['Boolean']>;
  for_phone?: InputMaybe<Scalars['Boolean']>;
  for_video?: InputMaybe<Scalars['Boolean']>;
  medium: Scalars['String'];
  start_at_minute: Scalars['Int'];
};

export type UpdateCareProviderRecurringAvailabilityBlocksInput = {
  care_provider_id: Scalars['ID'];
  care_provider_recurring_availability_blocks: Array<UpdateCareProviderRecurringAvailabilityBlocksFieldsInput>;
};

export type UpdateCareProviderRecurringAvailabilityBlocksOutput = {
  __typename?: 'UpdateCareProviderRecurringAvailabilityBlocksOutput';
  care_provider_recurring_availability_blocks: Array<Maybe<CareProviderRecurringAvailabilityBlock>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCareTeamFieldsInput = {
  care_navigator_id?: InputMaybe<Scalars['String']>;
  coach_id?: InputMaybe<Scalars['String']>;
  medication_manager_id?: InputMaybe<Scalars['String']>;
  peer_recovery_specialist_id?: InputMaybe<Scalars['String']>;
  therapist_id?: InputMaybe<Scalars['String']>;
};

export type UpdateCareTeamInput = {
  id: Scalars['ID'];
  patch: UpdateCareTeamFieldsInput;
};

export type UpdateCareTeamOutput = {
  __typename?: 'UpdateCareTeamOutput';
  care_team: CareTeam;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for updating a channel partner */
export type UpdateChannelPartnerFieldsInput = {
  active_at?: InputMaybe<Scalars['DateTime']>;
  channel_partner_sub_type?: InputMaybe<ChannelPartnerSubTypeEnumType>;
  channel_partner_type?: InputMaybe<ChannelPartnerTypeEnumType>;
  contract_end_at?: InputMaybe<Scalars['DateTime']>;
  customer_ids?: InputMaybe<Array<Scalars['ID']>>;
  landing_page_url?: InputMaybe<Scalars['String']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  sso_client_slug?: InputMaybe<Scalars['String']>;
};

/** Input for updating a channel partner */
export type UpdateChannelPartnerInput = {
  id: Scalars['ID'];
  patch: UpdateChannelPartnerFieldsInput;
};

export type UpdateChannelPartnerOutput = {
  __typename?: 'UpdateChannelPartnerOutput';
  channel_partner: ChannelPartner;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCohortEmailCustomizationInput = {
  body?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  intro?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
};

export type UpdateCohortEmailCustomizationOutput = {
  __typename?: 'UpdateCohortEmailCustomizationOutput';
  cohort_email_customization: CohortEmailCustomization;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCohortFieldsInput = {
  administration_notes?: InputMaybe<Scalars['String']>;
  can_access_provider?: InputMaybe<Scalars['Boolean']>;
  census_outreach_enabled?: InputMaybe<Scalars['Boolean']>;
  cohort_eap_link?: InputMaybe<Scalars['String']>;
  cohort_eap_text?: InputMaybe<Scalars['String']>;
  contract_term_id?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['String']>;
  crisis_support_number?: InputMaybe<Scalars['String']>;
  deactivation_grace_period?: InputMaybe<DeactivationGracePeriodInput>;
  default_care_navigator_id?: InputMaybe<Scalars['String']>;
  default_language?: InputMaybe<Scalars['String']>;
  dependent_cohort_id?: InputMaybe<Scalars['ID']>;
  eap_access_code_text?: InputMaybe<Scalars['String']>;
  eap_direct_dial_number?: InputMaybe<Scalars['String']>;
  eap_email?: InputMaybe<Scalars['String']>;
  eap_freephone_number?: InputMaybe<Scalars['String']>;
  eap_intro_text?: InputMaybe<Scalars['String']>;
  eap_sms_number?: InputMaybe<Scalars['String']>;
  general_support_number?: InputMaybe<Scalars['String']>;
  /** @deprecated Use scheduling_access to get the tier of a member */
  global_tier?: InputMaybe<Scalars['String']>;
  has_client_specific_network?: InputMaybe<Scalars['Boolean']>;
  international?: InputMaybe<Scalars['Boolean']>;
  member_termination_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  time_zone?: InputMaybe<Scalars['String']>;
};

export type UpdateCohortOutput = {
  __typename?: 'UpdateCohortOutput';
  cohort: Cohort;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCohortPlatformServiceFieldsInput = {
  cohort_id?: InputMaybe<Scalars['ID']>;
  max_rate?: InputMaybe<Scalars['Float']>;
  min_rate?: InputMaybe<Scalars['Float']>;
  modifiers?: InputMaybe<Scalars['JSON']>;
  platform_service_id?: InputMaybe<Scalars['ID']>;
};

export type UpdateCohortPlatformServiceInput = {
  id: Scalars['ID'];
  patch: UpdateCohortPlatformServiceFieldsInput;
};

export type UpdateCohortPlatformServiceOutput = {
  __typename?: 'UpdateCohortPlatformServiceOutput';
  cohort_platform_service: CohortPlatformService;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCompassUserFieldsInput = {
  accepted_client_types?: InputMaybe<Array<Scalars['String']>>;
  acuity_account?: InputMaybe<Scalars['String']>;
  acuity_calendar_id?: InputMaybe<Scalars['String']>;
  acuity_calendar_sync_enabled?: InputMaybe<Scalars['Boolean']>;
  admin_roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  associate_status?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<Scalars['DataURL']>;
  away_message?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  care_navigator_customer_assignment?: InputMaybe<CreateCareNavigatorCustomerAssignmentFieldsInput>;
  care_provider_tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Scalars['String']>;
  cohorts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  country?: InputMaybe<Scalars['String']>;
  customer_sites?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  ethnicities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first_name?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  gender?: InputMaybe<Scalars['String']>;
  hipaa_and_security_trained?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  instructions?: InputMaybe<Scalars['String']>;
  /** @deprecated Use care_provider_tags instead */
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last_name?: InputMaybe<Scalars['String']>;
  license_number?: InputMaybe<Scalars['String']>;
  licensed_countries?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  licenses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  network_status?: InputMaybe<NetworkStatusEnumType>;
  on_site?: InputMaybe<Scalars['Boolean']>;
  on_site_instructions?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  scheduling_constraints?: InputMaybe<SchedulingConstraintsInputType>;
  scheduling_setting?: InputMaybe<SchedulingSettingInputType>;
  scheduling_url?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  status?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  time_zone?: InputMaybe<Scalars['String']>;
  virtual_supported?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
  zoom_url?: InputMaybe<Scalars['String']>;
};

export type UpdateCompassUserInput = {
  id: Scalars['ID'];
  patch: UpdateCompassUserFieldsInput;
};

export type UpdateCompassUserOutput = {
  __typename?: 'UpdateCompassUserOutput';
  success?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
};

export type UpdateConnectedBenefitInput = {
  benefits_type?: InputMaybe<ConnectedBenefitEnumType>;
  default_description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logo_url?: InputMaybe<Scalars['DataURL']>;
  name?: InputMaybe<Scalars['String']>;
  vendor_logo_original_file_name?: InputMaybe<Scalars['String']>;
};

export type UpdateConnectedBenefitOutput = {
  __typename?: 'UpdateConnectedBenefitOutput';
  connected_benefit?: Maybe<ConnectedBenefit>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateContractTermFieldsInput = {
  basic_visits_covered?: InputMaybe<Scalars['Int']>;
  benefits_summary?: InputMaybe<Scalars['String']>;
  census_dependents_only?: InputMaybe<Scalars['Boolean']>;
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  coaching_support?: InputMaybe<Scalars['Boolean']>;
  community_care_solution?: InputMaybe<Scalars['Boolean']>;
  dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  moments_supported?: InputMaybe<Scalars['Boolean']>;
  sdoh_screener?: InputMaybe<Scalars['Boolean']>;
  specialist_visits_covered?: InputMaybe<Scalars['Int']>;
  sponsored_visits_covered?: InputMaybe<Scalars['Int']>;
  sponsored_visits_expires?: InputMaybe<Scalars['DateTime']>;
  sponsored_visits_start_at?: InputMaybe<Scalars['DateTime']>;
  visit_type?: InputMaybe<ContractTermVisitTypeEnumType>;
};

export type UpdateContractTermOutput = {
  __typename?: 'UpdateContractTermOutput';
  contract_term: ContractTerm;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCoveredLifeCohortOutput = {
  __typename?: 'UpdateCoveredLifeCohortOutput';
  covered_life_ids: Array<Scalars['ID']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateCustomerConnectedBenefitFields = {
  cohorts: Array<Scalars['String']>;
  connected_benefit_id: Scalars['ID'];
  connected_benefits_url?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  dependent_enabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerConnectedBenefitInput = {
  id: Scalars['ID'];
  patch: UpdateCustomerConnectedBenefitFields;
};

export type UpdateCustomerConnectedBenefitOutput = {
  __typename?: 'UpdateCustomerConnectedBenefitOutput';
  customer_connected_benefit?: Maybe<CustomerConnectedBenefit>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for updating a customer */
export type UpdateCustomerFieldsInput = {
  active_at?: InputMaybe<Scalars['DateTime']>;
  cancellation_policy_gated_booking_enabled?: InputMaybe<Scalars['Boolean']>;
  census_dob_format?: InputMaybe<Scalars['String']>;
  census_enabled?: InputMaybe<Scalars['Boolean']>;
  census_outreach_enabled?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Customers can have multiple channel partners, use channel_partner_ids instead. */
  channel_partner_id?: InputMaybe<Scalars['ID']>;
  channel_partner_ids?: InputMaybe<Array<Scalars['ID']>>;
  dependent_eligibility_tier?: InputMaybe<DependentEligibilityTierEnum>;
  domain?: InputMaybe<Scalars['String']>;
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  employment_id_kind?: InputMaybe<Scalars['String']>;
  enable_connected_benefits?: InputMaybe<Scalars['Boolean']>;
  enable_member_no_show_policy?: InputMaybe<Scalars['Boolean']>;
  has_monthly_awareness_campaign_enabled?: InputMaybe<Scalars['Boolean']>;
  in_network?: InputMaybe<Scalars['Boolean']>;
  inactive_at?: InputMaybe<Scalars['DateTime']>;
  include_in_comparative_analytics?: InputMaybe<Scalars['Boolean']>;
  international_dependents?: InputMaybe<Scalars['Int']>;
  international_employees?: InputMaybe<Scalars['Int']>;
  launched?: InputMaybe<Scalars['Boolean']>;
  marketing_name?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number_of_dependents?: InputMaybe<Scalars['Int']>;
  number_of_employees?: InputMaybe<Scalars['Int']>;
  personal_id_kind?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sso_client_slug?: InputMaybe<Scalars['String']>;
  sso_client_slugs?: InputMaybe<Array<Scalars['String']>>;
  sso_slugs?: InputMaybe<Array<Scalars['String']>>;
  trial?: InputMaybe<Scalars['Boolean']>;
  us_dependents?: InputMaybe<Scalars['Int']>;
  us_employees?: InputMaybe<Scalars['Int']>;
  wpo_dependents?: InputMaybe<Scalars['Int']>;
  wpo_employees?: InputMaybe<Scalars['Int']>;
};

/** Input for updating a customer */
export type UpdateCustomerInput = {
  id: Scalars['ID'];
  patch: UpdateCustomerFieldsInput;
};

export type UpdateCustomerOutput = {
  __typename?: 'UpdateCustomerOutput';
  customer: Customer;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input fields for updating a demo user covered life */
export type UpdateDemoUserCoveredLifeFieldsInput = {
  purpose?: InputMaybe<Scalars['String']>;
};

/** Input for updating a demo user covered life */
export type UpdateDemoUserCoveredLifeInput = {
  id: Scalars['ID'];
  patch: UpdateDemoUserCoveredLifeFieldsInput;
};

export type UpdateExerciseInput = {
  activity_type?: InputMaybe<Activity>;
  categories?: InputMaybe<Array<CategoryInput>>;
  duration?: InputMaybe<Scalars['String']>;
  emotions?: InputMaybe<Array<EmotionInput>>;
  images?: InputMaybe<Array<ImageInput>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateExerciseOutput = {
  __typename?: 'UpdateExerciseOutput';
  exercise?: Maybe<Exercise>;
  success: Scalars['Boolean'];
};

/** Input for updateExternalCalendarSync mutation */
export type UpdateExternalCalendarSyncInput = {
  additional_calendars?: InputMaybe<Array<GoogleCalendarInput>>;
  care_provider_id: Scalars['ID'];
  primary_calendar: GoogleCalendarInput;
};

/** Return from an updateExternalCalendarSync mutation */
export type UpdateExternalCalendarSyncOutput = {
  __typename?: 'UpdateExternalCalendarSyncOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberCarePreferenceFieldsInput = {
  days_of_week?: InputMaybe<Array<Scalars['String']>>;
  ethnicities?: InputMaybe<Array<Scalars['String']>>;
  genders?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  mediums?: InputMaybe<Array<Scalars['String']>>;
  preference_type?: InputMaybe<Array<Scalars['String']>>;
  specialties?: InputMaybe<Array<Scalars['String']>>;
  status?: InputMaybe<CarePreferenceStatusEnumType>;
  therapy_styles?: InputMaybe<Scalars['JSON']>;
  time_of_day?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateMemberCarePreferenceInput = {
  patch: UpdateMemberCarePreferenceFieldsInput;
};

export type UpdateMemberCarePreferenceOutput = {
  __typename?: 'UpdateMemberCarePreferenceOutput';
  care_preference: CarePreference;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberCohortOutput = {
  __typename?: 'UpdateMemberCohortOutput';
  member: Member;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberFieldsInput = {
  administration_notes?: InputMaybe<Scalars['String']>;
  check_in_frequency_days?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  cohort_id?: InputMaybe<Scalars['String']>;
  coppa_notice?: InputMaybe<Scalars['Boolean']>;
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  hipaa_privacy_policy?: InputMaybe<Scalars['Boolean']>;
  informed_consent?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  opt_in_personal?: InputMaybe<Scalars['Boolean']>;
  optimism?: InputMaybe<Scalars['Int']>;
  payment_preference?: InputMaybe<MemberPaymentPreferenceEnumType>;
  phone?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  preferred_name?: InputMaybe<Scalars['String']>;
  pronouns?: InputMaybe<Scalars['String']>;
  proposed_grantor_email?: InputMaybe<Scalars['String']>;
  push_opt_in?: InputMaybe<Scalars['Boolean']>;
  sms_opt_in?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  terminate_at?: InputMaybe<Scalars['DateTime']>;
  terminate_reason?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  workplace_manager?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type UpdateMemberGoalsInput = {
  goal_ids: Array<InputMaybe<Scalars['ID']>>;
};

export type UpdateMemberGoalsOutput = {
  __typename?: 'UpdateMemberGoalsOutput';
  member_goals: Array<Maybe<MemberGoal>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberInsurancePolicyOutputType = {
  __typename?: 'UpdateMemberInsurancePolicyOutputType';
  member_insurance_policy: MemberInsurancePolicy;
  success?: Maybe<Scalars['Boolean']>;
  zendesk_ticket_created?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberOutput = {
  __typename?: 'UpdateMemberOutput';
  member: Member;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateMemberPrevisitFieldsInput = {
  data: Scalars['JSON'];
  status: Scalars['String'];
};

export type UpdateMemberPrevisitInput = {
  member_id: Scalars['ID'];
  patch: UpdateMemberPrevisitFieldsInput;
};

export type UpdateMemberPrevisitOutput = {
  __typename?: 'UpdateMemberPrevisitOutput';
  member_previsit: MemberPrevisit;
  success?: Maybe<Scalars['Boolean']>;
};

/** consent fields from AgedOutRecord */
export type UpdateOutput = {
  __typename?: 'UpdateOutput';
  aged_out_record?: Maybe<AgedOutRecord>;
  /** @deprecated Use aged_out_record field */
  electronic_comm_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  health_info_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  hipaa_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  informed_consent_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  privacy_policy_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  terms_of_service_agreed_at?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use aged_out_record field */
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type UpdatePaymentMethodType = {
  existing_card_id: Scalars['String'];
  new_token_id: Scalars['String'];
};

export type UpdatePlanYearFieldsInput = {
  benefits_summary?: InputMaybe<Scalars['String']>;
  bundled_basic: Scalars['Int'];
  bundled_coaching: Scalars['Int'];
  bundled_specialist: Scalars['Int'];
  bundled_unlimited_coaching: Scalars['Boolean'];
  census_dependents_only?: InputMaybe<Scalars['Boolean']>;
  child_dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  coaching_support?: InputMaybe<Scalars['Boolean']>;
  cohort_id?: InputMaybe<Scalars['ID']>;
  community_care_solution?: InputMaybe<Scalars['Boolean']>;
  continued_coaching?: InputMaybe<Scalars['Boolean']>;
  customer_display_name?: InputMaybe<Scalars['String']>;
  dependents_allowed?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Use end_date instead */
  end_at?: InputMaybe<Scalars['DateTime']>;
  end_date?: InputMaybe<Scalars['ISO8601Date']>;
  in_person_med_management_supported?: InputMaybe<Scalars['Boolean']>;
  in_person_supported?: InputMaybe<Scalars['Boolean']>;
  member_cost_of_coaching?: InputMaybe<Scalars['Float']>;
  moments_supported?: InputMaybe<Scalars['Boolean']>;
  no_show_term_attributes?: InputMaybe<NoShowTermFieldsInput>;
  plan_name?: InputMaybe<Scalars['String']>;
  reason_summary?: InputMaybe<Scalars['String']>;
  scheduling_access: Scalars['String'];
  sdoh_screener?: InputMaybe<Scalars['Boolean']>;
  sponsored_basic: Scalars['Int'];
  sponsored_coaching: Scalars['Int'];
  /** @deprecated Use sponsored_end_date instead */
  sponsored_end_at?: InputMaybe<Scalars['DateTime']>;
  sponsored_end_date?: InputMaybe<Scalars['ISO8601Date']>;
  sponsored_specialist: Scalars['Int'];
  /** @deprecated Use sponsored_start_date instead */
  sponsored_start_at?: InputMaybe<Scalars['DateTime']>;
  sponsored_start_date?: InputMaybe<Scalars['ISO8601Date']>;
  sponsored_unlimited_basic: Scalars['Boolean'];
  sponsored_unlimited_coaching: Scalars['Boolean'];
  /** @deprecated Use start_date instead */
  start_at?: InputMaybe<Scalars['DateTime']>;
  start_date?: InputMaybe<Scalars['ISO8601Date']>;
  sud_supported?: InputMaybe<Scalars['Boolean']>;
  tobacco_cessation_purchased?: InputMaybe<Scalars['Boolean']>;
  tobacco_cessation_url?: InputMaybe<Scalars['String']>;
  virtual_session_cost_term_attributes?: InputMaybe<VirtualSessionCostTermCreateInputType>;
  work_life_supported?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePlatformServiceFieldsInput = {
  appointment_kind?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

export type UpdatePlatformServiceInput = {
  id: Scalars['ID'];
  patch: UpdatePlatformServiceFieldsInput;
};

export type UpdatePlatformServiceOutput = {
  __typename?: 'UpdatePlatformServiceOutput';
  platform_service: PlatformService;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateQuestionnaireFieldsInput = {
  responses: Scalars['JSON'];
};

export type UpdateQuestionnaireInput = {
  id: Scalars['ID'];
  patch: UpdateQuestionnaireFieldsInput;
};

export type UpdateQuestionnaireOutput = {
  __typename?: 'UpdateQuestionnaireOutput';
  questionnaire: Questionnaire;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for updating a Referral */
export type UpdateReferralInput = {
  care_status?: InputMaybe<ReferralCareStatusEnum>;
  referral_id: Scalars['ID'];
};

/** Output from referral update */
export type UpdateReferralOutput = {
  __typename?: 'UpdateReferralOutput';
  referral: Referral;
  success?: Maybe<Scalars['Boolean']>;
};

/** Input for updating a reporting integration */
export type UpdateReportingIntegrationInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  aws_handle?: InputMaybe<Scalars['String']>;
  customer_id?: InputMaybe<Scalars['ID']>;
  delimiter?: InputMaybe<Scalars['String']>;
  delivery_method?: InputMaybe<Scalars['String']>;
  delta_only?: InputMaybe<Scalars['Boolean']>;
  file_content_type?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  frequency?: InputMaybe<Scalars['String']>;
  header_casing?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  include_record_count?: InputMaybe<Scalars['Boolean']>;
  integration_type?: InputMaybe<Scalars['String']>;
  monthly_reporting_date?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  partner_customization?: InputMaybe<Scalars['String']>;
  partner_customization_options?: InputMaybe<Scalars['JSON']>;
  pgp_encrypted_file?: InputMaybe<Scalars['Boolean']>;
  prepend_count_label?: InputMaybe<Scalars['String']>;
  report_type?: InputMaybe<Scalars['String']>;
  reporting_integration_partner_id?: InputMaybe<Scalars['ID']>;
  row_terminator?: InputMaybe<Scalars['String']>;
  scheduled_to_run_at?: InputMaybe<Scalars['DateTime']>;
  snowflake_query_statement?: InputMaybe<Scalars['String']>;
};

export type UpdateReportingIntegrationOutput = {
  __typename?: 'UpdateReportingIntegrationOutput';
  errors?: Maybe<Array<Scalars['String']>>;
  reportingIntegration?: Maybe<ReportingIntegration>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateSettingFieldsInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateSettingInput = {
  id: Scalars['ID'];
  patch: UpdateSettingFieldsInput;
};

export type UpdateSettingOutput = {
  __typename?: 'UpdateSettingOutput';
  setting: Setting;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateStepInput = {
  audio_url?: InputMaybe<Scalars['String']>;
  caption_url?: InputMaybe<Scalars['String']>;
  content_format?: InputMaybe<Scalars['String']>;
  exercise_id?: InputMaybe<Scalars['ID']>;
  position?: InputMaybe<Scalars['Int']>;
  rich_text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video_url?: InputMaybe<Scalars['String']>;
};

export type UpdateStepOutput = {
  __typename?: 'UpdateStepOutput';
  step: Step;
  success: Scalars['Boolean'];
};

/** Input for updating a trial */
export type UpdateTrialInput = {
  create_passwords?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  new_trial_users?: InputMaybe<Array<TrialUserInput>>;
};

export type UpdateTrialOutput = {
  __typename?: 'UpdateTrialOutput';
  success?: Maybe<Scalars['Boolean']>;
  trial?: Maybe<Trial>;
};

export type UpdateUserFieldsInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  current_password?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['Date']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  password_confirmation?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  preferred_language?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street_address_1?: InputMaybe<Scalars['String']>;
  street_address_2?: InputMaybe<Scalars['String']>;
  time_zone?: InputMaybe<Scalars['String']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  patch: UpdateUserFieldsInput;
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  success?: Maybe<Scalars['Boolean']>;
  user: User;
};

export type UploadDocumentInputType = {
  file_name: Scalars['String'];
  id: Scalars['ID'];
  member_id: Scalars['String'];
  object_key: Scalars['String'];
};

/** Details around uploading insurance card images for a member */
export type UploadDocumentOutput = {
  __typename?: 'UploadDocumentOutput';
  document_id?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** A user */
export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  admin: Scalars['Boolean'];
  age?: Maybe<Scalars['Int']>;
  associated_admin?: Maybe<Admin>;
  avatar?: Maybe<Scalars['String']>;
  calendar?: Maybe<Calendar>;
  care_provider?: Maybe<CareProvider>;
  /** @deprecated User confirmation is not handled in rotom anymore */
  confirmed: Scalars['Boolean'];
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  date_of_birth?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  hipaa_and_security_trained?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  member?: Maybe<Member>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<UserRoleEnumType>>>;
  staff: Scalars['Boolean'];
  subscriptions?: Maybe<Subscriptions>;
  time_zone?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  zendesk_jwt?: Maybe<Scalars['String']>;
  zendesk_sso_help_center_jwt?: Maybe<Scalars['String']>;
};

/** The roles that users can have */
export enum UserRoleEnumType {
  /** An administrator */
  Admin = 'ADMIN',
  /** An atlas administrator */
  AtlasAdmin = 'ATLAS_ADMIN',
  /** An atlas user */
  AtlasUser = 'ATLAS_USER',
  /** A provider (e.g. a CN, therapist, or psychiatrist) */
  CareProvider = 'CARE_PROVIDER',
  /** A CDX administrator */
  CdxReportingAdmin = 'CDX_REPORTING_ADMIN',
  /** A compass administrator */
  CompassAdmin = 'COMPASS_ADMIN',
  /** A compass billing specialist */
  CompassBillingSpecialist = 'COMPASS_BILLING_SPECIALIST',
  /** A compass community care advocate */
  CompassCareAdvocate = 'COMPASS_CARE_ADVOCATE',
  /** A compass care consultant */
  CompassCareConsultant = 'COMPASS_CARE_CONSULTANT',
  /** A compass care navigator */
  CompassCareNavigator = 'COMPASS_CARE_NAVIGATOR',
  /** A compass care support coordinator */
  CompassCareSupportCoordinator = 'COMPASS_CARE_SUPPORT_COORDINATOR',
  /** A compass clinical manager */
  CompassClinicalManager = 'COMPASS_CLINICAL_MANAGER',
  /** A compass clinical support */
  CompassClinicalSupport = 'COMPASS_CLINICAL_SUPPORT',
  /** A compass coach */
  CompassCoach = 'COMPASS_COACH',
  /** A compass employee centered consultant */
  CompassEmployeeConsultant = 'COMPASS_EMPLOYEE_CONSULTANT',
  /** A compass group practice admin */
  CompassGroupPracticeAdmin = 'COMPASS_GROUP_PRACTICE_ADMIN',
  /** A compass manager centered consultant */
  CompassManagerConsultant = 'COMPASS_MANAGER_CONSULTANT',
  /** A compass medication manager */
  CompassMedManager = 'COMPASS_MED_MANAGER',
  /** A compass peer recovery specialist */
  CompassPeerRecoverySpecialist = 'COMPASS_PEER_RECOVERY_SPECIALIST',
  /** A compass specialty care navigator */
  CompassSpecialtyCareNavigator = 'COMPASS_SPECIALTY_CARE_NAVIGATOR',
  /** A compass administrator that can create compass administrators */
  CompassSuperAdmin = 'COMPASS_SUPER_ADMIN',
  /** A compass therapist */
  CompassTherapist = 'COMPASS_THERAPIST',
  /** A customer designated person */
  Employer = 'EMPLOYER',
  /** A Spring Health Engineer */
  Engineer = 'ENGINEER',
  /** A medical biller */
  MedicalBiller = 'MEDICAL_BILLER',
  /** A member who uses Spring */
  Member = 'MEMBER',
  /** A moments-only non-member user */
  MomentsOnly = 'MOMENTS_ONLY',
  /** A Spring Health sales user */
  Sales = 'SALES',
  /** An internal personnel */
  Staff = 'STAFF',
  /** An administrator that can create admin users */
  SuperAdmin = 'SUPER_ADMIN',
}

export type UserSubscriptionOutput = {
  __typename?: 'UserSubscriptionOutput';
  subscriptions: Subscriptions;
  success: Scalars['Boolean'];
};

/** A version of a record tracked through paper trail gem */
export type Version = {
  __typename?: 'Version';
  created_at: Scalars['DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  item_id: Scalars['ID'];
  item_type: Scalars['String'];
  object_changes?: Maybe<Scalars['JSON']>;
  whodunnit?: Maybe<Scalars['String']>;
};

/** Virtual session cost term information attached to a given Contract Term */
export type VirtualSessionCostTerm = {
  __typename?: 'VirtualSessionCostTerm';
  customer_display_name?: Maybe<Scalars['String']>;
  enable_show: Scalars['Boolean'];
  id: Scalars['ID'];
  percentage_covered?: Maybe<Scalars['Int']>;
};

export type VirtualSessionCostTermCreateInputType = {
  /** Display virtual session cost term information */
  enable_show?: InputMaybe<Scalars['Boolean']>;
  /** Percentage of virtual session cost covered by customer */
  percentage_covered?: InputMaybe<Scalars['Int']>;
};

/** A member with each possible next appointment type's session information. */
export type WithNextAppointmentsSessionOutputType = {
  __typename?: 'WithNextAppointmentsSessionOutputType';
  member: Member;
  next_appointment: Array<WithSessionOutputType>;
};

/** An appointment type with its next session type. */
export type WithSessionOutputType = {
  __typename?: 'WithSessionOutputType';
  appointment_type: Scalars['String'];
  session_type: InternalAppointmentSessionEnumType;
};

export type WorkplaceCustomization = {
  __typename?: 'WorkplaceCustomization';
  critical_incident_email?: Maybe<Scalars['String']>;
  critical_incident_form_link?: Maybe<Scalars['String']>;
  critical_incident_phone_extension?: Maybe<Scalars['String']>;
  critical_incident_phone_number?: Maybe<Scalars['String']>;
  critical_incident_response_enabled: Scalars['Boolean'];
  customer: Customer;
  employer_services_enabled: Scalars['Boolean'];
  fitness_for_duty_referral_type_enabled: Scalars['Boolean'];
  formal_referral_type_enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  informal_referral_type_enabled: Scalars['Boolean'];
  leader_course_description?: Maybe<Scalars['String']>;
  leader_course_title?: Maybe<Scalars['String']>;
  leader_course_url?: Maybe<Scalars['String']>;
  manager_consult_email?: Maybe<Scalars['String']>;
  manager_consult_enabled: Scalars['Boolean'];
  manager_consult_form_link?: Maybe<Scalars['String']>;
  manager_consult_phone_extension?: Maybe<Scalars['String']>;
  manager_consult_phone_number?: Maybe<Scalars['String']>;
  manager_consult_term?: Maybe<Scalars['String']>;
  mandatory_referral_type_enabled: Scalars['Boolean'];
  substance_abuse_referral_type_enabled: Scalars['Boolean'];
  term_employee_known_as?: Maybe<Scalars['String']>;
  term_manager_known_as?: Maybe<Scalars['String']>;
  workplace_enabled: Scalars['Boolean'];
};

export type WorkplaceVideo = {
  __typename?: 'WorkplaceVideo';
  content_type: Scalars['String'];
  content_url: Scalars['String'];
  description: Scalars['String'];
  duration: Scalars['String'];
  duration_in_minutes: Scalars['Int'];
  featured: Scalars['Boolean'];
  globally_available: Scalars['Boolean'];
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  us_available: Scalars['Boolean'];
  workplace_video_categories: Array<WorkplaceVideoCategory>;
};

export type WorkplaceVideoCategory = {
  __typename?: 'WorkplaceVideoCategory';
  category: Scalars['String'];
};

export type CreateProviderRequestAvailabilityZendeskTicketType = {
  __typename?: 'createProviderRequestAvailabilityZendeskTicketType';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateSudZendeskTicket = {
  __typename?: 'createSUDZendeskTicket';
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestCountryChange = {
  __typename?: 'requestCountryChange';
  success?: Maybe<Scalars['Boolean']>;
};
