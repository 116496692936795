import { combineReducers } from 'redux';
import Router from 'next/router';
import { form, modal, table } from '@spring/smeargle/reducers';
import routes from 'routes';

import auth from './auth';
import { logoutState as authLogoutState } from './auth/auth';

import { LOGOUT, AUTH_VERIFIED_TOKEN } from '../actions/auth/types';

const combined = combineReducers({
  auth,
  form,
  modal,
  table,
});

let actions = [];

const rootReducer = (state, action) => {
  const loggingOut = action.type === LOGOUT;
  const sessionExpired = action.type === AUTH_VERIFIED_TOKEN && !action.valid;
  const unauthenticated = loggingOut || sessionExpired;

  if (loggingOut) {
    Router.replace(routes.SignIn.to, routes.SignIn.as);
  }

  // Make sure integrations are reset or deactivated when session expires or logout happens
  if (unauthenticated) {
    window.__APOLLO_CLIENT__.clearStore();
    state = {
      auth: {
        ...authLogoutState,
      },
    };
    return combined(state, action);
  }

  if (actions.length > 100) {
    actions.shift();
  }

  actions.push(action);

  return combined(state, action);
};

const getActions = () => actions;

export { getActions };
export default rootReducer;
